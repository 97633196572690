<template>
  <v-hover v-slot="{ hover }">
    <v-card :elevation="hover ? 6 : 2">
      <v-card-title>
        <div
          :class="(asset.maintenance) ? 'text-left text-h5 font-weight-bold grey--text' : 'text-left text-h5 font-weight-bold black--text'">
          <v-icon>mdi-air-filter</v-icon>{{ asset.assetName }} <v-icon v-if="asset.maintenance"
            small>mdi-wrench</v-icon>
          <ASSET_ALERT_ICON :asset="asset" />
        </div>
        <v-spacer></v-spacer>
        <ASSET_MENU :asset="asset" />
      </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="auto" class="text-center">
            <AqiCircleChart :aqi="JSON.parse(asset.data)['PM25']" />
            <div class="text-caption">{{ $dayjs(asset.heartbeat).format('LLL') }}</div>
            <div class="text-caption"> {{ asset.location }}</div>
          </v-col>
          <v-col cols="auto">
            <v-simple-table dense>
              <template v-slot:default>
                <tbody>
                  <tr v-for="(subItem, index) in Object.keys(JSON.parse(asset.data))" :key="index"
                    @click="selectedRow(subItem)">
                    <td class="center-text text-subtitle-1" width="120px">
                      {{ subItem }}
                    </td>
                    <td class="center-text text-subtitle-1 font-weight-bold" width="50px">
                      {{ JSON.parse(asset.data)[subItem] }}
                    </td>
                    <td class="center-text text-subtitle-1" width="50px">{{ units[subItem] }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
          <v-col cols="7" class="text-center">
            ข้อมูลคุณภาพอากาศเฉลี่ยนราย ชม.
            <AIR_QUALITY_HOUR_CHART :element="selectedElement" :unit="units[selectedElement]" :data="data"
              :category="category" />
          </v-col>
          <v-col cols="12" class="text-center">
            ข้อมูลคุณภาพอากาศย้อนหลัง 7 วัน
            <AIR_QUALITY_HEATMAP :element="selectedElement" :unit="units[selectedElement]" :data="weeklyData" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-hover>

</template>
<script>
import AqiCircleChart from '@/components/air_quality/aqi_circle_chart.vue';
import AIR_QUALITY_HOUR_CHART from '@/components/air_quality/air_quality_hour_chart.vue';
import AIR_QUALITY_HEATMAP from '@/components/air_quality/air_quality_heatmap.vue';
import * as alasql from 'alasql'
import DateRangePicker from '@/components/common/DateRangePicker.vue'
import ASSET_MENU from '@/components/asset/asset_menu.vue'
import ASSET_ALERT_ICON from '@/components/asset/asset_alert_icon.vue'

export default {
  name: 'AirQualityView',
  props: {
    asset: {
      type: Object
    },
  },
  components: {
    AqiCircleChart,
    DateRangePicker,
    ASSET_MENU,
    ASSET_ALERT_ICON,
    AIR_QUALITY_HOUR_CHART,
    AIR_QUALITY_HEATMAP

  },
  data() {
    return {
      myChart: null,
      data: [],
      category: [],
      weeklyData: [],
      weeklyCategory: [],
      historyDialog: false,
      editDialog: false,
      currentItem: null,
      alertDialog: false,
      tokenDialog: false,
      emailDialog: false,
      alertHistoryDialog: false,
      selectedElement: 'PM25',
      tsItems: [],
      weeklyItems: [],
      range: {
        startDate: this.$dayjs().local().subtract('day', 7).startOf('day').toDate(),
        endDate: this.$dayjs().local().endOf('day').toDate(),
      },
    }
  },
  computed: {
    units() {
      return this.$store.state.User.units
    },
    building: {
      get: function () {
        return this.$store.state.User.building
      },
      set: function (newValue) {
        this.$store.dispatch('User/SET_BUILDING', newValue)
      }
    },
  },
  mounted() {
    this.getTSData()
    this.getHeatmapData()
  },
  methods: {
    selectedRow(item) {
      this.selectedElement = item
      this.setElementTS()
      this.setElementHeatMap()
    },
    setElementTS() {
      // SET TS chart
      this.category = this.tsItems.map((x) => this.$dayjs(x.date + ' ' + x.H + ':00', "YY-MM-DD H:mm").format("YY-MM-DD H:mm"))
      this.data = this.tsItems.map((x) => Number(x[this.selectedElement]).toFixed(2))
    },
    setElementHeatMap() {
      // SET HEATMAP chart
      let dataGroup = alasql(`select weekDay, H, MAX(${this.selectedElement}) as ${this.selectedElement} from ? group by weekDay, H`, [this.weeklyItems])
      this.weeklyData = dataGroup.map(a => [Number(a.weekDay), Number(a.H), Number(a[this.selectedElement])]).map(function (item) {
        return [item[1], item[0], item[2].toFixed(1) || '-'];
      })
    },
    getTSData() {
      this.$store.dispatch('Air/GET_AIR', {
        BuildingId: this.building.BuildingId,
        deviceId: this.asset.deviceId,
        start: Math.floor(this.$dayjs().local().subtract(1, 'day').valueOf()),
        end: Math.floor(this.$dayjs().local().valueOf()),
        type: 'hourly',
        group: true
      }).then((data) => {
        this.tsItems = data.sort((a, b) => {
          let dateA = this.$dayjs(a.date + ' ' + a.H + ':00', "YY-MM-DD H:mm")
          let dateB = this.$dayjs(b.date + ' ' + b.H + ':00', "YY-MM-DD H:mm")
          if (dateA.isAfter(dateB)) {
            return 1
          } else {
            return -1
          }
        })
        this.setElementTS()
      })
    },
    getHeatmapData() {
      this.$store.dispatch('Air/GET_AIR', {
        BuildingId: this.building.BuildingId,
        deviceId: this.asset.deviceId,
        start: Math.floor(this.$dayjs().local().subtract(1, 'week').startOf('week').valueOf()),
        end: Math.floor(this.$dayjs().local().valueOf()),
        type: 'hourly',
        group: true
      }).then((data) => {
        this.weeklyItems = data
        this.setElementHeatMap()
      })
    }
  },
}


</script>
