import { getAlert, getAlertByAsset ,getAlertLatest,getRules, createRules, deleteRules, updateRules } from '@/api/'
//import Vue from 'vue'
//import * as alasql from 'alasql'
const groupBy = key => array =>
    array.reduce((objectsByKeyValue, obj) => {
      const value = obj[key]
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj)
      return objectsByKeyValue
    }, {})
  
const groupByServiceId = groupBy('serviceId')

const state = () => ({
   alert: [],
   alertLatest: [],
   alertByService:[]

})

const mutations = {
    SET_ALERT(state, data) {
        state.alert = data
    },
    SET_ALERT_LATEST(state, data) {
        state.alertLatest = data
        state.alertByService = groupByServiceId(data)
    },
}

const actions = {
    GET_ALERT_BY_DEVICE({ commit, rootState }, data) {
        return new Promise((resolve, reject) => {
            getAlertByAsset({buildingId: rootState.User.building.BuildingId, assetId: data.assetId}, (message)=>{
                resolve(message.data)
            }, (error)=>{
                reject(error)
            })
        })
    },
    GET_ALERT({ commit, rootState }, data) {
        return new Promise((resolve, reject) => {
            getAlert({buildingId: rootState.User.building.BuildingId,start: data.start,end: data.end}, (message)=>{
                if (message.data) {
                    commit('SET_ALERT', message.data)
                    resolve()
                } else {
                    resolve() 
                }
            }, (error)=>{
                reject(error)
            })
        })
    },
    SET_ALERT_LATEST({ commit, rootState }, data){
        commit('SET_ALERT_LATEST',data)
    },
    GET_ALERT_LATEST({ commit, rootState }) {
        return new Promise((resolve, reject) => {
            getAlertLatest({buildingId: rootState.User.building.BuildingId},(message)=>{
                if (message.data) {
                    commit('SET_ALERT_LATEST', message.data)
                    resolve()
                } else {
                    resolve()
                }
            }, (error)=>{
                reject(error)
            })
        })
    },
    GET_RULES_BY_DEVICE({commit, rootState}, { assetId }) {
        return new Promise((resolve, reject) => {
            getRules({buildingId: rootState.User.building.BuildingId, assetId: assetId, },(message)=>{
                if (message.data) {
                    resolve(message.data)
                } else {
                    resolve()
                }
            }, (error)=>{
                reject(error)
            })
        })
    },
    CREATE_RULES_BY_DEVICE({commit, rootState}, data) {
        data.buildingId = rootState.User.building.BuildingId
        data.tenantId =  rootState.User.tenant.TenantId
        return new Promise((resolve, reject) => {
            createRules(data ,(message)=>{
                if (message.data) {
                    resolve(message.data)
                } else {
                    resolve()
                }
            }, (error)=>{
                reject(error)
            })
        })
    },
    UPDATE_RULES_BY_DEVICE({commit, rootState}, data) {
        data.buildingId = rootState.User.building.BuildingId
        data.tenantId =  rootState.User.tenant.TenantId
        return new Promise((resolve, reject) => {
            updateRules(data ,(message)=>{
                if (message.data) {
                    resolve(message.data)
                } else {
                    resolve()
                }
            }, (error)=>{
                reject(error)
            })
        })
    },
    DELETE_RULES_BY_DEVICE({commit, rootState}, data) {
        data.buildingId = rootState.User.building.BuildingId
        data.tenantId =  rootState.User.tenant.TenantId
        return new Promise((resolve, reject) => {
            deleteRules(data ,(message)=>{
                if (message.data) {
                    resolve(message.data)
                } else {
                    resolve()
                }
            }, (error)=>{
                reject(error)
            })
        })
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
  }
  