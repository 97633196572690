<template>
    <date-range-picker
            ref="picker"
            :opens="'left'"
            :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy HH:mm:ss' }"
            :singleDatePicker="false"
            :timePicker="timePicker"
            :timePicker24Hour="true"
            :showWeekNumbers="true"
            :showDropdowns="true"
            :autoApply="true"
            :date-range="value"
            :show-ranges="true"
            :ranges="ranges"
            @update="updateEvent"
            @toggle="logEvent('event: open', $event)"
            @start-selection="logEvent('event: startSelection', $event)"
            @finish-selection="logEvent('event: finishSelection', $event)"
            :linkedCalendars="true"
            control-container-class=""
    >
        <template v-slot:input="picker">
          <v-text-field v-if="timePicker" readonly  solo dense  :value="$dayjs(picker.startDate).locale('TH').format('DD MMM YYYY HH:mm')+  '-' + $dayjs(picker.endDate).locale('TH').format('DD MMM YYYY HH:mm')"  />
          <v-text-field v-else readonly  solo dense  :value="$dayjs(picker.startDate).locale('TH').format('DD MMM YYYY')+  '-' + $dayjs(picker.endDate).locale('TH').format('DD MMM YYYY')"  />
            <!-- {{ $dayjs(picker.startDate).locale('TH').format('DD MMM YYYY HH:mm')  }} - {{ $dayjs(picker.endDate).locale('TH').format('DD MMM YYYY HH:mm') }} -->
        </template>
    </date-range-picker>
  </template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
//you need to import the CSS manually
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  props: {
    value: {
        type: Object
    },
    timePicker: {
      type: Boolean,
      default: true,
    },
    ranges: {
      type: [Object, Boolean],
      default () {
        let today = new Date()
        today.setHours(0, 0, 0, 0)
        let todayEnd = new Date()
        todayEnd.setHours(11, 59, 59, 999);

        let yesterdayStart = new Date()
        yesterdayStart.setDate(today.getDate() - 1)
        yesterdayStart.setHours(0, 0, 0, 0);

        let yesterdayEnd = new Date()
        yesterdayEnd.setDate(today.getDate() - 1)
        yesterdayEnd.setHours(11, 59, 59, 999);

        let thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
        let thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0, 11, 59, 59, 999);

        let firstDayWeek = today.getDate() - today.getDay();
        let lastDayWeek = firstDayWeek + 6; 
        
        let lastWeekStart = new Date(today.getFullYear(), today.getMonth(), firstDayWeek);
        let lastWeekEnd =  new Date(today.getFullYear(), today.getMonth(), lastDayWeek, 11, 59, 59, 999);

        return {
          'Today': [today, todayEnd],
          'Yesterday': [yesterdayStart, yesterdayEnd],
          'This month': [thisMonthStart, thisMonthEnd],
          'This year': [
            new Date(today.getFullYear(), 0, 1),
            new Date(today.getFullYear(), 11, 31, 11, 59, 59, 999)
          ],
          'Last week': [lastWeekStart, lastWeekEnd],
          'Last month': [
            new Date(today.getFullYear(), today.getMonth() - 1, 1),
            new Date(today.getFullYear(), today.getMonth(), 0, 11, 59, 59, 999)
          ],
        }
      }
    },
  },
  components: { DateRangePicker },
  data() {
    return {
      opens: false,
      range: {
        startDate: this.$dayjs().subtract(1, 'day').toDate(),
        endDate: this.$dayjs().toDate(),
      },
      masks: {
        input: 'YYYY-MM-DD h:mm A',
      },
    };
  },
  methods: {
    updateEvent(event) {
        console.log(event)
        this.$emit('input', event)
    },
    logEvent(event) {
        console.log(event)
    }
  },
};
</script>
<style scoped>
 .v-text-field{
      min-width: 310px;
}
</style>