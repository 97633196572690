<template>
    <v-row class="pt-2">
        <v-col cols="12">
            <v-hover v-slot="{ hover }">
                <v-card :elevation="hover ? 6 : 2" :disabled="loading">
                    <v-card-title><v-spacer />
                        <v-col cols="2">
                            <v-select placeholder="เลือกมิเตอร์" :disabled="loading" class="mt-6" dense solo v-model="selectedMeter"
                                :items="assets" item-text="assetName" item-value="deviceId"></v-select></v-col>
                        <v-col cols="2"> <v-select :disabled="loading" class="mt-6" dense solo v-model="selectedPeriod"
                                :items="periods" item-text="period" item-value="value"></v-select></v-col>
                        <v-col cols="auto">
                            <DateRangePicker class="mt-6" v-model="range" :timePicker="false"/>
                        </v-col>
                        <v-col cols="auto"> <v-btn color="primary" :disabled="loading"
                                @click="getEnergyTable()">View</v-btn></v-col><v-col cols="auto"> <v-btn
                                :disabled="loading" color="primary" outlined
                                @click="exportToXLS(`Export${$dayjs().format('DDMMMYYYYHHmmss')}`, { headers: headers, items: items })">Export</v-btn></v-col>
                    </v-card-title>
                </v-card>
            </v-hover>
        </v-col>
        <v-col cols="12">
            <STACK_BAR_CHART v-bind="series" :update="update"/>
        </v-col>
        <v-col cols="12">
            <v-hover v-slot="{ hover }">
                <v-card :elevation="hover ? 6 : 2">
                    <v-card-text>
                        <v-data-table :headers="headers" :items="items" :items-per-page="-1">
                            <template v-slot:item.Peak="{ item }">
                                {{ item.Peak.toFixed(2) }}
                            </template>
                            <template v-slot:item.OffPeak="{ item }">
                                {{ item.OffPeak.toFixed(2) }}
                            </template>
                            <template v-slot:item.demand="{ item }">
                                {{ item.demand.toFixed(2) }}
                            </template>
                            <template v-slot:item.Unit="{ item }">
                                {{ item.Unit.toFixed(2) }}
                            </template>
                            <template v-slot:item.date="{ item }">
                                {{ $dayjs(item.date, "YY-MM-DD").format('DD/MM/YYYY') }}
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-hover>
        </v-col>
    </v-row>
</template>
<script>
import { xlsxMixins } from '@/mixins/xlsx'
import DateRangePicker from '@/components/common/DateRangePicker.vue'
import STACK_BAR_CHART from '@/components/charts/stack_bar_chart.vue';
export default {
    mixins: [xlsxMixins],
    components: {
        DateRangePicker,
        STACK_BAR_CHART
    },
    props: {
        update: {
            type: Boolean
        }
    },
    data() {
        return {
            range: {
                startDate: this.$dayjs().startOf('month').toDate(),
                endDate: this.$dayjs().endOf('month').toDate(),
            },
            selectedPeriod: 'daily',
            periods: [
                { period: 'Daily', value: 'daily' },
                { period: 'Weekly', value: 'weekly' },
                { period: 'Monthly', value: 'monthly' },
                { period: 'Yearly', value: 'yearly' },
            ],
            selectedMeter: null,
            loading: false,
            headers: [],
            items: [],
            series: {
                axis: [],
                peak: [],
                offPeak: [],
                demand: []
            },
        }
    },
    computed: {
        assets() {
            return this.$store.state.Asset.assetEnergy.filter((el) => el.assetType === 'PowerMeter')
        },
        building: {
            get: function () {
                return this.$store.state.User.building
            },
            set: function (newValue) {
                this.$store.dispatch('User/SET_BUILDING', newValue)
            },
        },
    },
    watch: {
        building() {
            this.selectedMeter = null
            this.headers = []
            this.items = []
        }
    },
    methods: {
        getEnergyTable() {
            if (this.selectedMeter) {
                this.loading = true
                this.$store.dispatch('Energy/GET_ENERGY_TABLE', { BuildingId: this.building.BuildingId, type: this.selectedPeriod, deviceId: this.selectedMeter, group: false, start: this.range.startDate.getTime(), end: this.range.endDate.getTime() }).then((data) => {
                    this.loading = false
                    this.items = []
                    this.series.axis = []
                    this.series.peak = []
                    this.series.offPeak = []
                    this.series.demand = []
                    switch (this.selectedPeriod) {
                        case 'daily':
                            this.headers = [
                                { text: 'Date', value: 'date' },
                                { text: 'Peak', value: 'Peak' },
                                { text: 'OffPeak', value: 'OffPeak' },
                                { text: 'Total', value: 'Unit' },
                                { text: 'Demand', value: 'demand' },
                            ]
                            data.forEach((element) => {
                                this.series.axis.push(element.date)
                                this.series.peak.push(Math.ceil(element.Peak))
                                this.series.offPeak.push(Math.ceil(element.OffPeak))
                                this.series.demand.push(Math.ceil(element.demand))
                            })
                            break;
                        case 'weekly':
                            this.headers = [
                                { text: 'Year', value: 'year' },
                                { text: 'Week', value: 'week' },
                                { text: 'Peak', value: 'Peak' },
                                { text: 'OffPeak', value: 'OffPeak' },
                                { text: 'Total', value: 'Unit' },
                                { text: 'Demand', value: 'demand' },
                            ]
                            data = data.sort((a, b) => a.week - b.week)
                            data.forEach((element) => {
                                this.series.axis.push(element.week)
                                this.series.peak.push(Math.ceil(element.Peak))
                                this.series.offPeak.push(Math.ceil(element.OffPeak))
                                this.series.demand.push(Math.ceil(element.demand))
                            })
                            break;
                        case 'monthly':
                            this.headers = [
                                { text: 'Year', value: 'year' },
                                { text: 'Month', value: 'month' },
                                { text: 'Peak', value: 'Peak' },
                                { text: 'OffPeak', value: 'OffPeak' },
                                { text: 'Total', value: 'Unit' },
                                { text: 'Demand', value: 'demand' },
                            ]
                            data.forEach((element) => {
                                this.series.axis.push(this.$dayjs().set('month',element.month-1).format('MMM YY'))
                                this.series.peak.push(Math.ceil(element.Peak))
                                this.series.offPeak.push(Math.ceil(element.OffPeak))
                                this.series.demand.push(Math.ceil(element.demand))
                            })
                            break;
                        case 'yearly':
                            this.headers = [
                                { text: 'Year', value: 'year' },
                                { text: 'Peak', value: 'Peak' },
                                { text: 'OffPeak', value: 'OffPeak' },
                                { text: 'Total', value: 'Unit' },
                                { text: 'Demand', value: 'demand' },
                            ]
                            data.forEach((element) => {
                                this.series.axis.push(element.year)
                                this.series.peak.push(Math.ceil(element.Peak))
                                this.series.offPeak.push(Math.ceil(element.OffPeak))
                                this.series.demand.push(Math.ceil(element.demand))
                            })
                            break;
                    }
                    this.items = data
                }).catch(() => {
                    this.loading = false
                })
            }

        },
    },
}

</script>