<template>
  <v-hover v-slot="{ hover }">
    <v-card :elevation="hover ? 6 : 2" class="mt-2 mr-2 ml-2 mb-2" > 
        <v-card-text>
            <v-row>
                <v-spacer/>
                <v-col cols="3">
                    <v-select :disabled="loading" solo dense :items="reportList" item-text="reportName" item-value="serviceId" ></v-select>
                </v-col>
                <v-col cols="auto">
                    <v-btn color="primary" :disabled="loading">View</v-btn>
                </v-col>
                <v-col cols="auto">
                    <v-btn color="primary" outlined>Export</v-btn>
                </v-col>
            </v-row>
           
        </v-card-text>
    </v-card>
  </v-hover>

</template>
<script>
import {mapState} from 'vuex'
export default {
    data() {
        return {
            loading: false
        }
    },
    computed: {
        ...mapState('Report',['reportList']),
        building: {
        get: function () {
          return this.$store.state.User.building
        },
        set: function (newValue) {
          this.$store.dispatch('User/SET_BUILDING', newValue)
        }
      },
    },
    watch: {
        building() {
            this.getReportList()
        }
    },
    mounted () {
        this.getReportList()
    },
    methods: {
        getReportList() {
            this.loading = true
            this.$store.dispatch('Report/GET_REPORT_LIST').then(()=>{
                this.loading = false
            }).catch(()=>{
                this.loading = false
            })
        },
    },
}


</script>

