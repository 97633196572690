<template>
    <l-map
        class="elevation-2"
        ref="map"
        :center="center"
        :crs="crs"
        :style="`height: ${height}px; width: 100%`"
        @ready="configMap()"
      >
      <l-image-overlay
        :url="'https://stastfmprodea01.blob.core.windows.net/upload/water_system_cyclotron.svg'"
        :bounds="bounds"
      />

      <l-marker
    :ref="item.assetName" 
        v-for="(item,index) in assets"
        :lat-lng="[Number(item.position.split(':')[0]),Number(item.position.split(':')[1])]"
        :key="index"
      >
          <l-icon
          :icon-size="[Number(item.IconSize.split(':')[0]),Number(item.IconSize.split(':')[1])]"
          :icon-url="item.icon"
          >
        
      </l-icon>
      <l-tooltip
      :options="{
        permanent: true, 
        direction: 'left',
        offset:[-45,20]
      }"
      >    <template v-if="item.status === 'Online'">
            <v-icon size="15" class="mr-2"  color="green">mdi-circle</v-icon>
          </template>
          <template v-else>
            <v-icon size="15"  class="mr-2"  color="red">mdi-circle</v-icon>
          </template>{{ item.assetName }}
            <tr v-for="(subItem, index) in Object.keys(JSON.parse(item.data))" :key="index"> 
              <td class="center-text" width="100px">
                {{ subItem }}   
              </td>
              <td class="center-text">
               {{ JSON.parse(item.data)[subItem] }}
              </td>
          </tr>
        </l-tooltip>
     
      <l-popup :options="{autoClose: false, closeOnClick: false}">
        <v-simple-table dense>
    <template v-slot:default>
      <tbody>
        <tr>
          <td>Alarm</td>
          <td>
            <template  v-if="item.alarm">
            <v-icon color="red">mdi-circle</v-icon> Alarm
          </template>
          <template v-else>
            <v-icon color="green">mdi-circle</v-icon> Normal
            </template>
          </td>
        </tr>
        <tr>
          <td>Notify</td>
          <td>
           <v-switch inset></v-switch>
          </td>
        </tr>

        <tr>
          <td>Asset Name</td>
          <td>{{ item.assetName  }}</td>
        </tr>
        <tr>
          <td>Asset Type</td>
          <td>{{ item.assetType  }}</td>
        </tr>
        <tr>
          <td>Status</td>
          <td>
            <template v-if="item.status === 'Online'">
            <v-icon  color="green">mdi-circle</v-icon>{{ item.status  }}
          </template>
          <template v-else>
            <v-icon  color="red">mdi-circle</v-icon>{{ item.status  }}
          </template>
          
          </td>
        </tr>
        <tr>
          <td>Data</td>
          <td> 
            <tr v-for="(subItem, index) in Object.keys(JSON.parse(item.data))" :key="index"> 
              <td class="center-text" width="100px">
                {{ subItem }}   
              </td>
              <td class="center-text">
               {{ JSON.parse(item.data)[subItem] }}
              </td>
          </tr>
          </td>
        </tr>
        <tr>
          <td>Heartbeat</td>
          <td>{{ $dayjs(item.heartbeat).format('DD/MM/YYYY HH:mm')}}</td>
        </tr>
        <tr>
          <td>History</td>
          <td><v-btn small color="primary">View</v-btn></td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
      </l-popup>
      </l-marker>
  </l-map>
  </template>
  <script>
  import {CRS, icon} from "leaflet";
  import { LMap, LImageOverlay, LMarker, LPopup, LPolyline,LTileLayer,LControl,LIcon,LTooltip,LControlLayers,LLayerGroup } from "vue2-leaflet";
  import 'leaflet/dist/leaflet.css';
  
  export default {
  
      components: {
          LMap,
          LImageOverlay,
          LMarker,
          LPopup,
          LPolyline,
          LTileLayer,
          LControl,
          LIcon,
          LTooltip,
          LControlLayers,
          LLayerGroup,
      },
      computed: {
          height () {
          switch (this.$vuetify.breakpoint.name) {
            case 'xs': return 220
            case 'sm': return 350
            case 'md': return 450
            case 'lg': return 600
            case 'xl': return 700
          } 
        },
        assets () {
            return this.$store.state.Asset.assetSanitary
        }
       },
      data() {
          return {
              crs: CRS.Simple,
              center: [300,600],
              bounds: [[0, 0], [600, 1200]],
          }
      },
      methods: {
          configMap() {
              
          }
      },
  }
  
  </script>