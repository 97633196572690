import { postAcknowledge } from '@/api/'


const state = () => ({
})

const mutations = {
}

const actions = {
    POST_ACKNOWLEDGE({commit, rootState}, data) {
        return new Promise((resolve, reject) => {
            postAcknowledge(data,(message)=>{
                if (message.data) {
                    resolve(message.data)
                } else {
                    resolve()
                }
            }, (error)=>{
                reject(error)
            })
        })
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
  }
  