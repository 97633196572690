<template>
   <v-row>
    <v-col cols="6">
      <v-hover v-slot="{ hover }">
      <v-card :elevation="hover ? 6 : 2">
        <v-system-bar>Outdoor</v-system-bar>
<v-card-text>
    <v-row>
        <v-col cols="6" class="text-center font-weight-bold">
        <v-avatar size="100">
           <v-icon size="100" color="yellow">mdi-emoticon-neutral-outline</v-icon>
        </v-avatar>
       <v-row>
        <v-col cols="12" class="text-center text-h6 font-weight-bold">โรงพยาบาลสมเด็จพระบรมราชเทวี ณ ศรีราชา</v-col>
        <v-col cols="6"><v-icon>mdi-thermometer</v-icon>36°C</v-col>
        <v-col cols="6"><v-icon>mdi-water-percent</v-icon>76%</v-col>
       </v-row>
        </v-col>
        <v-col cols="6" align-self="end">
       <v-row dense class="font-weight-bold">
        <v-col cols="4">CO₂</v-col>
        <v-col cols="8">200 ppm</v-col>
        <v-col cols="4">VOC</v-col>
        <v-col cols="8">0.25 mg/m3</v-col>
        <v-col cols="4">PM2.5</v-col>
        <v-col cols="8">50 ug/m3</v-col>
        <v-col cols="4">PM10</v-col>
        <v-col cols="8">130 ug/m3</v-col>
        <v-col cols="4">Time</v-col>
        <v-col cols="8"> {{ $dayjs().format('DD/MM/YYYY HH:mm') }}</v-col>
        <v-col cols="12">
            <v-sparkline
        :smooth="16"
        :gradient="['#f72047', '#ffd200', '#1feaea']"
        :line-width="3"
        :value="aqiOutdoor"
        auto-draw
        stroke-linecap="round"
      >
      <template v-slot:label="item">
            {{ item.value }}
          </template>
    </v-sparkline>
        </v-col>
       </v-row>
        </v-col>
    </v-row>
       
    </v-card-text>
      </v-card>
    </v-hover>
    </v-col>
    <v-col cols="6">
      <v-hover v-slot="{ hover }">
      <v-card :elevation="hover ? 6 : 2">
        <v-system-bar>Indoor</v-system-bar>
<v-card-text>
    <v-row>
        <v-col cols="6" class="text-center font-weight-bold">
        <v-avatar size="100">
           <v-icon size="100" color="green">mdi-emoticon-outline</v-icon>
        </v-avatar>
       <v-row>
        <v-col cols="12" class="text-center text-h6 font-weight-bold">อาคารศรีสวรินทิรานุสรณ์ 150 ปี</v-col>
        <v-col cols="6"><v-icon>mdi-thermometer</v-icon>27°C</v-col>
        <v-col cols="6"><v-icon>mdi-water-percent</v-icon>60%</v-col>
       </v-row>
        </v-col>
        <v-col cols="6" align-self="end">
       <v-row dense class="font-weight-bold">
        <v-col cols="4">CO₂</v-col>
        <v-col cols="8">500 ppm</v-col>
        <v-col cols="4">VOC</v-col>
        <v-col cols="8">0.25 mg/m3</v-col>
        <v-col cols="4">PM2.5</v-col>
        <v-col cols="8">15 ug/m3</v-col>
        <v-col cols="4">PM10</v-col>
        <v-col cols="8">65 ug/m3</v-col>
        <v-col cols="4">Time</v-col>
        <v-col cols="8"> {{ $dayjs().format('DD/MM/YYYY HH:mm') }}</v-col>
        <v-col cols="12">
            <v-sparkline
        :smooth="16"
        :gradient="['#f72047', '#ffd200', '#1feaea']"
        :line-width="3"
        :value="aqiIndoor"
        auto-draw
        stroke-linecap="round"
      >
      <template v-slot:label="item">
            {{ item.value }}
          </template>
    </v-sparkline>
        </v-col>
       </v-row>
        </v-col>
    </v-row>
    </v-card-text>
      </v-card>
    </v-hover>
    </v-col>
   </v-row>
</template>

<script>
export default {

  data() {
      return {
        aqiOutdoor: [29, 30, 40, 47, 50, 52],
        aqiIndoor: [29, 30, 32, 40, 34, 28]
      }
  },
}
</script>