
import Vue from 'vue'
import { apiConfig, b2cPolicies } from '@/authConfig'
import { InteractionStatus, EventType, EventMessageUtils } from '@azure/msal-browser'
import store from '@/store'

function accountArraysAreEqual (arrayA, arrayB) {
  if (arrayA.length !== arrayB.length) {
    return false
  }
  const comparisonArray = [...arrayB]
  return arrayA.every((elementA) => {
    const elementB = comparisonArray.shift()
    if (!elementA || !elementB) {
      return false
    }
    return (elementA.homeAccountId === elementB.homeAccountId) &&
               (elementA.localAccountId === elementB.localAccountId) &&
               (elementA.username === elementB.username)
  })
}

/**
 *  Vue Instance Definition
 */

let instance

export const getInstance = () => instance

/**
 *  Vue Instance Initialization
 */

export const useB2C = (msalInstance) => {
  if (instance) return instance
  instance = new Vue({
    data () {
      return {
        inProgress: InteractionStatus.Startup,
        accounts: null,
        errorMessage: null
      }
    },
    async created () {
      this.accounts = msalInstance.getAllAccounts()
      msalInstance.addEventCallback((message) => {
        switch (message.eventType) {
          case EventType.ACCOUNT_ADDED:
          case EventType.ACCOUNT_REMOVED:
          case EventType.LOGIN_SUCCESS:
          case EventType.SSO_SILENT_SUCCESS:
          case EventType.HANDLE_REDIRECT_END:
          case EventType.LOGIN_FAILURE:
          case EventType.SSO_SILENT_FAILURE:
          case EventType.LOGOUT_END:
          case EventType.ACQUIRE_TOKEN_SUCCESS:
          case EventType.ACQUIRE_TOKEN_FAILURE: {
            const currentAccounts = msalInstance.getAllAccounts()
            // this.accounts = currentAccounts
            if (!accountArraysAreEqual(currentAccounts, this.accounts)) {
              this.accounts = currentAccounts
            }
          }
            break
        }
        const status = EventMessageUtils.getInteractionStatusFromEvent(message, this.inProgress)
        if (status !== null) {
          this.inProgress = status
        }
      })
    
      try {
        const tokenResponse = await msalInstance.handleRedirectPromise()
        if (tokenResponse !== null) {
          const hasRole = await this.persistUserInfo(tokenResponse)
          if (!hasRole) this.logout()
        } else {
            const accounts = msalInstance.getAllAccounts()
          if (accounts.length > 0) {
            const token = await this.acquireToken()
            const hasRole = await this.persistUserInfo(token)
            if (!hasRole) this.logout()
          }
        }
      } catch (error) {
        if (error.errorMessage) {
          if (error.errorMessage.indexOf('AADB2C90118') > -1) {
            msalInstance.loginRedirect({
              authority: b2cPolicies.authorities.forgotPassword.authority,
              scopes: [...apiConfig.b2cScopes],
              extraQueryParameters: { ui_locales: 'th' }
            })
          } else if (error.errorMessage.indexOf('AADB2C90091') > -1) {
            console.log('access_denied')
          }
        }
      }
    },
    methods: {
      async persistUserInfo (token) {
        try {
          await store.dispatch('User/SET_USER', token.idTokenClaims)
          return true
        } catch {
          return false
        }
      },
      login () {
        msalInstance.loginRedirect({
          authority: b2cPolicies.authorities.signUpSignIn.authority,
          scopes: [...apiConfig.b2cScopes],
          extraQueryParameters: { ui_locales: 'th' }
        })
      },
      logout () {
        this.accounts = msalInstance.getAllAccounts()
        if (this.accounts.length > 0) {
          const logoutRequest = {
            account: msalInstance.getAccountByHomeId(this.accounts[0].homeAccountId)
          }
          msalInstance.logoutRedirect(logoutRequest)
          sessionStorage.clear()
        }
      },
      changePassword () {
        msalInstance.loginRedirect({
          authority: b2cPolicies.authorities.changePassword.authority,
          scopes: [...apiConfig.b2cScopes],
          extraQueryParameters: { ui_locales: 'th' }
        })
      },
      invite (idtoken) {
        console.trace()
        msalInstance.loginRedirect({
          authority: b2cPolicies.authorities.invitation.authority, 
          scopes: [...apiConfig.b2cScopes],
          extraQueryParameters: {
            ui_locales: 'th',
            id_token_hint: idtoken
          }
        })
      },
      async acquireToken () {
        if (this.inProgress.value === InteractionStatus.Startup || this.inProgress.value === InteractionStatus.HandleRedirect) {
          try {
            console.log('Aquire Step1')
            const response = await msalInstance.handleRedirectPromise()
            if (response) {
              return response
            }
          } catch (e) {
            console.log(e)
            return
          }
        }
        try {
          console.log('Aquire Step2')
          const response = await msalInstance.acquireTokenSilent({
            scopes: [...apiConfig.b2cScopes]
          })
          return response
        } catch (error) {
          console.log(error)
          await msalInstance.loginRedirect({
            scopes: [...apiConfig.b2cScopes],
            extraQueryParameters: { ui_locales: 'th' }
          }).catch((e) => {
            console.log(e)
          })
        }
      }
    }
  })
  return instance
}
export const msalPlugin = {
  install (Vue, msalInstance) {
    Vue.prototype.$auth = useB2C(msalInstance)
  }
}
