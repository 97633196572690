<template>
    <l-map
        class="elevation-2"
        ref="map"
        :center="center"
        :crs="crs"
        :style="`height: ${height}px; width: 100%`"
        @ready="configMap()"
      >
  </l-map>
  </template>
  <script>
  import {CRS, icon} from "leaflet";
  import { LMap, LImageOverlay, LMarker, LPopup, LPolyline,LTileLayer,LControl,LIcon,LTooltip,LControlLayers,LLayerGroup } from "vue2-leaflet";
  import 'leaflet/dist/leaflet.css';
  
  export default {
  
      components: {
          LMap,
          LImageOverlay,
          LMarker,
          LPopup,
          LPolyline,
          LTileLayer,
          LControl,
          LIcon,
          LTooltip,
          LControlLayers,
          LLayerGroup,
      },
      computed: {
          height () {
          switch (this.$vuetify.breakpoint.name) {
            case 'xs': return 220
            case 'sm': return 350
            case 'md': return 450
            case 'lg': return 600
            case 'xl': return 700
          }
      }
       },
      data() {
          return {
              crs: CRS.Simple,
              center: [300,600],
          }
      },
      methods: {
          configMap() {
              
          }
      },
  }
  
  </script>