<template>
    <v-card>
        <v-card-title>Alert Settings | {{ asset.assetName }} ({{ asset.deviceId }}) {{ asset.assetType }}<v-spacer />
            <v-btn icon @click="$emit('onClose')"><v-icon>mdi-close</v-icon></v-btn></v-card-title>
        <v-card-text>
            <v-snackbar v-model="errorSnack" :timeout="timeout">
                {{ errorMessage }}

                <template v-slot:action="{ attrs }">
                    <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
                        Close
                    </v-btn>
                </template>
            </v-snackbar>
            <v-data-table :search="search" :headers="headers" :loading="loading" :items="items">
                <template v-slot:top>
                    <v-row>
                        <v-col cols="auto">
                            <v-text-field v-model="search" solo dense prepend-inner-icon="mdi-magnify" label="Search" />
                        </v-col>
                        <v-spacer />
                        <v-col cols="auto">
                            <v-btn color="primary" v-if="role == 'Admin'"  :disabled="loading" @click="addNew"> <v-icon>mdi-plus</v-icon>New
                                Rule</v-btn>
                        </v-col></v-row>
                </template>

                <template v-slot:item.value="{ item }">
                    <v-text-field :disabled="loading" v-model="editedItem.value" :hide-details="true" dense single-line
                        :autofocus="true" v-if="item.rowKey === editedItem.rowKey"></v-text-field>
                    <span v-else>{{ item.value }}</span>
                </template>

                <template v-slot:item.title="{ item }">
                    <v-textarea :disabled="loading" rows="2" v-model="editedItem.title" :hide-details="true" dense
                        single-line :autofocus="true" v-if="item.rowKey === editedItem.rowKey"></v-textarea>
                    <span v-else>{{ item.title }}</span>
                </template>

                <template v-slot:item.message="{ item }">
                    <v-textarea :disabled="loading" rows="2" v-model="editedItem.message" :hide-details="true" dense
                        single-line :autofocus="true" v-if="item.rowKey === editedItem.rowKey"></v-textarea>
                    <span v-else>{{ item.message }}</span>
                </template>

                <template v-slot:item.element="{ item }">
                    <v-form ref="form" v-model="valid" v-if="item.rowKey === editedItem.rowKey">
                        <v-select :disabled="loading" label="Element" :items="elements"
                            :rules="[v => !!v || 'Please select element']" v-model="editedItem.element"
                            :hide-details="true" dense single-line :autofocus="true"></v-select>
                    </v-form>
                    <span v-else>{{ item.element }}</span>
                </template>

                <template v-slot:item.operation="{ item }">
                    <v-select :disabled="loading" label="Element" :items="operations" item-text="text"
                        item-value="value" v-model="editedItem.operation" :hide-details="true" dense single-line
                        :autofocus="true" v-if="item.rowKey === editedItem.rowKey"></v-select>
                    <span v-else>{{ operations.filter(el => el.value === item.operation)[0].text }}</span>
                </template>

                <template v-slot:item.actions="{ item }">
                    <div v-if="item.rowKey === editedItem.rowKey">
                        <v-icon color="red" class="mr-3" @click="close">
                            mdi-window-close
                        </v-icon>
                        <v-icon color="green" @click="save">
                            mdi-content-save
                        </v-icon>
                    </div>
                    <div v-else>
                        <v-icon color="green" :disabled="role !== 'Admin'" class="mr-3" @click="editItem(item)">
                            mdi-pencil
                        </v-icon>
                        <v-icon color="red" :disabled="role !== 'Admin'" @click="deleteItem(item)">
                            mdi-delete
                        </v-icon>
                    </div>
                </template>

            </v-data-table>
        </v-card-text>
    </v-card>

</template>
<script>
import { v4 as uuidv4 } from 'uuid';

export default {
    props: {
        asset: {
            type: Object
        },
    },
    watch: {
        asset(val) {
            if (val) {
                this.getData(val.assetId)
            }
        }
    },
    data() {
        return {
            search: '',
            valid: false,
            dialog: false,
            dialogDelete: false,
            errorSnack: false,
            errorMessage: '',
            timeout: 2000,
            operations: [
                { text: '=', value: 'eq' },
                { text: '>=', value: 'ge' },
                { text: '<=', value: 'le' },
                { text: '!=', value: 'nq' },
            ],
            headers: [
                { text: 'Element', value: 'element', width: "100px" },
                { text: 'Operation', value: 'operation', width: "150px" },
                { text: 'Value', value: 'value', width: "150px" },
                { text: 'Title', value: 'title' },
                { text: 'Message', value: 'message' },
                { text: 'Actions', value: 'actions', sortable: false, width: "100px" },
            ],
            items: [],
            loading: false,
            elements: [],
            editedIndex: -1,
            defaultItem: {
                rowKey: 0,
                element: '',
                operation: 'eq',
                value: 0,
                title: 'หัวข้อ',
                message: 'ข้อความแนะนำ',
                trigger: '',
                resolve: ''
            },
            editedItem: {
                rowKey: 0,
                element: null,
                operation: null,
                value: 0,
                title: '',
                message: '',
                trigger: '',
                resolve: ''
            }
        }
    },
    computed: {
        role: {
            get: function () {
                return this.$store.state.User.role
            }
        },
        formTitle() {
            return this.editedIndex === -1 ? 'New Rule' : 'Edit Rule'
        },
    },
    mounted() {
        if (this.asset.assetId) {
            this.getData(this.asset.assetId);
        }
    },
    methods: {
        getData(assetId) {
            this.items = []
            this.loading = true
            try {
                let data = JSON.parse(this.asset.data)
                this.elements = Object.keys(data)
                this.$store.dispatch('Alert/GET_RULES_BY_DEVICE', { assetId: assetId }).then((data) => {
                    this.items = data
                    this.loading = false
                }).catch(() => {
                    this.loading = false
                })
            } catch (error) {
                console.log(error)
                this.loading = false
            }
        },
        addNew() {
            const addObj = Object.assign({}, this.defaultItem);
            addObj.rowKey = uuidv4();
            this.items.push(addObj);
            this.editItem(addObj);
        },
        editItem(item) {
            this.editedIndex = this.items.indexOf(item);
            this.editedItem = Object.assign({}, item);
        },
        save() {
            if (this.editedIndex > -1 && this.editedItem.element) {
                if (!this.$refs.form.validate()) return
                this.loading = true
                const data = {
                    assetId: this.asset.assetId,
                    element: this.editedItem.element,
                    operation: this.editedItem.operation,
                    value: this.editedItem.value,
                    title: this.editedItem.title,
                    message: this.editedItem.message,
                    roleId: this.editedItem.rowKey
                }
                this.$store.dispatch('Alert/UPDATE_RULES_BY_DEVICE', data).then((data) => {
                    this.items = data
                    this.loading = false
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                }).catch((error) => {
                    this.loading = false
                    this.errorMessage = error
                    this.errorSnack = true
                })
            }

        },
        deleteItem(item) {
            confirm('Are you sure you want to delete this item?') && this.delete(item);
        },
        delete(item) {
            this.loading = true
            const data = {
                assetId: this.asset.assetId,
                roleId: item.rowKey
            }
            this.$store.dispatch('Alert/DELETE_RULES_BY_DEVICE', data).then((data) => {
                this.items = data
                this.loading = false
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            }).catch((error) => {
                this.loading = false
                this.errorMessage = error
                this.errorSnack = true
            })
        },
        close() {
            this.loading = true
            this.$store.dispatch('Alert/GET_RULES_BY_DEVICE', { deviceId: this.asset.deviceId }).then((data) => {
                this.items = data
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
                this.loading = false
            }).catch((error) => {
                this.loading = false
                this.errorMessage = error
                this.errorSnack = true
            })
        }
    },
}

</script>