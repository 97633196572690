import { getScore } from '@/api/'
import Vue from 'vue'
import * as alasql from 'alasql'



const state = () => ({
    score: []
})

const mutations = {
    SET_SCORE(state, data){
        state.score =   data.map(item => item.score)
    }
}

const actions = {
    GET_SCORE({ commit , rootState}) {
        return new Promise((resolve, reject) => {
            getScore({ buildingId: rootState.User.building.BuildingId}, message => {
              commit('SET_SCORE', message.data)
              resolve(message.data)
            }, error => {
              reject(error)
            })
          })
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
  }
  