<template>
  <v-hover v-slot="{ hover }">
    <v-card :elevation="hover ? 6 : 2" :id="asset.assetName">
      <v-dialog width="600px" v-model="recalDialog" v-if="asset" scrollable>
        <ELECTRICAL_REPROCESS :asset="asset" @onClose="recalDialog = false"></ELECTRICAL_REPROCESS>
      </v-dialog>
      <v-card-title style="background-color: rgb(23,20,20,0.1);">
        <v-avatar>
          <v-tooltip bottom v-if="asset.status === 'Online'">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" size="32" color="primary">mdi-flash</v-icon>
            </template>
            <span>ออนไลน์</span>
          </v-tooltip>
          <v-tooltip bottom v-else>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" size="32" color="red">mdi-flash</v-icon>
            </template>
            <span>ออฟไลน์</span>
          </v-tooltip>
        </v-avatar>
        {{ asset.assetName }}
        <ASSET_ALERT_ICON  :asset="asset" />
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon :ripple="false" @click.stop="exportToImage"><v-icon color="primary">mdi-image</v-icon></v-btn>
          </template>
          <span>บันทึกภาพ</span>
        </v-tooltip>
       
        <v-spacer />
        <v-avatar size="50" tile>
          <v-img contain :src="asset.MapImage"></v-img>
        </v-avatar>
      </v-card-title>
      <v-card-text>
        <v-row dense class="mt-2">

        </v-row>
        <v-row dense class="mt-2">
          <v-col cols="12">
            <div>{{ asset.location }}</div>
            <div>  {{ analysisResult }}</div>
          </v-col>
          <v-col cols="12">
            <div class="text-caption">ข้อมูลเมื่อ {{ $dayjs(asset.heartbeat).format('LLL') }}</div>
          </v-col>
        </v-row>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="center-text">ชื่อ</th>
                <th class="center-text">ค่า</th>
                <th class="center-text">หน่วย</th>
                <th class="center-text">สถานะ</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(subItem, index) in Object.keys(JSON.parse(asset.data))" :key="index" @click="selectedRow(subItem)">
                <td class="center-text" width="100px">
                  {{ subItem }}
                </td>
                <td class="center-text">
                  {{ JSON.parse(asset.data)[subItem] }}
                </td>
                <td class="center-text">{{ units[subItem] }}</td>
                <td width="200px" class="center-text">
                  <v-icon :color="statusColor(asset.data, subItem)">mdi-circle</v-icon>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-expansion-panels class="mt-2" flat>
          <v-expansion-panel>
            <v-expansion-panel-header>
              ข้อมูลพลังงานย้อนหลัง 6 ชั่วโมง
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <SMALL_CHART :title="defaultElement" :category="category" :data="data"/>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
      <v-card-text class="text-right">
        <ASSET_MENU :asset="asset" :align="'right'">
          <template v-slot:extended>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon @click="openRecalDialog()"><v-icon>mdi-calculator</v-icon></v-btn>
              </template>
              <span>คำนวณประวัติการใช้ไฟฟ้า</span>
            </v-tooltip>
          </template>
        </ASSET_MENU>
      </v-card-text>
    </v-card>
  </v-hover>
</template>
<script>
import ELECTRICAL_REPROCESS from './electrical_reprocess_card.vue'
import ASSET_ALERT_ICON from '@/components/asset/asset_alert_icon.vue'
import ASSET_MENU from '@/components/asset/asset_menu.vue'
import SMALL_CHART from '@/components/energy_management/energy_kw_small_chart.vue'
import html2canvas from 'html2canvas'

export default {
  props: {
    asset: {
      type: Object
    },
  },
  components: {
    ELECTRICAL_REPROCESS,
    ASSET_ALERT_ICON,
    ASSET_MENU,
    SMALL_CHART
  },
  data() {
    return {
      recalDialog: false,
      range: {
        startDate: this.$dayjs().subtract(6, 'hour').toDate(),
        endDate: this.$dayjs().toDate(),
      },
      category: [],
      data: [],
      defaultElement: 'ActivePower',
      rawData: [],
      analysisResult: "",
    }
  },
  computed: {
    units() {
      return this.$store.state.User.units
    }
  },
  mounted() {
    let data = JSON.parse(this.asset.data)
    this.analyzeThreePhasePowerLineThailand(data.VoltageAN, data.VoltageBN, data.VoltageCN, data.CurrentA, data.CurrentB, data.CurrentC, (data.PowerFactor)?data.PowerFactor : 1, 50);
    this.getTelemetry()
  },
  methods: {
    openRecalDialog() {
      this.recalDialog = true
    },
    selectedRow(item) {
      this.defaultElement = item
      this.setChart()
    },
    setChart() {
      this.category = this.rawData.map(a=> this.$dayjs(a.timestamp).format('HH:mm DD MMM YYYY'))
      this.data = this.rawData.map(a=> Number(a[this.defaultElement]))
    },
    getTelemetry() {
      this.category = []
      this.data = []
      this.$store.dispatch('Asset/GET_TELEMETRY', { assetId: this.asset.assetId, start: Math.floor(this.range.startDate.getTime() / 1000), end: Math.floor(this.range.endDate.getTime() / 1000) }).then((data) => {
        this.rawData = data
        this.setChart()
      })
    },
    exportToImage () {
      setTimeout(() => {
        html2canvas(document.getElementById(this.asset.assetName)).then(canvas => {
          const img = canvas.toDataURL('image/png')
          const htmlTag = document.createElement('a')
          htmlTag.setAttribute('download', `${this.asset.assetName}.png`)
          htmlTag.setAttribute('href', img)
          htmlTag.click()
        })
      }, 10)
    },
    statusColor(data, element) {
      let obj = JSON.parse(data)
      switch (element) {
        case 'PowerFactor':
          if (obj[element] > 0.9) {
            return 'green'
          } else if (obj[element] > 0.8) {
            return 'orange'
          } else {
            return 'red'
          }
          break;
        case 'VoltageAN':
          if (obj[element] > 220 && obj[element] < 240) {
            return 'green'
          } else if (obj[element] > 210) {
            return 'orange'
          } else {
            return 'red'
          }
          break;
        case 'VoltageBN':
          if (obj[element] > 220 && obj[element] < 240) {
            return 'green'
          } else if (obj[element] > 210) {
            return 'orange'
          } else {
            return 'red'
          }
          break;
        case 'VoltageCN':
          if (obj[element] > 220 && obj[element] < 240) {
            return 'green'
          } else if (obj[element] > 210) {
            return 'orange'
          } else {
            return 'red'
          }
          break;
        default:
          return 'green'
          break;
      }

    },
    analyzeThreePhasePowerLineThailand(voltageA, voltageB, voltageC, currentA, currentB, currentC, powerFactor, frequency) {
      const voltageRange = { min: 220, max: 240 };
      const currentRange = { min: 0, max: 1100 };
      const frequencyRange = { min: 49.5, max: 50.5 };
      const powerFactorRange = { min: 0.9, max: 1.0 };
      const phaseImbalanceThreshold = 5;
      
      let issues = [];

      const checkPhase = (phase, voltage, current, powerFactor) => {
        if (voltage < voltageRange.min) {
          issues.push(`Phase ${phase}: Voltage is too low.`);
        } else if (voltage > voltageRange.max) {
          issues.push(`Phase ${phase}: Voltage is too high.`);
        }

        if (current < currentRange.min) {
          issues.push(`Phase ${phase}: Current is too low.`);
        } else if (current > currentRange.max) {
          issues.push(`Phase ${phase}: Current is too high.`);
        }

      };

      checkPhase('A', voltageA, currentA, powerFactor);
      checkPhase('B', voltageB, currentB, powerFactor);
      checkPhase('C', voltageC, currentC, powerFactor);

      if (powerFactor < powerFactorRange.min) {
          issues.push(`Power factor is too low (inefficient power usage).`);
        } else if (powerFactor > powerFactorRange.max) {
          issues.push(`Power factor is suspiciously high.`);
        }


      if (frequency < frequencyRange.min) {
        issues.push("Frequency is too low.");
      } else if (frequency > frequencyRange.max) {
        issues.push("Frequency is too high.");
      }

      const voltageImbalanceAB = Math.abs(voltageA - voltageB);
      const voltageImbalanceBC = Math.abs(voltageB - voltageC);
      const voltageImbalanceCA = Math.abs(voltageC - voltageA);

      if (voltageImbalanceAB > phaseImbalanceThreshold) {
        issues.push("Voltage imbalance between Phase A and B is too high.");
      }
      if (voltageImbalanceBC > phaseImbalanceThreshold) {
        issues.push("Voltage imbalance between Phase B and C is too high.");
      }
      if (voltageImbalanceCA > phaseImbalanceThreshold) {
        issues.push("Voltage imbalance between Phase C and A is too high.");
      }

      if (issues.length === 0) {
        this.analysisResult = "Three-phase power line quality is good.";
      } else {
        this.analysisResult = "Three-phase power line issues : " + issues.join(" ");
      }
    }
  },
}

</script>