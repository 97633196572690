<template>
  <v-hover v-slot="{ hover }">
    <v-card :elevation="hover ? 6 : 2" class="mt-2 mr-2 ml-2 mb-2" > 
        <v-card-text>


          <v-data-table :search="search" :loading="loading" :headers="headers" :items="items" sort-by="createDatetime"
                    :sort-desc="true">
            <template v-slot:top>
              <v-row>
                <v-spacer/>
                <v-col cols="3">
                    <v-select v-model="selectServiceId" :disabled="loading" solo dense :items="reportList" item-text="reportName" item-value="serviceId" ></v-select>
                </v-col>
                <v-col cols="auto">
                    <v-btn color="primary" :disabled="loading" @click="getReport">View</v-btn>
                </v-col>
                <v-col cols="auto">
                    <v-btn color="primary" outlined @click="exportToXLS(`Export${$dayjs().format('DDMMMYYYYHHmmss')}`,{ headers: headers,items: items })">Export</v-btn>
                </v-col>
            </v-row>
            </template>

            <template v-slot:item.resolve="{ item }">
                        <v-icon color="green" v-if="item.resolve">mdi-check</v-icon>
                        <v-icon color="red" v-else>mdi-alert</v-icon>
                    </template>
    <template v-slot:item.createDatetime="{ item }">
                        {{ $dayjs(item.createDatetime).local().format('DD MMM YY HH:mm') }}
                    </template>
                    <template v-slot:item.resolveTime="{ item }">
                        {{ (item.resolveTime) ? $dayjs(item.resolveTime * 1000).local().format('DD MMM YY HH:mm') : '' }}
                    </template>
        </v-data-table>






           
           
        </v-card-text>
    </v-card>
  </v-hover>

</template>
<script>
import {mapState} from 'vuex'
import { xlsxMixins } from '@/mixins/xlsx'
export default {
  mixins: [xlsxMixins],
    data() {
        return {
            loading: false,
            headers: [
                { text: 'Start', value: 'createDatetime' },
                { text: 'End', value: 'resolveTime' },
                { text: 'Device Name', value: 'assetName' },
                { text: 'Status', value: 'resolve' },
                { text: 'Title', value: 'title' },
                { text: 'Message', value: 'message' },
            ],
            items: [],
            selectServiceId: null
        }
    },
    computed: {
        ...mapState('Report',['reportList']),
        building: {
        get: function () {
          return this.$store.state.User.building
        },
        set: function (newValue) {
          this.$store.dispatch('User/SET_BUILDING', newValue)
        }
      },
    },
    watch: {
        building() {
            this.getReportList()
        }
    },
    mounted () {
        this.getReportList()
    },
    methods: {
        getReportList() {
            this.loading = true
            this.$store.dispatch('Report/GET_REPORT_LIST').then(()=>{
                this.loading = false
            }).catch(()=>{
                this.loading = false
            })
        },
        getReport() {
          if (this.selectServiceId) {
            this.loading = true
            this.$store.dispatch('Report/GET_REPORT', {serviceId: this.selectServiceId}).then((reportItems)=>{
                this.items = reportItems
                this.loading = false
            }).catch(()=>{
                this.loading = false
            })
          }
      
        }
    },
}


</script>

