import { render, staticRenderFns } from "./fire_alarm_status.vue?vue&type=template&id=ebd23b60&scoped=true&"
import script from "./fire_alarm_status.vue?vue&type=script&lang=js&"
export * from "./fire_alarm_status.vue?vue&type=script&lang=js&"
import style0 from "./fire_alarm_status.vue?vue&type=style&index=0&id=ebd23b60&prod&scss=true&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ebd23b60",
  null
  
)

export default component.exports