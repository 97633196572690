<template>
    <v-hover v-slot="{ hover }">
     
      <v-card :elevation="hover ? 6 : 2" class="mt-2" > 
        <v-snackbar
            v-model="errorSnack"
            :timeout="timeout">
          {{ errorMessage }}
          <template v-slot:action="{ attrs }">
            <v-btn
              color="blue"
              text
              v-bind="attrs"
              @click="snackbar = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
    <v-card-text>
          <v-data-table :search="search" :loading="loading" :headers="headers" :items="member">
              <template v-slot:top>
                  <v-row>
                  <v-spacer/>
                <v-col cols="2">
                <v-text-field label="Search" v-model="search" solo dense prepend-inner-icon="mdi-magnify"></v-text-field>
              
                </v-col>
                <v-col cols="auto">
                    <v-btn v-if="isSuperAdmin" color="primary" @click="addUserDialog = true"> Add User</v-btn>
                </v-col>
                <v-col cols="auto">
                    <v-btn v-if="isSuperAdmin" outlined color="primary" @click="testAlarmDialog = true">Test Alarm</v-btn>
                </v-col>
              </v-row>
              </template>
              <template v-slot:item.Timestamp="{ item }">
          {{$dayjs(item.Timestamp).fromNow()}}
      </template>
      <template v-slot:item.delete="{ item }">
        <v-icon color="red" @click="deleteItem(item)" :disabled="!isSuperAdmin || posting">
                  mdi-delete
        </v-icon>
      </template>

          </v-data-table>
          <v-dialog v-model="addUserDialog" width="350px" >
            <UserADCard @onClose="onUserDialogClose" :key="addUserDialog"/>
          </v-dialog>
          <v-dialog v-model="testAlarmDialog" width="350px" >
            <TestAlarmCard @onClose="testAlarmDialog = false" :key="testAlarmDialog"/>
          </v-dialog>
      </v-card-text>
      </v-card>
      </v-hover>
  </template>
  <script>
  import UserADCard from '@/components/users/user_ad_card.vue'
  import TestAlarmCard from '@/components/users/test_alarm_card.vue'
  export default {
    components: {
        UserADCard,
        TestAlarmCard
    },
      data() {
          return {
              addUserDialog: false,
              posting: false,
              headers: [
                  {text: 'Name', value: 'displayName'},
                  {text: 'UUID', value: 'partitionKey'},
                  {text: 'Role', value: 'Role'},
                  {text: 'Latest Update', value: 'Timestamp'},
                  {text: 'Delete', value: 'delete'}
              ],
              loading: false,
              testAlarmDialog: false,
              search: '',
              interval: null,
              timeout: 2000,
              errorSnack: false,
              errorMessage: '',
          }
      },
      mounted () {
          this.getMember();
          this.getUserAD();
      },
      destroyed () {
          this.interval = null
      },
      watch: {
        building() {
            this.getMember();

        }
      },
      computed: {
          building: {
          get: function () {
            return this.$store.state.User.building
          },
          set: function (newValue) {
            this.$store.dispatch('User/SET_BUILDING', newValue)
          }
        },
        member: {
          get: function () {
            return this.$store.state.Member.member
          }
        },
        isSuperAdmin: {
          get: function () {
            return this.$store.state.User.isSupperAdmin
          }
        }
      },
      methods: {
            onUserDialogClose(){
                this.addUserDialog = false
                this.getMember()
            },
          getMember() {
              this.loading = true
              this.$store.dispatch('Member/GET_MEMBER',{buildingId: this.building.BuildingId}).then(()=>{
                  this.loading = false
              }).catch(()=>{
                  this.loading = false
              })
          },
          getUserAD() {
              this.loading = true
              this.$store.dispatch('Member/GET_USER_AD').then(()=>{
                  this.loading = false
              }).catch(()=>{
                  this.loading = false
              })
          },
          deleteItem(item){
            confirm('Are you sure you want to delete this item?') && this.delete(item);
          },
          delete(item) {
            this.posting = true
            this.$store.dispatch('Member/DELETE_MEMBER', item).then(()=>{
                this.posting = false
                this.errorSnack = true
                this.errorMessage = 'Success'
                this.getMember()
            }).catch((error)=>{
                this.posting = false
                this.errorSnack = true
                this.errorMessage = error
                this.getMember()
            })
          }
      },
  }
  
  </script>