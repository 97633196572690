<template>
    <v-card>
       <v-card-title>
       {{ asset.assetName }} ({{ asset.deviceId }}) {{ asset.assetType }}
        <v-spacer/>
        <v-btn icon @click="$emit('onClose')">
            <v-icon>mdi-close</v-icon>
        </v-btn>
       </v-card-title>
        <v-card-text>
          <v-row dense>
          <v-col cols="12">Timestamp  {{ $dayjs(asset.heartbeat).format('HH:mm DD MMM YYYY') }}</v-col>
        </v-row>
          <v-simple-table dense class="mt-2">
            <thead>
        <tr>
          <th class="text-left">
            Element
          </th>
          <th class="text-left">
            Value
          </th>
          <th class="text-left">
            Unit
          </th>
        </tr>
      </thead>
            <tbody v-if="asset.data">
            <tr v-for="(subItem, index) in Object.keys(JSON.parse(asset.data))" :key="index"> 
              <td class="center-text" width="100px">
                {{ subItem }}   
              </td>
              <td class="center-text">
               {{ JSON.parse(asset.data)[subItem] }}
              </td>
              <td class="center-text">{{units[subItem]}}</td>
          </tr>
        </tbody>
        </v-simple-table>
        </v-card-text>
    </v-card>
</template>
<script>
      export default {
        props: {
          asset: {
              type: Object
            }
        },
        computed: {
          units() {
            return this.$store.state.User.units
          }
        },
      }
</script>