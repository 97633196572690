<template>
    <v-container fluid>
      <Head>
        <template v-slot:title>
          <v-btn icon :to="{name: 'overview'}"><v-icon>mdi-arrow-left</v-icon></v-btn>
          <v-icon color="red">mdi-fire</v-icon>Fire Alarm
        </template>
        <template v-slot:subtitle>
       ระบบแจ้งเตือนเหตุเพลิงไหม้
      </template>
        </Head>
    <v-tabs centered v-model="tab" v-if="assets.length > 0">
      <v-tab>Dashboard</v-tab>
      <v-tab>Status</v-tab>
      <v-tab>Analytic</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" v-if="assets.length > 0">
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <Dashboard />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <Status />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
           <Analytic/>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <v-card v-else flat >
      <v-card-title > <v-spacer/> This feature not available for your building<v-spacer/> </v-card-title>
      <v-img :src="require('@/assets/undraw_city_life_gnpr.svg')">
      </v-img>
    </v-card>
    </v-container>
  </template>
  
  <script>

  import Head from '@/components/layout/Head.vue'
  import Dashboard from '@/components/firealarm_system/fire_alarm_dashboard.vue'
  import Status from '@/components/firealarm_system/fire_alarm_status.vue'
  import Analytic from '@/components/firealarm_system/fire_alarm_analytics.vue'

    export default {
      name: 'FireAlarmView',
      components: {
        Status,
        Head,
        Dashboard,
        Analytic
      },
      computed: {
        building: {
          get: function() {
            return this.$store.state.User.building
          },
          set: function(newValue) {
            this.$store.dispatch('User/SET_BUILDING', newValue)
          }
        },
        assets: {
          get: function() {
            return this.$store.state.Asset.assetFireAlarm
          },
        }
        ,
        height () {
          switch (this.$vuetify.breakpoint.name) {
            case 'xs': return 220
            case 'sm': return 400
            case 'md': return 600
            case 'lg': return 700
            case 'xl': return 900
          }
    },

      },
      methods: {
        autoRefresh() {
          this.interval = setInterval(()=>{this.refresh()}, 60000)
        },
        refresh() {
          this.$store.dispatch('Asset/GET_ALL_ASSET', this.building).then(()=>{}).catch(()=>{})
        }
      },
      destroyed () {
        if (this.interval) {
          clearInterval(this.interval)
        } 
      },
      mounted () {
        //this.autoRefresh();
      },
      data: () => ({
        tab: null,
      }),
    }
  </script>

<style scss  scoped>

 @-webkit-keyframes argh-my-eyes {
    0%   { color: #FFEBEE !important; }
    49% { color: #FFEBEE !important; }
    50% { color: #E53935 !important; }
    99% { color: #E53935 !important; }
    100% { color: #FFEBEE !important; }
  }
  @-moz-keyframes argh-my-eyes {
    0%   { color: #FFEBEE !important; }
    49% { color: #FFEBEE !important; }
    50% { color: #E53935 !important; }
    99% { color: #E53935 !important; }
    100% { color: #FFEBEE !important; }
  }
  @keyframes argh-my-eyes {
    0%   { color: #FFEBEE; }
    49% { color: #FFEBEE; }
    50% { color: #E53935; }
    99% { color: #E53935; }
    100% { color: #FFEBEE; }
  }

  .blink {
  -webkit-animation: argh-my-eyes 2s infinite !important;
  -moz-animation:    argh-my-eyes 2s infinite !important;
  animation:         argh-my-eyes 2s infinite !important;
}



</style>