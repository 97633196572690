import { getNetwork, getConnector, updateNetwork } from '@/api/'
import Vue from 'vue'
import * as alasql from 'alasql'

const state = () => ({
   network: [],
   connector: []
})

const mutations = {
    SET_NETWORK(state, data) {
        state.network = data
    },
    SET_CONNECTOR(state, data) {
        state.connector = data
    }
}

const actions = {
    GET_NETWORK({ commit,dispatch }) {
        return new Promise((resolve, reject) => {
            getNetwork((message)=>{
                if (message.data) {
                    commit('SET_NETWORK', message.data)
                    resolve()
                } else {
                    resolve()
                }
            }, (error)=>{
                reject(error)
            })
        })
    },
    UPDATE_NETWORK({ commit,rootState,dispatch }, data) {
        data.tenantId =  rootState.User.tenant.TenantId
        return new Promise((resolve, reject) => {
            updateNetwork(data, (message)=>{
                if (message.data) {
                    //commit('SET_NETWORK', message.data)
                    resolve()
                } else {
                    resolve()
                }
            }, (error)=>{
                reject(error)
            })
        })
    },
    GET_CONNECTOR({ commit, dispatch }, data) {
        return new Promise((resolve, reject) => {
            getConnector({buildingId: data.buildingId},(message)=>{
                if (message.data) {
                    commit('SET_CONNECTOR', message.data)
                    resolve(message.data)
                } else {
                    resolve()
                }
            }, (error)=>{
                reject(error)
            })
        })
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
  }
  