<template>
    <v-hover v-slot="{ hover }">
        <v-card :elevation="hover ? 6 : 2">
            <v-card-text>
                <div ref="chart" style="width: 100%;height:350px;"></div>
            </v-card-text>
        </v-card>
    </v-hover>

</template>
<script>
import * as echarts from 'echarts';

export default {
    props: {
        update: {
            type: Boolean
        },
        axis: {
            type: Array
        },
        peak: {
            type: Array
        },
        offPeak: {
            type: Array
        },
        demand: {
            type: Array
        }
    },
    watch: {
        update() {
            setTimeout(() => {
            this.myChart.resize()
            }, 80);
        },
        axis(value) {
            this.myChart.setOption({
                xAxis: {
                    data: value
                },
                series: [
                    {
                        name: 'Peak',
                        type: 'bar',
                        stack: 'energy',
                        label: {
                            show: true
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        data: this.peak
                    },
                    {
                        name: 'Off Peak',
                        type: 'bar',
                        stack: 'energy',
                        label: {
                            show: true
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        data: this.offPeak
                    },
                    {
                        name: 'Demand',
                        type: 'line',
                        yAxisIndex: 1,
                        label: {
                            show: true
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        data: this.demand
                    }

                ]
            });
            this.myChart.resize()
        }
    },
    data() {
        return {
            option: {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
                    }
                },
                toolbox: {
                    itemGap: 20,
                    show: true,
                    showTitle: false, // hide the default text so they don't overlap each other
                    feature: {
                        restore: { show: true },
                        magicType: {
                             type: ['line', 'bar']
                         },
                        saveAsImage: {
                            show: true,
                            title: 'Save As Image'
                        },
                        dataView: {
                            show: true,
                            title: 'Data View'
                        },
                    }
                },
                legend: {},
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    data: []
                },
                yAxis: [
                    {
                        type: 'value',
                        name: 'Usage (kWh)',
                        axisLabel: {
                            formatter: '{value} kWh'
                        }
                    },
                    {
                        type: 'value',
                        name: 'Demand (kW)',
                        axisLabel: {
                            formatter: '{value} kW'
                        }
                    }
                ],
                series: []
            }
        }
    },
    mounted() {
        this.myChart = echarts.init(this.$refs.chart);
        this.myChart.setOption(this.option);
    },
}

</script>