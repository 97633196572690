<template>
    <v-row>
     <v-col cols="12">
       <v-hover  v-slot="{ hover }">
       <v-card :elevation="hover ? 6 : 2" >
         <v-card-text>
             <v-row>
                <v-spacer/>
                 <v-col cols="">
                     <v-autocomplete solo dense :items="reportLists"></v-autocomplete>
                 </v-col>
                 <v-col cols="auto">
                    <vc-date-picker
        v-model="range"
        :masks="masks"
        is-range
        :max-date='new Date()'
      >
        <template v-slot="{ inputValue, inputEvents, isDragging }">
          <ButtonDatePicker  v-on="inputEvents.start || inputEvents.end" color="primary">
            {{ inputValue.start }} ถึง {{ inputValue.end }}
          </ButtonDatePicker>        
        </template>
      </vc-date-picker>
                 </v-col>
                 <v-col cols="auto">
                    <v-btn color="primary">View</v-btn>
                 </v-col>
                 <v-col cols="auto">
                       <v-btn outlined color="primary">Export</v-btn>
                    </v-col>
             </v-row>
            
         </v-card-text>
         </v-card>
     </v-hover>
     </v-col>
     </v-row>
</template>
<script>
import ButtonDatePicker from '@/components/common/ButtonDatePicker'

export default {
    components: {
        ButtonDatePicker,
    },
    data() {
      return {
        reportLists: [
            { text: 'รายงานการแจ้งเตือนรายเดือน' }
        ],
        range: {
          start: this.$dayjs().subtract(1, 'day').format(),
          end: this.$dayjs().format(),
        },
        masks: {
          input: 'YYYY-MM-DD',
        },
        }
    },
}
</script>