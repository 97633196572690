<template>
  <div ref="chart" style="width: 100%;height:120px;"></div>
</template>
<script>
import * as echarts from 'echarts/core';
import { GridComponent } from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use([GridComponent, LineChart, CanvasRenderer, UniversalTransition]);
Array.prototype.max = function() {
  return Math.max.apply(null, this);
};

Array.prototype.min = function() {
  return Math.min.apply(null, this);
};

export default {
    props: {
        title: {
            type: String,
            default: 'Title'
        },
        category: {
            type: Array,
        },
        data: {
            type: Array,
        }
    },
    data() {
        return {
            mychart: null
        }
    },
    watch: {
        category(val) {
            // val && this.set()
        },
        data(val) {
           val && this.setChart()
        }
    },
    methods: {
        setChart() {
            this.mychart = echarts.init(this.$refs.chart);
            this.mychart.setOption({
              title: {
                text: this.title,
                left: 'center',
                bottom: '20'
              },
              xAxis: {
                type: 'category',
                data: this.category
              },
              yAxis: {
                type: 'value',
                axisLabel: {
                  formatter: `${(this.title === 'ActivePower') ? '{value} kW' : (this.title.includes('Voltage')) ? '{value} V' : (this.title.includes('Current')) ? '{value} A' : (this.title.includes('EnergyConsumption')) ? '{value} kWh' : '{value}'}`,
                }
              },
              tooltip: {
                trigger: 'axis',
                formatter: `${(this.title === 'ActivePower') ? '{c} kW' : (this.title.includes('Voltage')) ? '{c} V' : (this.title.includes('Current')) ? '{c} A' : (this.title.includes('EnergyConsumption')) ? '{c} kWh' : '{c}'}`,
              },
              visualMap: [
                {
                  show: false,
                  type: 'continuous',
                  seriesIndex: 0,
                  min: this.data.min() - ((this.data.min()/1000)*30),
                  max: this.data.max() + ((this.data.max()/1000)*30)
                }],
              grid: [{
                top: '4%',
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
              }],
              series: [
                {
                  data: this.data,
                  type: 'line',
                  smooth: true
                }
              ]
            });
            this.mychart.resize()
        }
    },
    mounted () {
        this.mychart = echarts.init(this.$refs.chart);
        const resizeObserver = new ResizeObserver((entries) => {
            this.mychart.resize();
        });
        resizeObserver.observe(this.$refs.chart);
        this.setChart()
    },
}
</script>