<template>
    <v-container fluid>
      <Head>
        <template v-slot:title>
          <v-icon color="red">mdi-chart-line</v-icon>Smart Alarm History
        </template>
        </Head>
      <FireAlarmHistoryFilter/>
      <FireAlarmHistoryDataTable/>
    </v-container>
  </template>
  
  <script>
  import FireAlarmHistoryFilter from '@/components/fire_alarm_history/fire_alarm_history_filter.vue'
  import FireAlarmHistoryDataTable from '@/components/fire_alarm_history/fire_alarm_history_datatable.vue'

  import Head from '@/components/layout/Head.vue'

    export default {
      name: 'HelloWorld',
      components: {
        Head,
        FireAlarmHistoryFilter,
        FireAlarmHistoryDataTable
      },
      data: () => ({
        buildings: ['อาคารศรีสวรินทิรานุสรณ์ 150 ปี'],
        selectedBuilding: 'อาคารศรีสวรินทิรานุสรณ์ 150 ปี',
        value: [
          423,
          446,
          675,
          510,
          590,
          610,
          760,
        ],
        items: [
        {
          action: 'mdi-flash-triangle',
          items: [{ title: 'List Item' }],
          title: 'Over Voltage',
        },
        {
          action: 'mdi-lightning-bolt',
          active: true,
          items: [
            { title: 'Breakfast & brunch' },
            { title: 'New American' },
            { title: 'Sushi' },
          ],
          title: 'Under Voltage',
        },
        {
          action: 'mdi-current-dc',
          items: [{ title: 'List Item' }],
          title: 'Over Current',
        },
        {
          action: 'mdi-scale-unbalanced',
          items: [{ title: 'List Item' }],
          title: 'Unbalance',
        },
        {
          action: 'mdi-current-ac',
          items: [{ title: 'List Item' }],
          title: 'Power Factor',
        },
        {
          action: 'mdi-sine-wave',
          items: [{ title: 'List Item' }],
          title: 'Harmonic',
        },
        {
          action: 'mdi-current-ac',
          items: [{ title: 'List Item' }],
          title: 'Frequency',
        },
      ],
        ecosystem: [
          {
            text: 'vuetify-loader',
            href: 'https://github.com/vuetifyjs/vuetify-loader',
          },
          {
            text: 'github',
            href: 'https://github.com/vuetifyjs/vuetify',
          },
          {
            text: 'awesome-vuetify',
            href: 'https://github.com/vuetifyjs/awesome-vuetify',
          },
        ],
        importantLinks: [
          {
            text: 'Documentation',
            href: 'https://vuetifyjs.com',
          },
          {
            text: 'Chat',
            href: 'https://community.vuetifyjs.com',
          },
          {
            text: 'Made with Vuetify',
            href: 'https://madewithvuejs.com/vuetify',
          },
          {
            text: 'Twitter',
            href: 'https://twitter.com/vuetifyjs',
          },
          {
            text: 'Articles',
            href: 'https://medium.com/vuetify',
          },
        ],
        whatsNext: [
          {
            text: 'Explore components',
            href: 'https://vuetifyjs.com/components/api-explorer',
          },
          {
            text: 'Select a layout',
            href: 'https://vuetifyjs.com/getting-started/pre-made-layouts',
          },
          {
            text: 'Frequently Asked Questions',
            href: 'https://vuetifyjs.com/getting-started/frequently-asked-questions',
          },
        ],
      }),
    }
  </script>
  