<template>
  <v-row class="pt-2">
    <v-col cols="12">
      <v-hover v-slot="{ hover }">
        <v-card :elevation="hover ? 6 : 2">
          <v-card-title><v-spacer />
            <v-btn-toggle mandatory v-model="toggle" rounded dense>
              <v-btn v-for="(item, index) in menus" :key="index">
                {{ item }}
              </v-btn>
            </v-btn-toggle>
            <v-spacer />
            <!-- <v-spacer/> <v-switch v-model="enable" label="Analytic Mode" inset></v-switch></v-card-title> -->
          </v-card-title>
        </v-card>
      </v-hover>
    </v-col>
    <v-col cols="12">
      <v-tabs-items v-model="toggle">
        <v-tab-item :key="'DETAILS'">
          <v-card>
            <v-row class="mr-2 ml-2">
              <v-spacer/>
              <v-col cols="auto">
                <v-select placeholder="เลือกมิเตอร์" solo dense :items="assets" item-text="assetName" multiple v-model="selectedMeter" return-object/>
              </v-col>
            </v-row>
            <v-row class="pb-2">
              <v-col cols="12" md="4" v-for="(item, index) in selectedMeter" :key="index">
                <ELECTRICAL_METER_CARD :asset="item" />
              </v-col>
            </v-row>
          </v-card>
        </v-tab-item>
        <v-tab-item :key="'SUMMARY'">
          <v-row class="">
            <v-col cols="12" md="4">
              <POWER_QUALITY_CARD title="Power Quality 24 Hr." v-bind="dailyRange"/>
            </v-col>
            <v-col cols="12" md="4" >
              <POWER_QUALITY_CARD title="Power Quality 30 Days" v-bind="monthlyRange"/>
            </v-col>
           <v-col cols="12" md="4">
              <POWER_QUALITY_CARD title="Power Quality 60 Days" v-bind="twiceMonthRange"/>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </v-col>



  </v-row>
</template>
<script>
import DAILY_POWER_QUALITY from '@/components/energy_analysis/daily_power_quality'
import MONTHLY_POWER_QUALITY from '@/components/energy_analysis/monthly_power_quality'
import WEEKLY_POWER_QUALITY from '@/components/energy_analysis/weekly_power_quality'
import ELECTRICAL_METER_CARD from './electrical_meter_card.vue';
import POWER_QUALITY_CARD from '@/components/energy_analysis/power_quality_card.vue'

export default {
  components: {
    DAILY_POWER_QUALITY,
    MONTHLY_POWER_QUALITY,
    WEEKLY_POWER_QUALITY,
    ELECTRICAL_METER_CARD,
    POWER_QUALITY_CARD
  },
  data() {
    return {
      menus: ['DETAILS', 'SUMMARY'],
      toggle: 'DETAILS',
      selectedMeter: [],
      dailyRange: {
        start:  this.$dayjs().local().subtract(1, 'day').unix() * 1000,
        end: this.$dayjs().local().endOf('day').unix() * 1000
      },
      monthlyRange:{
        start:  this.$dayjs().local().subtract(1, 'month').unix() * 1000,
        end:  this.$dayjs().local().endOf('month').unix() * 1000
      },
      twiceMonthRange:{
        start: this.$dayjs().local().subtract(2, 'month').unix() * 1000,
        end:  this.$dayjs().local().endOf('month').unix() * 1000
      },
    }
  },
  mounted () {
    this.selectedMeter = [ ...this.main_meter ]
  },
  computed: {
    assets() {
      return this.$store.state.Asset.assetEnergy.filter((el) => el.assetType === 'PowerMeter')
    },
    main_meter() {
        return this.$store.state.Energy.mainMeter
    },
  },
  methods: {

  },
}

</script>