<template>
<v-hover  v-slot="{ hover }">   
      <v-card :elevation="hover ? 6 : 2" :height="height">   
      <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      style="z-index: 99999;"
      >
    <v-card tile :key="dialog" height="100%">
      <v-card-title>{{ building.BuildingName }}<v-spacer/><v-btn icon @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn></v-card-title>
      <v-tabs vertical v-model="level" >
          <v-tab v-for="(item,index) in building.floors" :key="index" :href="`#floor-${item.Floor}`">

       
            <v-icon left class="blink"     v-if="(alertByService['20340ea6-88dd-11ee-b9d1-0242ac120002'])? alertByService['20340ea6-88dd-11ee-b9d1-0242ac120002'].filter(el=> Number(el.floor) === Number(item.Floor)).length : false ">
              mdi-domain
            </v-icon>

            <v-icon left v-else>
              mdi-domain
            </v-icon>

            ชั้น {{ item.Floor }} 
          </v-tab>
        <v-tab-item  v-for="(item,index) in building.floors" :key="index" :value="`floor-${item.Floor}`">
        <v-card flat>
          <FLOOR_PLAN :floor="item" @onFullscreen="dialog = true" :fullscreen="true"/>
        </v-card>
       </v-tab-item>
      </v-tabs>
    </v-card>
  </v-dialog>   
        <v-tabs vertical v-model="level" >
          <v-tab v-for="(item,index) in building.floors" :key="index" :href="`#floor-${item.Floor}`">
            <v-icon left  class="blink"     v-if="(alertByService['20340ea6-88dd-11ee-b9d1-0242ac120002'])? alertByService['20340ea6-88dd-11ee-b9d1-0242ac120002'].filter(el=> Number(el.floor) === Number(item.Floor)).length : false ">
              mdi-domain
            </v-icon>
          <v-icon left v-else>
              mdi-domain
            </v-icon>
            ชั้น {{ item.Floor }} 
          
          </v-tab>
        <v-tab-item  v-for="(item,index) in building.floors" :key="index" :value="`floor-${item.Floor}`">
        <v-card flat>

          <FLOOR_PLAN :floor="item" @onFullscreen="dialog = true" :fullscreen="false"/>
        </v-card>
       </v-tab-item>
      </v-tabs>
    </v-card>

</v-hover>
</template>

<script>
  import { mapState } from 'vuex'
  import FLOOR_PLAN from '@/components/firealarm_system/floor_plan'
  export default {
    components: {
        FLOOR_PLAN,
    },
    data() {
      return {
        dialog: false,
        level: null
      }
    },
    watch: {
      building() {
        this.level = null
      }
    },
    computed: {
        ...mapState('Alert', ['alertByService']),
        building: {
          get: function() {
            return this.$store.state.User.building
          },
          set: function(newValue) {
            this.$store.dispatch('User/SET_BUILDING', newValue)
          }
        },
        height () {
          switch (this.$vuetify.breakpoint.name) {
            case 'xs': return 220
            case 'sm': return 400
            case 'md': return 600
            case 'lg': return 700
            case 'xl': return 900
          }
    },
    },
  }

</script>

<style scss scoped>
 @-webkit-keyframes argh-my-eyes {
    0%   { color: #FFEBEE !important; }
    49% { color: #FFEBEE !important; }
    50% { color: #E53935 !important; }
    99% { color: #E53935 !important; }
    100% { color: #FFEBEE !important; }
  }
  @-moz-keyframes argh-my-eyes {
    0%   { color: #FFEBEE !important; }
    49% { color: #FFEBEE !important; }
    50% { color: #E53935 !important; }
    99% { color: #E53935 !important; }
    100% { color: #FFEBEE !important; }
  }
  @keyframes argh-my-eyes {
    0%   { color: #FFEBEE; }
    49% { color: #FFEBEE; }
    50% { color: #E53935; }
    99% { color: #E53935; }
    100% { color: #FFEBEE; }
  }

  .blink {
  -webkit-animation: argh-my-eyes 2s infinite !important;
  -moz-animation:    argh-my-eyes 2s infinite !important;
  animation:         argh-my-eyes 2s infinite !important;
}


</style>