<template>
    <v-row class="pt-2">
        <v-col cols="12">
            <v-hover v-slot="{ hover }">
                <v-card :elevation="hover ? 6 : 2" :disabled="loading">
                    <v-card-text>
                        <v-row dense>
                            <v-col cols="12" md="1">
                                <!-- <v-avatar class="mr-2" size="100" tile> -->
                                    <v-img height="100px" :src="asset.MapImage || require('@/assets/undraw_photos_re_pvh3.svg')" />
                                <!-- </v-avatar> -->
                            </v-col>
                            <v-col cols="12" md="auto">
                                <div class="text-left text-h5 font-weight-bold black--text">
                                    <v-icon>mdi-gauge</v-icon>
                                    {{ asset.assetName }}
                                    <ASSET_ALERT_ICON :asset="asset"/>
                                </div>
                             
                                <div class="text-left text-subtitle-1">{{ asset.location }} </div>
                                <div class="text-left text-subtitle-1">ข้อมูลเมื่อ {{
                                    $dayjs(asset.heartbeat).format('HH:mm DD MMM YYYY') }}</div>
                                      <ASSET_MENU :asset="asset" :align="'left'" />
                            </v-col>
                            <v-spacer />
                            <v-col cols="auto">
                                เลือกช่วงเวลา
                                <DateRangePicker v-model="range" />
                            </v-col>
                            <v-col cols="auto"> <v-btn color="primary" :disabled="loading"
                                    @click="refresh">VIEW</v-btn></v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="2">
                                <div ref="meterCompare" style="width: 100%;height:250px;"></div>
                                <div class="text-center text-h5 font-weight-bold black--text"> {{
                                    Number(JSON.parse(asset.data).Unit).toFixed(0) }} Unit. </div>
                                <div class="text-center text-h6 font-weight-bold black--text"> {{ asset.assetName }}
                                </div>
                                <div class="text-center black--text"> {{ asset.location }} </div>
                                <div class="text-center black--text"> ข้อมูลเมื่อ {{
                                    $dayjs(asset.heartbeat).format('HH:mm DD MMM YYYY') }} </div>
                                <div class="text-center black--text"> <v-icon>mdi-meter</v-icon>
                                    Water Meter
                                </div>
                            </v-col>
                            <v-col cols="10">
                                <div ref="meterChart" style="width: 100%;height:250px;"></div>
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="2"></v-col>
                            <v-col cols="10">
                                <div ref="meterDistChart" style="width: 100%;height:250px;"></div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-hover>
        </v-col>
    </v-row>
</template>

<script>
import * as echarts from 'echarts/core';
import {
    TitleComponent,
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    MarkLineComponent,
    MarkPointComponent,
    DatasetComponent,
    VisualMapComponent
} from 'echarts/components';
import { LineChart, HeatmapChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import DateRangePicker from '@/components/common/DateRangePicker.vue'
import * as alasql from 'alasql'
import ASSET_MENU from '@/components/asset/asset_menu.vue'
import ASSET_ALERT_ICON from '@/components/asset/asset_alert_icon.vue'
echarts.use([
    TitleComponent,
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    MarkLineComponent,
    MarkPointComponent,
    LineChart,
    CanvasRenderer,
    UniversalTransition,
    DatasetComponent,
    VisualMapComponent,
    HeatmapChart
]);

// prettier-ignore
const hours = [
    '12a', '1a', '2a', '3a', '4a', '5a', '6a',
    '7a', '8a', '9a', '10a', '11a',
    '12p', '1p', '2p', '3p', '4p', '5p',
    '6p', '7p', '8p', '9p', '10p', '11p'
];
// prettier-ignore
const days = [
    'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'
];

export default {
    props: {
        asset: {
            type: Object
        },
    },
    components: {
        DateRangePicker,
        ASSET_MENU,
        ASSET_ALERT_ICON
    },
    data() {
        return {
            range: {
                startDate: this.$dayjs().local().startOf('month').toDate(),
                endDate: this.$dayjs().local().endOf('month').toDate(),
            },
            meterChart: null,
            meterCompare: null,
            meterDistChart: null,
            loading: false
        }
    },
    computed: {
        building: {
            get: function () {
                return this.$store.state.User.building
            },
            set: function (newValue) {
                this.$store.dispatch('User/SET_BUILDING', newValue)
            }
        },
    },
    mounted() {
        this.meterChart = echarts.init(this.$refs.meterChart);
        this.meterCompare = echarts.init(this.$refs.meterCompare);
        this.meterDistChart = echarts.init(this.$refs.meterDistChart);
        const resizeObserver = new ResizeObserver((entries) => {
                this.meterChart.resize();
                this.meterCompare.resize()
                this.meterDistChart.resize()
        });
        resizeObserver.observe(this.$refs.meterChart);
        this.refresh()
    },
    methods: {
        refresh() {
         

            this.getMeterData();
            this.setMeterCompare();
            this.getMeterDist()
        },
        getMeterDist() {
            this.$store.dispatch('Water/GET_WATER_CONSUMPTION', {
                BuildingId: this.building.BuildingId,
                deviceId: this.asset.deviceId,
                start: Math.floor(this.$dayjs().local().subtract(1, 'week').startOf('week').valueOf()),
                end: Math.floor(this.$dayjs().local().valueOf()),
                type: 'hourly',
                group: true
            }).then((data) => {

                let maxValue = alasql(`select Max(Unit) as max from ?`, [data])
                let minValue = alasql(`select Min(Unit) as min from ?`, [data])
                let dataGroup = alasql(`select weekDay, H, AVG(Unit) as Unit from ? group by weekDay, H`, [data])
                let series = dataGroup.map(a => [Number(a.weekDay), Number(a.H), Number(a.Unit)]).map(function (item) {
                    return [item[1], item[0], item[2].toFixed(1) || '-'];
                })
                this.meterDistChart.setOption({
                    tooltip: {
                        position: 'top'
                    },
                    visualMap: {
                        min: minValue[0].min,
                        max: maxValue[0].max,
                        calculable: true,
                        orient: 'horizontal',
                        left: 'center',
                        bottom: '15%'
                    },
                    grid: {
                        height: '50%',
                        top: '10%'
                    },
                    xAxis: {
                        type: 'category',
                        data: hours,
                        splitArea: {
                            show: true
                        }
                    },
                    yAxis: {
                        type: 'category',
                        data: days,
                        splitArea: {
                            show: true
                        }
                    },
                    visualMap: {
                        min: 0,
                        max: 300,
                        calculable: true,
                        orient: 'horizontal',
                        left: 'center',
                        bottom: '15%'
                    },
                    series: [
                        {
                            name: 'kWh',
                            type: 'heatmap',
                            label: {
                                show: true
                            },

                            data: series,
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            }
                        },

                    ]
                })
                this.meterDistChart.resize()
            }).catch((err) => {
                console.log(err)
            })


        },
        setMeterCompare() {
            this.$store.dispatch('Water/GET_WATER_CONSUMPTION', {
                BuildingId: this.building.BuildingId,
                deviceId: this.asset.deviceId,
                start: Math.floor(this.$dayjs().local().subtract(1, 'week').startOf('week').valueOf()),
                end: Math.floor(this.$dayjs().local().valueOf()),
                type: 'weekly',
                group: false
            }).then((data) => {
                let series = data.map((el) => {
                    return el.Unit
                })
                this.meterCompare.setOption({
                    title: {},
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        },
                        formatter: '{c} cm.'
                    },
                    toolbox: {
                        show: false,
                        feature: {
                            saveAsImage: {}
                        }
                    },
                    grid: {
                        left: '10%',
                        right: '10%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'category',
                        data: ['Week-1', 'This week']
                    },
                    yAxis: {
                        type: 'value',
                        min: 0,
                        max: function (value) {
                            return Number(value.max + 20).toFixed(0);
                        },
                        boundaryGap: [0, 0.01],
                    },
                    series: [
                        {
                            name: 'Water Consumption',
                            type: 'bar',
                            label: {
                                show: true,
                                position: 'top',
                                formatter: '{c} Unit'
                            },
                            showBackground: true,
                            backgroundStyle: {
                                color: 'rgba(180, 180, 180, 0.2)'
                            },
                            data: series
                        }
                    ]
                })
                this.meterCompare.resize()
            }).catch((err) => {
                console.log(err)
            })



        },
        getMeterData() {
            this.$store.dispatch('Water/GET_WATER_CONSUMPTION', {
                BuildingId: this.building.BuildingId,
                deviceId: this.asset.deviceId,
                start: Math.floor(this.range.startDate.getTime()),
                end: Math.floor(this.range.endDate.getTime()),
                type: 'daily',
                group: false
            }).then((data) => {
                let series = []
                let axis = []
                let sorted = data.sort((a, b) => {
                    let dateA = this.$dayjs(a.date, "YY-MM-DD")
                    let dateB = this.$dayjs(b.date, "YY-MM-DD")
                    if (dateA.isAfter(dateB)) {
                        return 1
                    } else {
                        return -1
                    }
                })
                sorted.forEach(element => {
                    series.push(Number(element.Unit).toFixed(2))
                    axis.push(this.$dayjs(element.date, "YY-MM-DD").format('DD MMM YY'))
                })
                this.meterChart.setOption({
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
                        }
                    },
                    toolbox: {
                        itemGap: 20,
                        show: true,
                        showTitle: false, // hide the default text so they don't overlap each other
                        feature: {
                            restore: { show: true },
                            magicType: {
                                type: ['line', 'bar']
                            },
                            saveAsImage: {
                                show: true,
                                title: 'Save As Image'
                            },
                            dataView: {
                                show: true,
                                title: 'Data View'
                            },
                        }
                    },
                    legend: {},
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'category',
                        data: axis
                    },
                    yAxis: {
                        type: 'value',
                        name: 'Usage (Unit)',
                        axisLabel: {
                            formatter: '{value} Unit'
                        }
                    },
                    series: {
                        name: 'Usage',
                        type: 'bar',
                        label: {
                            show: true
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        data: series
                    },
                });

                this.meterChart.resize()
            }).catch((err) => {
                console.log(err)
            })

        },
    },

}

</script>