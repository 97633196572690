<template>
    <div>
        <!-- Your component's HTML template goes here -->
       
    </div>
</template>

<script>
export default {
    name: 'AirQualityTable',
    // Your component's JavaScript logic goes here

    //Insert methods that calcualte dispaly size
    methods: {
        calculateDisplaySize() {
            const screenWidth = window.innerWidth;
            const screenHeight = window.innerHeight;
            // Code to calculate display screen size goes here
            // ...
        }
    }
}
</script>

<style scoped>
/* Your component's CSS styles go here */
</style>