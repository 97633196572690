import { getNotifyToken, createNotifyToken, deleteNotifyToken, sendLine } from '@/api/'


const state = () => ({
})

const mutations = {
}

const actions = {
    SEND_LINE({commit, rootState}, data) {
        data.buildingId = rootState.User.building.BuildingId
        data.tenantId =  rootState.User.tenant.TenantId
        return new Promise((resolve, reject) => {
            sendLine(data ,(message)=>{
                if (message.data) {
                    resolve(message.data)
                } else {
                    resolve()
                }
            }, (error)=>{
                reject(error)
            })
        })
    },
    GET_TOKEN_BY_DEVICE({commit, rootState}, { assetId }) {
        return new Promise((resolve, reject) => {
            getNotifyToken({buildingId: rootState.User.building.BuildingId, assetId: assetId, },(message)=>{
                if (message.data) {
                    resolve(message.data)
                } else {
                    resolve()
                }
            }, (error)=>{
                reject(error)
            })
        })
    },
    UPDATE_TOKEN_BY_DEVICE({commit, rootState}, data) {
        data.buildingId = rootState.User.building.BuildingId
        data.tenantId =  rootState.User.tenant.TenantId
        return new Promise((resolve, reject) => {
            createNotifyToken(data ,(message)=>{
                if (message.data) {
                    resolve(message.data)
                } else {
                    resolve()
                }
            }, (error)=>{
                reject(error)
            })
        })
    },
    DELETE_TOKEN_BY_DEVICE({commit, rootState}, data) {
        data.buildingId = rootState.User.building.BuildingId
        data.tenantId =  rootState.User.tenant.TenantId
        return new Promise((resolve, reject) => {
            deleteNotifyToken(data ,(message)=>{
                if (message.data) {
                    resolve(message.data)
                } else {
                    resolve()
                }
            }, (error)=>{
                reject(error)
            })
        })
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
  }
  