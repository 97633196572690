import { getEnergy, getEnergyReprocess, getElectrical, getDistBoard} from '@/api/'
import Vue from 'vue'
import * as alasql from 'alasql'

const groupBy = key => array =>
    array.reduce((objectsByKeyValue, obj) => {
        const value = obj[key]
        objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj)
        return objectsByKeyValue
    }, {})

const groupByAssetType = groupBy('assetType')

const state = () => ({
    hourlyEnergy: [],
    dailyEnergy: [],
    dailyEnergyCompare: [],
    weeklyEnergy: [],
    weeklyEnergyCompare: [],
    monthlyEnergy: [],
    monthlyEnergyCompare: [],
    yearlyEnergy: [],
    yearlyEnergyCompare: [],
    energyTable: [],
    mainMeter: [],
    selectedMainMeter: [],
})

const mutations = {
    SET_HOURLY_ENERGY(state, data) {
        state.hourlyEnergy = alasql(`select weekDay, H, AVG(Unit) as Unit from ? group by weekDay, H`, [data])
    },
    SET_DAILY_ENERGY(state, data) {
        if (data) {
            state.dailyEnergy = data.sort(function (a, b) {
                if (Vue.prototype.$dayjs(a.date).unix() > Vue.prototype.$dayjs(b.date).unix()) {
                    return true
                } else {
                    return false
                }
            })
        } else {
            state.dailyEnergy = data
        }
    },
    SET_WEEKLY_ENERGY(state, data) {
        state.weeklyEnergy = data.sort(function (a, b) {
            if (Number(a.year) === Number(b.year)) {
                return Number(a.week) - Number(b.week)
            }
            return Number(a.year) > Number(b.year) ? 1 : -1;
        })
    },
    SET_MONTHLY_ENERGY(state, data) {
        state.monthlyEnergy = data.sort(function (a, b) {
            if (Number(a.year) === Number(b.year)) {
                return Number(a.month) - Number(b.month)
            }
            return Number(a.year) > Number(b.year) ? 1 : -1;
        })
    },
    SET_MONTHLY_ENERGY_COMPARE(state, data) {
        state.monthlyEnergyCompare = data.sort(function (a, b) {
            if (Number(a.year) === Number(b.year)) {
                return Number(a.month) - Number(b.month)
            }
            return Number(a.year) > Number(b.year) ? 1 : -1;
        })
    },
    SET_DAILY_ENERGY_COMPARE(state, data) {
        state.dailyEnergyCompare = data.sort(function (a, b) {
            if (Vue.prototype.$dayjs(a.date).unix() > Vue.prototype.$dayjs(b.date).unix()) {
                return true
            } else {
                return false
            }
        })
    },
    SET_WEEKLY_ENERGY_COMPARE(state, data) {
        state.weeklyEnergyCompare =  data.sort(function (a, b) {
            if (Number(a.year) === Number(b.year)) {
                return Number(a.week) - Number(b.week)
            }
            return Number(a.year) > Number(b.year) ? 1 : -1;
        })
    },
    SET_YEARLY_ENERGY_COMPARE(state, data) {
        state.yearlyEnergyCompare = data
    },
    SET_YEARLY_ENERGY(state, data) {
        state.yearlyEnergy = data
    },
    SET_SELECTED_MAIN_METER(state, data) {
        state.selectedMainMeter = data    
    },
    SET_ENERGY_TABLE(state, data) {
        state.energyTable = data
    },
    SET_SELECTED_MAIN_METER_BUILDING_CHANGE(state){
      if ( state.mainMeter.length > 0) {
        state.selectedMainMeter =  [state.mainMeter[0]]
      } else {
        state.selectedMainMeter =  []
      }
    },
    SET_MAIN_METER(state, data) {
        state.mainMeter =data
    }
}

const actions = {
    GET_DIST_BOARD({ commit }, data) {
        return new Promise((resolve, reject) => {
            getDistBoard({ buildingId: data.BuildingId}, message => {
                resolve(message.data)
            }, error => {
                reject(error)
            })
        })
    },
    GET_ELECTRICAL({ commit }, data) {
        return new Promise((resolve, reject) => {
            getElectrical({ buildingId: data.BuildingId, start: data.start, end: data.end }, message => {
                resolve(message.data)
            }, error => {
                reject(error)
            })
        })
    },
    SET_SELECTED_MAIN_METER_BUILDING_CHANGE({commit}){
        commit('SET_SELECTED_MAIN_METER_BUILDING_CHANGE')
    },
    SET_MAIN_METER({commit, rootState}) {
        commit('SET_MAIN_METER', rootState.Asset.assetEnergy.filter(el=> el.root === true) )
    },
    SET_SELECTED_MAIN_METER({commit}, data) {
        commit('SET_SELECTED_MAIN_METER', data)
    },
    GET_ENERGY_REPROCESS({ commit }, data) {
        return new Promise((resolve, reject) => {
            getEnergyReprocess({ buildingId: data.buildingId, deviceId: data.deviceId, period: data.period, maximum: data.maximum }, message => {
                resolve(message)
            }, error => {
                reject(error)
            })
        })
    },
    GET_HOURLY_ENERGY({ commit }, data) {
        return new Promise((resolve, reject) => {
            getEnergy({ buildingId: data.BuildingId, type: data.type, deviceId: data.deviceId,start: data.start, end: data.end, group: data.group }, message => {
                commit('SET_HOURLY_ENERGY', message.data)
                resolve(message.data)
            }, error => {
                reject(error)
            })
        })
    },
    GET_DAILY_ENERGY({ commit }, data) {
        return new Promise((resolve, reject) => {
            getEnergy({ buildingId: data.BuildingId, type: data.type, deviceId: data.deviceId,start: data.start, end: data.end, group: data.group }, message => {        
                commit('SET_DAILY_ENERGY', message.data)
                resolve(message.data)
            }, error => {
                reject(error)
            })
        })
    },
    GET_WEEKLY_ENERGY({ commit }, data) {
        return new Promise((resolve, reject) => {
            getEnergy({ buildingId: data.BuildingId, type: data.type, deviceId: data.deviceId ,start: data.start, end: data.end, group: data.group}, message => {
                commit('SET_WEEKLY_ENERGY', message.data)
                resolve(message.data)
            }, error => {
                reject(error)
            })
        })
    },
    GET_DAILY_ENERGY_COMPARE({ commit }, data) {
        return new Promise((resolve, reject) => {
            const startCompare = Vue.prototype.$dayjs(data.start).subtract(1, 'year').local().startOf('month').unix() * 1000
            const endCompare = Vue.prototype.$dayjs(data.end).subtract(1, 'year').local().endOf('month').unix() * 1000
            getEnergy({ buildingId: data.BuildingId, type: 'daily', deviceId: data.deviceId ,start: data.start, end: data.end, group: true}, msg => {
                getEnergy({ buildingId: data.BuildingId, type: data.type, deviceId: data.deviceId ,start: startCompare, end: endCompare, group: true}, msg2 => {
                    commit('SET_DAILY_ENERGY_COMPARE', [...msg.data,...msg2.data])
                    resolve([...msg.data,...msg2.data])
                }, error => {
                    reject(error)
                })
            }, error => {
                console.log(error)
                reject(error)
            })
        })
    },
    GET_WEEKLY_ENERGY_COMPARE({ commit }, data) {
        return new Promise((resolve, reject) => {
            const start = Vue.prototype.$dayjs(data.end).local().startOf('year').unix() * 1000
            const end = Vue.prototype.$dayjs(data.end).local().endOf('week').unix() * 1000
            const startCompare = Vue.prototype.$dayjs(data.end).subtract(1, 'year').local().startOf('year').unix() * 1000
            const endCompare = Vue.prototype.$dayjs(data.end).subtract(1, 'year').local().endOf('year').unix() * 1000
            getEnergy({ buildingId: data.BuildingId, type: 'weekly', deviceId: data.deviceId ,start: start, end: end, group: true}, msg => {
                getEnergy({ buildingId: data.BuildingId, type: data.type, deviceId: data.deviceId ,start: startCompare, end: endCompare, group: true}, msg2 => {
                    commit('SET_WEEKLY_ENERGY_COMPARE', [...msg.data,...msg2.data])
                    resolve([...msg.data,...msg2.data])
                }, error => {
                    reject(error)
                })
            }, error => {
                console.log(error)
                reject(error)
            })
        })
    },
    GET_MONTHLY_ENERGY_COMPARE({ commit }, data) {
        return new Promise((resolve, reject) => {
            const start = Vue.prototype.$dayjs(data.end).local().startOf('year').unix() * 1000
            const end = Vue.prototype.$dayjs(data.end).local().endOf('month').unix() * 1000
            const startCompare = Vue.prototype.$dayjs(data.end).subtract(1, 'year').local().startOf('year').unix() * 1000
            const endCompare = Vue.prototype.$dayjs(data.end).subtract(1, 'year').local().endOf('year').unix() * 1000
            getEnergy({ buildingId: data.BuildingId, type: 'monthly', deviceId: data.deviceId ,start: start, end: end, group: true}, msg => {
                getEnergy({ buildingId: data.BuildingId, type: data.type, deviceId: data.deviceId ,start: startCompare, end: endCompare, group: true}, msg2 => {
                    commit('SET_MONTHLY_ENERGY_COMPARE', [...msg.data,...msg2.data])
                    resolve([...msg.data,...msg2.data])
                }, error => {
                    reject(error)
                })
            }, error => {
                console.log(error)
                reject(error)
            })
        })
    },
    GET_YEARLY_ENERGY_COMPARE({ commit }, data) {
        return new Promise((resolve, reject) => {
            const start = Vue.prototype.$dayjs(data.end).local().startOf('year').unix() * 1000
            const end = Vue.prototype.$dayjs(data.end).local().endOf('year').unix() * 1000
            const startCompare = Vue.prototype.$dayjs(data.end).subtract(1, 'year').local().startOf('year').unix() * 1000
            const endCompare = Vue.prototype.$dayjs(data.end).subtract(1, 'year').local().endOf('year').unix() * 1000
            getEnergy({ buildingId: data.BuildingId, type: 'yearly', deviceId: data.deviceId ,start: start, end: end, group: true}, msg => {
                getEnergy({ buildingId: data.BuildingId, type: data.type, deviceId: data.deviceId ,start: startCompare, end: endCompare, group: true}, msg2 => {
                    commit('SET_YEARLY_ENERGY_COMPARE', [...msg.data,...msg2.data])
                    resolve([...msg.data,...msg2.data])
                }, error => {
                    reject(error)
                })
            }, error => {
                console.log(error)
                reject(error)
            })
        })
    },
    GET_MONTHLY_ENERGY({ commit }, data) {
        return new Promise((resolve, reject) => {
            getEnergy({ buildingId: data.BuildingId, type: 'monthly', deviceId: data.deviceId,start: data.start, end: data.end, group: data.group }, message => {
                // commit('SET_MONTHLY_ENERGY', message.data)
                resolve(message.data)
            }, error => {
                reject(error)
            })
        })
    },
    GET_ENERGY_COMPARE({ commit }, data) {
        return new Promise((resolve, reject) => {
            getEnergy({ buildingId: data.BuildingId, type: data.type, deviceId: data.deviceId,start: data.start, end: data.end, group: data.group }, message => {
                commit('SET_MONTHLY_ENERGY_COMPARE', message.data)
                resolve(message.data)
            }, error => {
                reject(error)
            })
        })
    },
    GET_ENERGY ({ commit }, data) {
        return new Promise((resolve, reject) => {
            getEnergy({ buildingId: data.BuildingId, type: data.type, deviceId: data.deviceId,start: data.start, end: data.end, group: data.group  }, message => {
                resolve(message.data)
            }, error => {
                reject(error)
            })
        })
    },
    GET_ENERGY_TABLE({ commit }, data) {
        return new Promise((resolve, reject) => {
            getEnergy({ buildingId: data.BuildingId, type: data.type, deviceId: data.deviceId, start: data.start, end: data.end ,group: data.group }, message => {
                commit('SET_ENERGY_TABLE', message.data)
                resolve(message.data)
            }, error => {
                reject(error)
            })
        })
    },
    GET_YEARLY_ENERGY({ commit }, data) {
        return new Promise((resolve, reject) => {
            getEnergy({ buildingId: data.BuildingId, type: data.type, deviceId: data.deviceId, start: data.start, end: data.end ,group: data.group }, message => {
                commit('SET_YEARLY_ENERGY', message.data)
                resolve(message.data)
            }, error => {
                reject(error)
            })
        })
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
