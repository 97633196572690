<template>
  <v-hover v-slot="{ hover }">
    <v-card :elevation="hover ? 6 : 2" class="mt-2 mr-2 ml-2 mb-2" > 
        <v-card-text>
        <v-data-table :search="search" :loading="loading" :headers="headers" :items="network">
            <template v-slot:top>
                <v-row>
                <v-spacer/>
              <v-col cols="2">
              <v-text-field label="Search" v-model="search" solo dense prepend-inner-icon="mdi-magnify"></v-text-field>
              </v-col>
            </v-row>
            </template>
            <template v-slot:item.heartbeat="{ item }">
        {{$dayjs(item.heartbeat).fromNow()}}
    </template>
    <template v-slot:item.rowKey="{ item }">
        <span :class="(item.maintenance) ? 'grey--text':''">{{ item.rowKey }}</span> <v-icon v-if="item.maintenance" small>mdi-wrench</v-icon>
    </template>
    <template v-slot:item.maintenance="{ item }">
     <v-switch :true-value="true" :disabled="posting && role !== 'Admin'" v-model="item.maintenance" @change="updateNetwork(item)"></v-switch>
    </template>
        </v-data-table>
    </v-card-text>
    </v-card>
    </v-hover>
</template>
<script>
export default {
    data() {
        return {
            headers: [
                {text: 'Device ID', value: 'rowKey'},
                {text: 'Device Name', value: 'deviceAlias'},
                {text: 'Device Type', value: 'msgCmd'},
                {text: 'GSM Signal', value: 'signal'},
                {text: 'Zeta DownLinkRssi', value: 'downRssi'},
                {text: 'Zeta UpLinkRssi', value: 'upRssi'},
                {text: 'Battery', value: 'battery'},
                {text: 'Latest Update', value: 'heartbeat'},
                {text: 'Maintenance', value: 'maintenance'}
            ],
            loading: false,
            search: '',
            interval: null,
            posting: false,
        }
    },
    mounted () {
        this.getNetwork();
        this.interval =  setInterval(()=>{
            this.getNetwork();
        }, 60000)
    },
    destroyed () {
        this.interval = null
    },
    computed: {
        role: {
      get: function () {
        return this.$store.state.User.role
      }
    },
        building: {
        get: function () {
          return this.$store.state.User.building
        },
        set: function (newValue) {
          this.$store.dispatch('User/SET_BUILDING', newValue)
        }
      },
      network: {
        get: function () {
          return this.$store.state.Network.network
        }
      }
    },
    methods: {
        getNetwork() {
            this.loading = true
            this.$store.dispatch('Network/GET_NETWORK').then(()=>{
                this.loading = false
            }).catch(()=>{
                this.loading = false
            })
        },
        updateNetwork(item) {
            this.posting = true
            const data = {
                deviceId: item.rowKey,
                maintenance: item.maintenance
            }
            this.$store.dispatch('Network/UPDATE_NETWORK',data).then(()=>{
                this.posting = false
                this.getNetwork()
            }).catch(()=>{
                this.posting = false
                this.getNetwork()
            })
        }
    },
}

</script>