<template>
 <v-tabs vertical class="elevation-2">
      <v-tab v-for="(item,index) in items" :key="index" class="text-left">
        <v-icon left>
          mdi-graph-outline
        </v-icon>
      {{item.dbName}}
      </v-tab>
      <v-tab-item v-for="(item,index) in items" :key="index" >
        <ELECTRICAL_MAP :distboard="item"/>
      </v-tab-item>
    </v-tabs>
</template>
<script>
import ELECTRICAL_MAP from '@/components/electrical/electrical_map.vue'
export default {
  components: {
    ELECTRICAL_MAP,
  },
  data() {
    return {
      items:[],
      loading: false
    }
  },
  computed: {
    isSuperAdmin: {
      get: function () {
        return this.$store.state.User.isSupperAdmin
      }
    },
    building: {
      get: function () {
        return this.$store.state.User.building
      },
      set: function (newValue) {
        this.$store.dispatch('User/SET_BUILDING', newValue)
      },
    },
    assets() {
      return this.$store.state.Asset.assetEnergy
    },
    role: {
      get: function () {
        return this.$store.state.User.role
      }
    },
  },
  mounted() {
    this.getDistBoard()
  },
  methods: {
    getDistBoard() {
      this.loading = true
      this.$store.dispatch('Energy/GET_DIST_BOARD',{BuildingId: this.building.BuildingId}).then((data) => {
        data.forEach(distribution => {
         distribution.asset = this.assets.filter((asset) => {
          return distribution.assetId.filter((assetId) => {
            return assetId === asset.assetId
         }).length > 0
        });
          this.items = data
          this.loading = false
      })
    }).catch((error)=>{
        console.log(error)
        this.loading = false
      })
  },}
}

</script>