<template>
    <v-container fluid>
      <Head>
        <template v-slot:title>
          <v-btn icon :to="{name: 'overview'}"><v-icon>mdi-arrow-left</v-icon></v-btn>
          <v-icon >mdi-network-outline</v-icon>Network
        </template>
        <template v-slot:subtitle>
       ข้อมูลเครือข่าย
      </template>
        </Head>
        <v-row>
          <v-col cols="12">
        <v-tabs v-model="tab">
          <v-tab key="network">Network</v-tab>
          <v-tab key="connector">Connector</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
            <v-tab-item key="network">
              <DataTableNetwork/>
            </v-tab-item>
            <v-tab-item key="connector">
              <DataTableConnector/>
            </v-tab-item>
          </v-tabs-items>

           
          </v-col>
        </v-row>    
    </v-container>
  </template>
  
  <script>
  import Head from '@/components/layout/Head.vue'
  import DataTableNetwork from '@/components/network/network_table'
  import DataTableConnector from '@/components/network/connector_table'

    export default {
      name: 'HelloWorld',
      components: {
        Head,
        DataTableNetwork,
        DataTableConnector
      },
     data() {
        return {
          tab: null
        }
     },
    }
  </script>
  