<template>
 <v-navigation-drawer
      v-bind:value="drawer"
      @input="$emit('update:drawer',$event)"
      app
      temporary
      style="z-index: 9999;"
    >
   
  <v-list-item>
    <v-list-item-avatar size="50">
      <v-img  class="mx-auto" contain :src="require('@/assets/nexter_living.webp')"/>
    </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            SMART FEM
          </v-list-item-title>
          <v-list-item-subtitle class="text-wrap">
            SCG Smart Building Solution
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>
      <v-list-item v-if="tenantList.length > 1">
        <v-select class="mt-4" dense outlined label="เลือกลูกค้า" :items="tenantList" item-text="TenantName" return-object v-model="tenant"></v-select>
      </v-list-item>
      <v-divider  v-if="tenantList.length > 1"></v-divider>

      <v-list
        nav
      >
        <!-- <v-list-item-group
          v-model="group"
        > -->

      <template v-for="(item,index) in menus" >
        <v-list-item v-if="!item.subgroup" :to="(item.navigate === '/')? '/' : {name: item.navigate}" :key="index">
            <v-list-item-icon>
              <v-icon>{{ item.icons }}</v-icon> 
            </v-list-item-icon>
            <v-list-item-title>{{ item.service }}</v-list-item-title>
        </v-list-item>
     

        <!-- <v-list-group
          v-if="item.subgroup"
          :value="item.active"
          :prepend-icon="item.icons"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{item.service}}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item v-for="(subitem, subindex) in item.sub" :key="subitem.name + subindex" class="ml-4" :to="{name: subitem.navigate}">
            <v-list-item-icon>
              <v-icon>{{subitem.icons}}</v-icon> 
            </v-list-item-icon>
            <v-list-item-title>{{subitem.name}}</v-list-item-title>
            
          </v-list-item>
        </v-list-group> -->
      </template>
        <!-- </v-list-item-group>  -->
      </v-list>
      <template v-slot:append>
        <div>
        <small class="ml-2">©2024 NexterLiving Build {{version}}</small>
      </div>
      </template>
    </v-navigation-drawer>
</template>
<script>
import { mapState } from 'vuex';
export default {
    props: {
      drawer: {
        type: Boolean
      },
    },
    computed: {
      ...mapState('User',['tenantList']),
      tenant: {
        get: function() {
          return this.$store.state.User.tenant
        },
        set: function(newValue) {
          this.$store.dispatch('User/SET_TENANT', newValue).catch((err)=>{
            console.log(err)
          })
        }
      },
      //filteredMenu () {
       // return this.menus.filter(element => element.name === 'All' || this.$store.state.User.tenant.services.filter(el=> el.ServiceName === element.name).length > 0)
       // return 
      //}
    },
    data() {
        return {
            version: process.env.VUE_APP_VERSION,
            menus: [
            { name: 'All',service: 'Overview', icons: 'mdi-border-all', navigate: '/', subgroup: false, active: false },
            { name: 'Asset',service: 'Asset',icons: 'mdi-toy-brick-outline', navigate: 'asset',subgroup: false },
            { name: 'Alert',service: 'Alert',icons: 'mdi-alert-outline', navigate: 'alert',subgroup: false },
            { name: 'Network',service: 'Network',icons: 'mdi-network-outline', navigate: 'network',subgroup: false },
            { name: 'Users',service: 'Users',icons: 'mdi-account-multiple-check-outline', navigate: 'users',subgroup: false },
            { name: 'Report',service: 'Report',icons: 'mdi-chart-box-outline', navigate: 'report',subgroup: false }
              // { name: 'All',service: 'Overview', icons: 'mdi-border-all', navigate: 'overview', subgroup: false, active: false },
              // { name: 'SmartLevel',service: 'Smart Level', icons: 'mdi-waves-arrow-up', navigate: 'smart_level', subgroup: false , active: false},
              // { name: 'SmartEnergy',service: 'Smart Energy', icons: 'mdi-lightning-bolt-circle', navigate: '', subgroup: true, active: false, sub: [
              //   { name: 'Dashboard',icons: 'mdi-view-dashboard', navigate: 'energy_management' },
              //   { name: 'Energy analysis',icons: 'mdi-chart-line', navigate: 'energy_analysis' }
              // ]},
              // { name: 'SmartAlarm',service: 'Smart Alarm', icons: 'mdi-fire', navigate: '', subgroup: true, active: true, sub: [
              //   { name: 'Monitoring',icons: 'mdi-view-dashboard', navigate: 'fire_alarm' },
              //   { name: 'History',icons: 'mdi-chart-line', navigate: 'fire_alarm_history' },
              //   { name: 'Report',icons: 'mdi-chart-box-outline', navigate: 'fire_alarm_report' }
              // ]},

            ],
            group:null
        }
    },
}

</script>