<template>
  <v-hover v-slot="{ hover }">
    <v-card :elevation="hover ? 6 : 2" class="mt-2">
      <v-dialog width="600px" v-model="valueDialog" v-if="currentItem">
        <AssetValue :asset="currentItem" @onClose="valueDialog = false"></AssetValue>
      </v-dialog>
      <v-dialog width="600px" v-model="editDialog" v-if="currentItem" scrollable>
        <AssetEdit :asset="currentItem" @onClose="editDialogClose"></AssetEdit>
      </v-dialog>
      <v-dialog v-model="alertDialog" v-if="currentItem" scrollable>
        <AlertSetting :asset="currentItem" @onClose="alertDialog = false"></AlertSetting>
      </v-dialog>
      <v-dialog v-model="tokenDialog" v-if="currentItem">
        <TokenSetting :asset="currentItem" @onClose="tokenDialog = false"></TokenSetting>
      </v-dialog>
      <v-dialog v-model="emailDialog" v-if="currentItem">
        <EmailSetting :asset="currentItem" @onClose="emailDialog = false"></EmailSetting>
      </v-dialog>
      <v-dialog v-model="historyDialog" v-if="currentItem" scrollable>
        <DataHistory :asset="currentItem" @onClose="historyDialog = false"></DataHistory>
      </v-dialog>
      <v-dialog v-model="alertHistoryDialog" v-if="currentItem" scrollable>
        <AlertHistory :asset="currentItem" @onClose="alertHistoryDialog = false"></AlertHistory>
      </v-dialog>
      <v-card-text>
        <v-data-table :search="search" :loading="loading" :headers="headers" :items="asset" :items-per-page="-1">
          <template v-slot:top>
            <v-row>
              <v-col cols="12" md="2">
                <v-text-field label="Search" v-model="search" solo dense
                  prepend-inner-icon="mdi-magnify"></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-select v-model="selectedAssetType" solo dense :items="assetTypeItems" label="Asset Type"></v-select>
              </v-col>
              <v-spacer />
              <v-col cols="12" md="auto">
                <v-btn v-if="role === 'Admin'" color="primary" @click="addNewDialogClose()"><v-icon>mdi-plus</v-icon>New
                  Asset</v-btn>
              </v-col>
              <v-col cols="12" md="auto">
                <v-btn outlined color="primary"
                  @click="exportToXLS(`Export${$dayjs().format('DDMMMYYYYHHmmss')}`, { headers: headers, items: asset })"><v-icon>mdi-export</v-icon>Export</v-btn>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.alarm="{ item }">
            <v-tooltip bottom v-if="item.alarm">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon @click="openAlertHistoryDialog(item)"><v-icon
                    class="blink">mdi-alert</v-icon></v-btn>
              </template>
              <span>แจ้งเตือน</span>
            </v-tooltip>

            <v-tooltip bottom v-else>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon @click="openAlertHistoryDialog(item)"><v-icon
                    color="green">mdi-check-bold</v-icon></v-btn>
              </template>
              <span>ไม่พบการแจ้งเตือน</span>
            </v-tooltip>
          </template>

          <template v-slot:item.status="{ item }">
            <span :class="(item.status === 'Online') ? 'green--text font-weight-bold' : 'red--text font-weight-bold'">{{
              item.status }}</span>
          </template>
          <template v-slot:item.heartbeat="{ item }">
            <span>{{ $dayjs(item.heartbeat).format('DD MMM YYYY HH:mm') }}</span>
          </template>
          <template v-slot:item.assetName="{ item }">
            <span :class="(item.maintenance) ? 'grey--text' : ''">{{ item.assetName }}</span> <v-icon
              v-if="item.maintenance" small>mdi-wrench</v-icon>
          </template>
          <template v-slot:item.settings="{ item }">
            <v-btn icon @click="openEditDialog(item)"><v-icon>mdi-pencil</v-icon></v-btn>
          </template>
          <template v-slot:item.email="{ item }">
            <v-btn icon @click="openEmailDialog(item)"><v-icon>mdi-email</v-icon></v-btn>
          </template>
          <template v-slot:item.history="{ item }">
            <v-btn icon @click="openHistoryDialog(item)"><v-icon>mdi-table</v-icon></v-btn>
          </template>
          <template v-slot:item.alertHistory="{ item }">
            <v-btn icon @click="openAlertHistoryDialog(item)"><v-icon>mdi-history</v-icon></v-btn>
          </template>
          <template v-slot:item.value="{ item }">
            <v-btn icon @click="openValueDialog(item)"><v-icon color="primary">mdi-gauge</v-icon></v-btn>
          </template>
          <template v-slot:item.rules="{ item }">
            <v-btn icon @click="openAlertDialog(item)"><v-icon color="red">mdi-alert-plus</v-icon></v-btn>
          </template>
          <template v-slot:item.notify="{ item }">
            <!-- <v-avatar :click="openTokenDialog(item)" tile size="25"><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/41/LINE_logo.svg/640px-LINE_logo.svg.png"/></v-avatar> -->
            <v-btn icon @click="openTokenDialog(item)"><v-icon
                :color="(item.notify) ? 'green' : 'red'">$LineIcon</v-icon></v-btn>
          </template>
        </v-data-table>
      </v-card-text>

    </v-card>
  </v-hover>
</template>
<script>
import AssetValue from '@/components/asset/asset_value.vue'
import AssetEdit from '@/components/asset/asset_edit.vue'
import AlertHistory from '@/components/asset/asset_alert_history.vue'
import AlertSetting from '@/components/asset/asset_alert_setting.vue'
import TokenSetting from '@/components/asset/asset_token_setting.vue'
import EmailSetting from '@/components/asset/asset_email_setting.vue'
import DataHistory from '@/components/asset/asset_history.vue'
import { xlsxMixins } from '@/mixins/xlsx'
import { mapState } from 'vuex'

export default {
  mixins: [xlsxMixins],
  components: {
    AssetValue,
    AssetEdit,
    AlertSetting,
    TokenSetting,
    DataHistory,
    EmailSetting,
    AlertHistory
  },
  data() {
    return {
      valueDialog: false,
      editDialog: false,
      alertDialog: false,
      tokenDialog: false,
      emailDialog: false,
      historyDialog: false,
      alertHistoryDialog: false,
      selectedAssetType: 'All',
      headers: [],
      loading: false,
      currentItem: null,
      search: '',
      interval: null
    }
  },
  destroyed() {
    this.interval = null
  },
  methods: {
    setHeader() {
      if(this.role === 'Admin') {
          this.headers = [
          { text: 'Alarm', value: 'alarm' },
          { text: 'Asset Name', value: 'assetName' },
          { text: 'Status', value: 'status' },
          { text: 'Device ID', value: 'deviceId' },
          { text: 'Asset Type', value: 'assetType' },
          { text: 'Location', value: 'location' },
          { text: 'Lasted Report', value: 'heartbeat' },
          { text: 'Value', value: 'value' },
          { text: 'History', value: 'history' },
          { text: 'Alert History', value: 'alertHistory' },
          { text: 'Edit', value: 'settings' },
          { text: 'Rules', value: 'rules' },
          { text: 'Notify', value: 'notify' },
          { text: 'Email', value: 'email' }
        ]
      } else {
        this.headers = [
          { text: 'Alarm', value: 'alarm' },
          { text: 'Asset Name', value: 'assetName' },
          { text: 'Status', value: 'status' },
          { text: 'Device ID', value: 'deviceId' },
          { text: 'Asset Type', value: 'assetType' },
          { text: 'Location', value: 'location' },
          { text: 'Lasted Report', value: 'heartbeat' },
          { text: 'Value', value: 'value' },
          { text: 'History', value: 'history' },
          { text: 'Alert History', value: 'alertHistory' }
        ]
      }
     
    },
    openValueDialog(item) {
      this.currentItem = item
      this.valueDialog = true
    },
    openEditDialog(item) {
      this.currentItem = item
      this.editDialog = true
    },
    openAlertDialog(item) {
      this.currentItem = item
      this.alertDialog = true
    },
    openTokenDialog(item) {
      this.currentItem = item
      this.tokenDialog = true
    },
    openEmailDialog(item) {
      this.currentItem = item
      this.emailDialog = true
    },
    openAlertHistoryDialog(item) {
      this.currentItem = item
      this.alertHistoryDialog = true
    },
    openHistoryDialog(item) {
      this.currentItem = item
      this.historyDialog = true
    },
    editDialogClose() {
      this.editDialog = false
      this.refresh()
    },
    addNewDialogClose() {
      this.currentItem = {}
      this.editDialog = true
    },
    autoRefresh() {
      this.interval = setInterval(() => { this.refresh() }, 60000)
    },
    refresh() {
      this.loading = true
      this.$store.dispatch('Asset/GET_ALL_ASSET', this.building).then(() => {
        this.loading = false
      }).catch((err) => {
        this.loading = false
        console.log(err)
      })

    }
  },
  mounted() {
    this.setHeader()
  },
  computed: {
    assetTypeItems() {
      let data = this.$store.state.User.assetType
        data.forEach(element => {
          element.text = element.partitionKey
          element.value = element.rowKey
        });
        const defaultObj = { text: 'All', value: 'All' }
        data.unshift(defaultObj)
            return data
    },
    role: {
      get: function () {
        return this.$store.state.User.role
      }
    },
    building: {
      get: function () {
        return this.$store.state.User.building
      },
      set: function (newValue) {
        this.$store.dispatch('User/SET_BUILDING', newValue)
      }
    },
    asset: {
      get: function () {
        return this.$store.state.Asset.assetAll.filter(el => el.assetType === this.selectedAssetType || this.selectedAssetType === 'All')
      }
    }
  }
}

</script>

<style scss scoped>
@-webkit-keyframes argh-my-eyes {
  0% {
    color: #FFEBEE !important;
  }

  49% {
    color: #FFEBEE !important;
  }

  50% {
    color: #E53935 !important;
  }

  99% {
    color: #E53935 !important;
  }

  100% {
    color: #FFEBEE !important;
  }
}

@-moz-keyframes argh-my-eyes {
  0% {
    color: #FFEBEE !important;
  }

  49% {
    color: #FFEBEE !important;
  }

  50% {
    color: #E53935 !important;
  }

  99% {
    color: #E53935 !important;
  }

  100% {
    color: #FFEBEE !important;
  }
}

@keyframes argh-my-eyes {
  0% {
    color: #FFEBEE;
  }

  49% {
    color: #FFEBEE;
  }

  50% {
    color: #E53935;
  }

  99% {
    color: #E53935;
  }

  100% {
    color: #FFEBEE;
  }
}

.blink {
  -webkit-animation: argh-my-eyes 2s infinite !important;
  -moz-animation: argh-my-eyes 2s infinite !important;
  animation: argh-my-eyes 2s infinite !important;
}
</style>