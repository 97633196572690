<template>
    <v-container fluid>
        <Head>
        <template v-slot:title>
            <v-btn icon :to="{name: 'overview'}"><v-icon>mdi-arrow-left</v-icon></v-btn>
            <v-icon color="teal darken-3">mdi-air-filter</v-icon>Air Quality
        </template>
        <template v-slot:subtitle>
       ระบบติดตามคุณภาพอากาศ
      </template>
        </Head>
        <v-tabs centered v-model="tab" >
            <v-tab>Dashboard</v-tab>
            <!-- <v-tab>Diagram</v-tab>
            <v-tab>Table</v-tab> -->
        </v-tabs>
        <v-tabs-items v-model="tab">
            <v-tab-item>
        <v-card flat>
          <v-card-text>
            <Dashboard />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <!-- <v-tab-item>
        <v-card flat>
          <v-card-text>
            <Diagram />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <Table />
          </v-card-text>
        </v-card>
      </v-tab-item> -->
        </v-tabs-items>
    </v-container>
</template>

<script>
import Head from '@/components/layout/Head.vue'
import Dashboard from '@/components/air_quality/air_quality_dashboard'
import Table from '@/components/air_quality/air_quality_table'
import Diagram from '@/components/air_quality/air_quality_diagram'

export default {
    name: 'AirQualityView',
    components: {
        Head,
        Dashboard,
        Diagram,
        Table
    },
    data() {
        return {
            tab: null,
        }
    }
}
</script>

<style scoped>
/* Your component's CSS styles go here */
</style>