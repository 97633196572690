<template>
    <span>
        <v-dialog v-model="alertHistoryDialog" v-if="asset" scrollable>
            <AlertHistory :asset="asset" @onClose="alertHistoryDialog = false"></AlertHistory>
        </v-dialog>
        <v-tooltip bottom v-if="asset.alarm">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon @click="openAlertHistoryDialog()"><v-icon
              class="blink">mdi-alert</v-icon></v-btn>
        </template>
        <span>แจ้งเตือน</span>
      </v-tooltip>
      <v-tooltip bottom v-else>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon @click="openAlertHistoryDialog()"><v-icon
              color="green">mdi-check-bold</v-icon></v-btn>
        </template>
        <span>ไม่พบการแจ้งเตือน</span>
      </v-tooltip>
    </span>
</template>
<script>    
// import AlertSetting from '@/components/asset/asset_alert_setting.vue'
import AlertHistory from '@/components/asset/asset_alert_history.vue'
export default {
    name: 'AssetAlertIcon',
    components: {
        AlertHistory,
    },
    props: {
        asset: Object
    },
    data() {
        return {
            alertHistoryDialog: false,
        }
    },
    methods: {
        openAlertHistoryDialog() {
            this.alertHistoryDialog = true
        }
    }
}

</script>
<style scss scoped>
@-webkit-keyframes argh-my-eyes {
  0% {
    color: #FFEBEE !important;
  }

  49% {
    color: #FFEBEE !important;
  }

  50% {
    color: #E53935 !important;
  }

  99% {
    color: #E53935 !important;
  }

  100% {
    color: #FFEBEE !important;
  }
}

@-moz-keyframes argh-my-eyes {
  0% {
    color: #FFEBEE !important;
  }

  49% {
    color: #FFEBEE !important;
  }

  50% {
    color: #E53935 !important;
  }

  99% {
    color: #E53935 !important;
  }

  100% {
    color: #FFEBEE !important;
  }
}

@keyframes argh-my-eyes {
  0% {
    color: #FFEBEE;
  }

  49% {
    color: #FFEBEE;
  }

  50% {
    color: #E53935;
  }

  99% {
    color: #E53935;
  }

  100% {
    color: #FFEBEE;
  }
}

.blink {
  -webkit-animation: argh-my-eyes 2s infinite !important;
  -moz-animation: argh-my-eyes 2s infinite !important;
  animation: argh-my-eyes 2s infinite !important;
}
</style>