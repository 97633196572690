<template>
    <v-container fluid>
      <Head>
        <template v-slot:title>
          <v-btn icon :to="{name: 'overview'}"><v-icon>mdi-arrow-left</v-icon></v-btn>
          <v-icon >mdi-account-multiple-check-outline</v-icon>Users
        </template>
        <template v-slot:subtitle>
       บริหารจัดการผู้ใช้งาน
      </template>
        </Head>
        <v-row>
          <v-col cols="12">
            <USERS_DATATABLE/>
          </v-col></v-row>
    </v-container>
  </template>
  
  <script>
  import Head from '@/components/layout/Head.vue'
  import USERS_DATATABLE from '@/components/users/users_table'
    export default {
      name: 'HelloWorld',
      components: {
        Head,
        USERS_DATATABLE
      },
     data() {
        return {
        }
     },
    }
  </script>
  