<template>
    <v-hover v-slot="{ hover }">
        <v-card :elevation="hover ? 6 : 2">
            <v-card-title>Power Quality 24 Hr.</v-card-title>
            <v-list>
                <v-list-group v-for="item in items" :key="item.title" v-model="item.active" :prepend-icon="item.action"
                    no-action>
                    <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-title v-text="item.title"></v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-list-item-action-text>Total. {{item.total}} mins</v-list-item-action-text>
                        </v-list-item-action>
                    </template>

                    <v-list-item v-for="child in item.items" :key="child.title">
                        <v-list-item-avatar tile>
                            <v-img :src="require('@/assets/power_meter.webp')"></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>

                            <v-list-item-title class="red--text" v-text="child.title"></v-list-item-title>
                            <v-list-item-subtitle v-text="child.total + ' mins'"></v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-group>
            </v-list>
        </v-card>
    </v-hover>
</template>
<script>
export default {

    data() {
        return {
            items: [
                {
                    action: 'mdi-flash-triangle',
                    items: [
                        { title: 'อาคารศรีสวรินทิรานุสรณ์ 150 ปี ชั้น 1', avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg' }
                    ],
                    title: 'Over Voltage',
                    total: 0,
                },
                {
                    action: 'mdi-lightning-bolt',
                    active: true,
                    items: [
                        { title: 'PM001 อาคาร Cyclotron ชั้น 1', total: 5 },
                        { title: 'PM020 อาคาร Cyclotron ชั้น 2', total: 5 },
                    ],
                    title: 'Under Voltage',
                    total: 15,
                },
                {
                    action: 'mdi-current-dc',
                    items: [
                        { title: 'อาคารศรีสวรินทิรานุสรณ์ 150 ปี ชั้น 1', total: 0 }
                    ],
                    title: 'Over Current',
                    total: 0,
                },
                {
                    action: 'mdi-scale-unbalanced',
                    items: [
                        { title: 'อาคารศรีสวรินทิรานุสรณ์ 150 ปี ชั้น 1', total: 0}
                    ],
                    title: 'Unbalance',
                    total: 0,
                },
                {
                    action: 'mdi-current-ac',
                    items: [
                        { title: 'อาคารศรีสวรินทิรานุสรณ์ 150 ปี ชั้น 1', total: 0}
                    ],
                    title: 'Power Factor',
                    total: 0,
                },
                {
                    action: 'mdi-waveform',
                    items: [
                        { title: 'อาคารศรีสวรินทิรานุสรณ์ 150 ปี ชั้น 1', total: 0 }
                    ],
                    title: 'Harmonic',
                    total: 0,
                },
                {
                    action: 'mdi-sine-wave',
                    items: [
                        { title: 'อาคารศรีสวรินทิรานุสรณ์ 150 ปี ชั้น 1', total: 0 }
                    ],
                    title: 'Frequency',
                    total: 0,
                },
            ]
        }
    },
}

</script>