import { getWater, getTelemetry } from '@/api/'
import Vue from 'vue'
import * as alasql from 'alasql'


const groupBy = key => array =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key]
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj)
    return objectsByKeyValue
  }, {})

const groupByAssetType = groupBy('assetType')
const groupByStatus = groupBy('status')

const state = () => ({
    floors: [],
    assetStatus: []
})

const mutations = {
    SET_FLOOR(state, {assets, building}){
        state.assetStatus = groupByAssetType(assets)
        state.floors = []
        building.floors.forEach((element)=>{
            let assetFloors = assets.filter(el=> Number(el.floor) ===  Number(element.Floor));
            if (assetFloors.length > 0) {
              element.assets = groupByAssetType(assetFloors)
            } else {
              element.assets = {}
            }
            state.floors.push(element)
        })
      
    }
}

const actions = {
    SET_FLOOR({ commit ,rootState }) {
        commit('SET_FLOOR', {assets: rootState.Asset.assetFireAlarm, building: rootState.User.building})
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
  }
  