<template>
    <v-hover v-slot="{ hover }">
        <v-card :elevation="hover ? 6 : 2" class="fill-height">
            <v-card-text>
            <v-row>
              <v-col cols="auto">
              <v-icon>mdi-chart-timeline-variant-shimmer</v-icon>  Energy distribution
              </v-col>
              <v-spacer/>
                    <v-col cols="auto">
                        <v-btn-toggle
          v-model="toggle_exclusive"
          rounded
          dense
        >
          <v-btn>
            kWh
          </v-btn>
          <v-btn>
            Price
          </v-btn>
        </v-btn-toggle>
                    </v-col>
                    </v-row>
                  
            <div ref="chart" style="width: 100%;height:300px;"></div>
            <v-row class="text-center">
                <v-spacer></v-spacer>
                    <v-col cols="12" class="text-center">
                        <v-autocomplete chips  solo :items="consumption" multiple  item-text="name" v-model="selectedFloor" return-object>
   
                        </v-autocomplete>
                        <v-spacer></v-spacer>
                    </v-col>
                </v-row>
          
        </v-card-text>
        <v-card-actions>

        </v-card-actions>
        </v-card>
    </v-hover>
</template>
<script>
import * as echarts from 'echarts/core';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent
} from 'echarts/components';
import { PieChart } from 'echarts/charts';
import { LabelLayout } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use([
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  PieChart,
  CanvasRenderer,
  LabelLayout
]);

export default {
    watch: {
        toggle_exclusive(value) {
            let data = []
            this.selectedFloor.forEach(element => {
                data.push({name: element.name, value: (!this.toggle_exclusive)? element.unit: (element.price * element.factor)})
            });
            this.myChart.setOption(
                {
                   
                    series: [
                        {
                            name: (!this.toggle_exclusive)? 'Energy distribution (kWh)' : 'Energy distribution (Price)',
                            data: data
                        }
                    ]
                }
            );
        },
        selectedFloor(value) {
            let data = []
            value.forEach(element => {
                data.push({name: element.name, value: (!this.toggle_exclusive)? element.unit: (element.price * element.factor)})
            });
            this.myChart.setOption(
                {
                  
                    series: [
                        {
                            name: (!this.toggle_exclusive)? 'Energy distribution (kWh)' : 'Energy distribution (Price)',
                            data: data
                        }
                    ]
                }
            );
        }
    },
    data() {
        return {
            myChart: null,
            toggle_exclusive: 0,
            selectedFloor: [
                { name: 'อาคารศรีสวรินทิรานุสรณ์ 150 ปี FL. 1', unit: 1000, price: 7000, factor: 0.89 },
                { name: 'อาคารศรีสวรินทิรานุสรณ์ 150 ปี FL. 2', unit: 1440, price: 10080, factor: 0.97},
                { name: 'อาคารศรีสวรินทิรานุสรณ์ 150 ปี FL. 3', unit: 2390, price: 16730, factor: 0.99 },
                { name: 'อาคารศรีสวรินทิรานุสรณ์ 150 ปี FL. 4', unit: 1203, price: 8521, factor: 0.98 },
            ],
            consumption: [
                { header: 'อาคารศรีสวรินทิรานุสรณ์ 150 ปี' },
                { name: 'อาคารศรีสวรินทิรานุสรณ์ 150 ปี FL. 1', unit: 1000, price: 7000, factor: 0.89 },
                { name: 'อาคารศรีสวรินทิรานุสรณ์ 150 ปี FL. 2', unit: 1440, price: 10080, factor: 0.97 },
                { name: 'อาคารศรีสวรินทิรานุสรณ์ 150 ปี FL. 3', unit: 2390, price: 16730, factor: 0.99 },
                { name: 'อาคารศรีสวรินทิรานุสรณ์ 150 ปี FL. 4', unit: 1203, price: 8521, factor: 0.98 },
                { name: 'อาคารศรีสวรินทิรานุสรณ์ 150 ปี FL. 5', unit: 493, price: 3451 , factor: 0.89},
                { name: 'อาคารศรีสวรินทิรานุสรณ์ 150 ปี FL. 6', unit: 1345, price: 9415, factor: 0.93 },
                { name: 'อาคารศรีสวรินทิรานุสรณ์ 150 ปี FL. 7', unit: 1345, price: 9415, factor: 0.94 },
                { name: 'อาคารศรีสวรินทิรานุสรณ์ 150 ปี FL. 8', unit: 1345, price: 9415, factor: 0.95 },
                { name: 'อาคารศรีสวรินทิรานุสรณ์ 150 ปี FL. 9', unit: 1345, price: 9415, factor: 0.96 },
                { name: 'อาคารศรีสวรินทิรานุสรณ์ 150 ปี FL. 10', unit: 1345, price: 9415, factor: 0.97 },
                { name: 'อาคารศรีสวรินทิรานุสรณ์ 150 ปี FL. 11', unit: 1345, price: 9415, factor: 0.99 },
                { name: 'อาคารศรีสวรินทิรานุสรณ์ 150 ปี FL. 12', unit: 1000, price: 7000, factor: 0.89 },
                { name: 'อาคารศรีสวรินทิรานุสรณ์ 150 ปี FL. 13', unit: 1440, price: 10080, factor: 0.97 },
                { name: 'อาคารศรีสวรินทิรานุสรณ์ 150 ปี FL. 14', unit: 2390, price: 16730, factor: 0.99 },
                { name: 'อาคารศรีสวรินทิรานุสรณ์ 150 ปี FL. 15', unit: 1203, price: 8521, factor: 0.98 },
                { name: 'อาคารศรีสวรินทิรานุสรณ์ 150 ปี FL. 16', unit: 493, price: 3451 , factor: 0.89},
                { name: 'อาคารศรีสวรินทิรานุสรณ์ 150 ปี FL. 17', unit: 1345, price: 9415, factor: 0.93 },
                { name: 'อาคารศรีสวรินทิรานุสรณ์ 150 ปี FL. 18', unit: 1345, price: 9415, factor: 0.94 },
                { name: 'อาคารศรีสวรินทิรานุสรณ์ 150 ปี FL. 19', unit: 1345, price: 9415, factor: 0.95 },
                { name: 'อาคารศรีสวรินทิรานุสรณ์ 150 ปี FL. 20', unit: 1345, price: 9415, factor: 0.96 },
                { name: 'อาคารศรีสวรินทิรานุสรณ์ 150 ปี FL. 21', unit: 1345, price: 9415, factor: 0.97 },
                { divider: true },
                { header: 'อาคาร 100 ปี' },
                { name: 'อาคาร 100 ปี FL. 1', unit: 1000, price: 7000, factor: 0.89 },
                { name: 'อาคาร 100 ปี FL. 2', unit: 1440, price: 10080, factor: 0.97 },
                { name: 'อาคาร 100 ปี FL. 3', unit: 2390, price: 16730, factor: 0.99 },
                { name: 'อาคาร 100 ปี FL. 4', unit: 1203, price: 8521, factor: 0.98 },
                { name: 'อาคาร 100 ปี FL. 5', unit: 493, price: 3451 , factor: 0.89},
                { name: 'อาคาร 100 ปี FL. 6', unit: 1345, price: 9415, factor: 0.93 },
                { name: 'อาคาร 100 ปี FL. 7', unit: 1345, price: 9415, factor: 0.94 },
            ],
            option: {
                title: {
                    text: '',
                    subtext: '',
                    left: 'center'
                },
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    show: false,
                    orient: 'vertical',
                    left: 'left'
                },
                series: [
                    {
                        name: 'Power Consumption (Unit)',
                        type: 'pie',
                        radius: '50%',
                        data: [
                        { name: 'อาคารศรีสวรินทิรานุสรณ์ 150 ปี FL. 1', value: 1000, price: 7000, factor: 0.89 },
                        { name: 'อาคารศรีสวรินทิรานุสรณ์ 150 ปี FL. 2', value: 1440, price: 10080, factor: 0.97},
                        { name: 'อาคารศรีสวรินทิรานุสรณ์ 150 ปี FL. 3', value: 2390, price: 16730, factor: 0.99 },
                        { name: 'อาคารศรีสวรินทิรานุสรณ์ 150 ปี FL. 4', value: 1203, price: 8521, factor: 0.98 },
                        ],
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            }
        }
    },
    mounted () {
        this.myChart =  echarts.init(this.$refs.chart);
        this.myChart.setOption(this.option);
    },
}
</script>