<template>
    <v-card flat >
        <v-card-text>
        <v-row >
            <v-col cols="12" md="3" v-for="(item, index) in items" :key="index">
                <v-hover v-slot="{ hover }">
                    <v-card :elevation="hover ? 6 : 2" >
                        <v-card-title>
                            {{ item.chillerName }}
                            <v-spacer></v-spacer>
                            <v-icon color="green" v-if=" (item.asset) ? (JSON.parse(item.asset[0].data)[item.machineElement] > item.onState) ? true : false : false">mdi-circle</v-icon>
                            <v-icon color="red" v-else>mdi-circle</v-icon>
                        </v-card-title>
                        <v-card-text>
                            <v-img :src="item.image" height="200px" contain></v-img>
                        </v-card-text>
                    </v-card>
                </v-hover>
            </v-col>
        </v-row>
    </v-card-text>
    </v-card>
</template>
<script>

export default {
    data() {
        return {
            items: []
        }
    },
    computed: {
    isSuperAdmin: {
      get: function () {
        return this.$store.state.User.isSupperAdmin
      }
    },
    building: {
      get: function () {
        return this.$store.state.User.building
      },
      set: function (newValue) {
        this.$store.dispatch('User/SET_BUILDING', newValue)
      },
    },
    assets() {
      return this.$store.state.Asset.assetAll
    },
    role: {
      get: function () {
        return this.$store.state.User.role
      }
    },
  },
  mounted () {
    this.getChiller();
  },
  methods: {
    getChiller() {
      this.loading = true
      this.$store.dispatch('HVAC/GET_CHILLER', { BuildingId: this.building.BuildingId }).then((data) => {
        data.forEach(distribution => {
          distribution.asset = this.assets.filter((asset) => {
            return asset.assetId === distribution.powerMeter
        })
        })

        this.items = data
        this.loading = false

      }).catch((error) => {
        console.log(error)
        this.loading = false
      })
    },
  
  },
}
</script>