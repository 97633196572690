<template>
 <v-row class="pt-2" >
  <v-spacer/>
  <v-col cols="12" md="3">
    <v-select dense placeholder="เลือกมิเตอร์" solo v-model="selectedMeter" multiple :items="main_meter" item-text="assetName" return-object></v-select>
  </v-col>
    <ENERGY_SUMMARY :meter="selectedMeter"/>
    <v-col cols="12">
    <ENERGY_DISTRIBUTION_BY_TIME :meter="selectedMeter" v-if="selectedMeter.length > 0"/>
      <v-skeleton-loader v-else
      class="mx-auto"
      type="card"
    ></v-skeleton-loader>
     </v-col>
    <ENERGY_COMPARE_BY_TIME :meter="selectedMeter"/>
   <v-col cols="12">
    <ENERGY_COMPARISON :meter="selectedMeter" v-if="selectedMeter.length > 0" />
    <v-skeleton-loader v-else
      class="mx-auto"
      type="card"
    ></v-skeleton-loader>
    </v-col>
     <v-col cols="12">
    <ENERGY_DISTRIBUTION_BY_LOAD :meter="selectedMeter" v-if="selectedMeter.length > 0" />
    <v-skeleton-loader v-else
      class="mx-auto"
      type="card"
    ></v-skeleton-loader>
    </v-col>
    </v-row>
</template>

<script>
import ENERGY_DISTRIBUTION from '@/components/energy_management/energy_distribution'
import ENERGY_DISTRIBUTION_BY_LOAD from '@/components/energy_management/energy_distribution_by_load'
import ENERGY_SUMMARY from '@/components/energy_management/energy_summary'
import ENERGY_COMPARISON from '@/components/energy_management/energy_comparison'
import ENERGY_DISTRIBUTION_BY_TIME from '@/components/energy_management/energy_distribution_by_time'
import ENERGY_COMPARE_BY_TIME from '@/components/energy_management/energy_compare_by_time'

const objectsEqual = (o1, o2) =>
    Object.keys(o1).length === Object.keys(o2).length 
        && Object.keys(o1).every(p => o1[p] === o2[p]);

const arraysEqual = (a1, a2) => a1.length === a2.length && a1.every((o, idx) => objectsEqual(o, a2[idx]));


export default {
    props: {
      update: {
        type: Boolean
      }
    },
    components: {
      ENERGY_DISTRIBUTION,
      ENERGY_DISTRIBUTION_BY_LOAD,
      ENERGY_SUMMARY,
      ENERGY_COMPARISON,
      ENERGY_DISTRIBUTION_BY_TIME,
      ENERGY_COMPARE_BY_TIME
    },
    computed: {
      main_meter() {
        return this.$store.state.Energy.mainMeter
      },
      building: {
        get: function () {
          return this.$store.state.User.building
        },
        set: function (newValue) {
          this.$store.dispatch('User/SET_BUILDING', newValue)
        },
      },
    },
    watch: {
      building(){
        this.selectedMeter = []
      },
      main_meter(old, newVal){
        if(newVal.length > 0 && !arraysEqual(old, newVal)) {
          //this.selectedMeter = newVal
        }
      }
    },
    mounted () {
      if (this.main_meter.length > 0) {
         setTimeout(() => {
           this.selectedMeter = this.main_meter
         }, 500)
      }
    },
    data() {
      return {
       selectedMeter: []
      }
    },
    methods: {
    },
}

</script>