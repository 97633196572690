<template>
                <v-row>
                    <v-col cols="12" v-for="(item, index) in assetAQI" :key="index">
                        <AIR_QUALITY_CARD :asset="item" />
                    </v-col>
                </v-row>
</template>

<script>
import { mapState } from 'vuex';
import AIR_QUALITY_CARD from '@/components/air_quality/air_quality_card.vue';
export default {
    name: 'AirQualityDashboard',
    components: {
        AIR_QUALITY_CARD
    },
    data() {
        return {
            // Your component's data goes here
        };
    },
    methods: {
        // Your component's methods go here
    },
    computed: {
        ...mapState('Asset', ['assetAQI']),
        units() {
            return this.$store.state.User.units
        }
    },
    mounted() {
        // Code to run when the component is mounted goes here
    },
};
</script>

<style scoped>
/* Your component's styles go here */
</style>