import { getMember, getADUser, postMember, deleteMember, postSignalR } from '@/api/'
import Vue from 'vue'
import * as alasql from 'alasql'

const state = () => ({
    member: [],
    userAD: []
})

const mutations = {
    SET_MEMBER(state, data) {
        state.member = data
    },
    SET_USER_AD(state, data) {
        state.userAD = data
    }
}

const actions = {
    GET_MEMBER({ commit, dispatch }, data) {
        return new Promise((resolve, reject) => {
            getMember({ buildingId: data.buildingId }, (message) => {
                if (message.data) {
                    commit('SET_MEMBER', message.data)
                    resolve()
                } else {
                    resolve()
                }
            }, (error) => {
                reject(error)
            })
        })
    },
    DELETE_MEMBER({ commit, rootState }, data) {
        return new Promise((resolve, reject) => {
            deleteMember({ tenantId: rootState.User.tenant.TenantId, buildingId: rootState.User.building.BuildingId, TenantName: rootState.User.tenant.TenantName, BuildingName: rootState.User.building.BuildingName, id: data.partitionKey, userPrincipalName: data.userPrincipalName, Role: data.Role, displayName: data.displayName }, (message) => {
                resolve()
            }, (error) => {
                reject(error)
            })
        })
    },
    TEST_SIGNALR({ commit, rootState }, data) {
        return new Promise((resolve, reject) => {
            postSignalR({ tenantId: rootState.User.tenant.TenantId, buildingId: rootState.User.building.BuildingId }, (message) => {
                resolve()
            }, (error) => {
                reject(error)
            })
        })
    },
    ADD_MEMBER({ commit, rootState }, data) {
        return new Promise((resolve, reject) => {
            postMember({ tenantId: rootState.User.tenant.TenantId, buildingId: rootState.User.building.BuildingId, TenantName: rootState.User.tenant.TenantName, BuildingName: rootState.User.building.BuildingName, id: data.id, userPrincipalName: data.userPrincipalName, Role: data.Role, displayName: data.displayName }, (message) => {
                resolve()
            }, (error) => {
                reject(error)
            })
        })
    },
    GET_USER_AD({ commit, dispatch }, data) {
        return new Promise((resolve, reject) => {
            getADUser((message) => {
                if (message.data) {
                    commit('SET_USER_AD', message.data)
                    resolve()
                } else {
                    resolve()
                }
            }, (error) => {
                reject(error)
            })
        })
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
