<template>
    <v-col cols="12">

        <v-row class="">
            <v-col cols="12" class="text-center">
                <v-icon>mdi-sigma</v-icon>
            <template v-for="(item,index) in meter">
                <v-icon>mdi-gauge</v-icon>{{ item.assetName }} {{ index < meter.length - 1 ? '+' : '' }}
              </template>
          </v-col>
            <v-col cols="12" md="3">
                <v-hover v-slot="{ hover }">
                    <v-card class="" :elevation="hover ? 6 : 2" v-if="!loading">
                        <v-row>
                            <v-col cols="6"
                                :class="(dailyEnergy > preDailyEnergy) ? `text-center text-h5 red--text font-weight-bold` : `text-center text-h5 success--text font-weight-bold`">
                                {{ Math.floor(dailyEnergy).toLocaleString() }} <div> <span class="text-center text-caption">kWh</span>
                                </div>
                                <div>
                                    <span class="text-center text-subtitle-1 font-weight-bold">Today</span>
                                </div>
                            </v-col>
                            <v-divider vertical class="mt-2 mb-4"></v-divider>
                            <v-col cols="6" class="text-center text-h5 font-weight-bold">

                                {{  Math.floor(preDailyEnergy).toLocaleString() }}
                                <div> <span class="text-center text-caption">kWh</span></div>
                                <div>
                                    <span class="text-center text-subtitle-1 font-weight-bold">Yesterday</span>
                                </div>
                            </v-col>
                        </v-row>
                        <!-- <div class="text-center text-subtitle-1 font-weight-bold grey lighten-2">Today | Yesterday</div> -->
                    </v-card>
                    <v-skeleton-loader v-else class="mx-auto" type="card"></v-skeleton-loader>
                </v-hover>
            </v-col>
            <v-col cols="12" md="3">
                <v-hover v-slot="{ hover }">
                    <v-card class="" :elevation="hover ? 6 : 2" v-if="!loading">
                        <v-row>
                            <v-col cols="6"
                                :class="(weeklyEnergy > preWeeklyEnergy) ? `text-center text-h5 red--text font-weight-bold` : `text-center text-h5 success--text font-weight-bold`">
                                {{ Math.floor(weeklyEnergy).toLocaleString()}}
                                <div>
                                    <span class="text-center text-caption">kWh</span>
                                </div>
                                <div>
                                    <span class="text-center text-subtitle-1 font-weight-bold">This Week</span>
                                </div>
                            </v-col>
                            <v-divider vertical class="mt-2 mb-4"></v-divider>
                            <v-col cols="6" class="text-center text-h5 font-weight-bold">
                                {{  Math.floor(preWeeklyEnergy).toLocaleString() }}
                                <div> <span class="text-center text-caption">kWh</span></div>
                                <div>
                                    <span class="text-center text-subtitle-1 font-weight-bold">Last Week</span>
                                </div>
                            </v-col>
                        </v-row>
                        <!-- <div class="text-center text-subtitle-1 font-weight-bold grey lighten-2">Week | Prev Week</div> -->
                    </v-card>
                    <v-skeleton-loader v-else class="mx-auto" type="card"></v-skeleton-loader>
                </v-hover>
            </v-col>
            <v-col cols="12" md="3">
                <v-hover v-slot="{ hover }">
                    <v-card class="" :elevation="hover ? 6 : 2" v-if="!loading">

                        <v-row>
                            <v-col cols="6"
                                :class="(monthlyEnergy > preMonthyEnergy) ? `text-center text-h5 red--text font-weight-bold` : `text-center text-h5 success--text font-weight-bold`">
                                {{  Math.floor(monthlyEnergy).toLocaleString() }}
                                <div>
                                    <span class="text-center text-caption">kWh</span>
                                </div>
                                <div>
                                    <span class="text-center text-subtitle-1 font-weight-bold">This Month</span>
                                </div>
                            </v-col>
                            <v-divider vertical class="mt-2 mb-4"></v-divider>
                            <v-col cols="6" class="text-center text-h5 font-weight-bold">
                                {{  Math.floor(preMonthyEnergy).toLocaleString() }}
                                <div>
                                    <span class="text-center text-caption">kWh</span>
                                </div>
                                <div>
                                    <span class="text-center text-subtitle-1 font-weight-bold">Last Month</span>
                                </div>
                            </v-col>
                        </v-row>
                        <!-- <div class="text-center text-subtitle-1 font-weight-bold grey lighten-2 ">Month | Prev Month
                        </div> -->
                    </v-card>
                    <v-skeleton-loader v-else class="mx-auto" type="card"></v-skeleton-loader>
                </v-hover>
            </v-col>
            <v-col cols="12" md="3">
                <v-hover v-slot="{ hover }">
                    <v-card :elevation="hover ? 6 : 2" v-if="!loading">
                        <v-row>
                            <v-col cols="6"
                                :class="(yearlyEnergy > preYearlyEnergy) ? `text-center text-h5 red--text font-weight-bold` : `text-center text-h5 success--text font-weight-bold`">
                                {{  Math.floor(yearlyEnergy).toLocaleString() }}
                                <div>
                                    <span class="text-center text-caption">kWh</span>
                                </div>
                                <div>
                                    <span class="text-center text-subtitle-1 font-weight-bold">This Year</span>
                                </div>
                            </v-col>
                            <v-divider vertical class="mt-2 mb-4"></v-divider>
                            <v-col cols="6" class="text-center text-h5 font-weight-bold">
                                {{  Math.floor(preYearlyEnergy).toLocaleString() }}
                                <div>
                                    <span class="text-center text-caption">kWh</span>
                                </div>
                                <div>
                                    <span class="text-center text-subtitle-1 font-weight-bold">Last Year</span>
                                </div>
                            </v-col>
                        </v-row>

                    </v-card>
                    <v-skeleton-loader v-else class="mx-auto" type="card"></v-skeleton-loader>
                </v-hover>
            </v-col>
        </v-row>
    </v-col>
</template>

<script>
export default {
    props: {
        meter: {
            type: Array
        },
    },
    data() {
        return {
            loading: true,
            dailyEnergy: 0,
            preDailyEnergy: 0,
            weeklyEnergy: 0,
            preWeeklyEnergy: 0,
            monthlyEnergy: 0,
            preMonthyEnergy: 0,
            yearlyEnergy: 0,
            preYearlyEnergy: 0
        }
    },
    computed: {
        building: {
            get: function () {
                return this.$store.state.User.building
            },
            set: function (newValue) {
                this.$store.dispatch('User/SET_BUILDING', newValue)
            },
        },
    },
    watch: {
        meter(value) {
            if (value && value.length > 0) {
                this.getEnergy()
            } else {
                this.loading = true
            }
        },
    },
    mounted() {
        this.getEnergy();
    },
    methods: {
        getEnergy() {
            if (this.meter.length > 0) {
                const startDaily = this.$dayjs().local().subtract(1, 'day').unix() * 1000
                const endDaily = this.$dayjs().local().endOf('day').unix() * 1000

                const startWeekly = this.$dayjs().local().subtract(1, 'week').unix() * 1000
                const endWeekly = this.$dayjs().local().endOf('week').unix() * 1000

                const startMonthly = this.$dayjs().local().subtract(1, 'month').unix() * 1000
                const endMonthly = this.$dayjs().local().endOf('month').unix() * 1000

                const startYearly = this.$dayjs().local().subtract(1, 'year').unix() * 1000
                const endYearly = this.$dayjs().local().endOf('year').unix() * 1000


                const daily = new Promise((resolve, reject) => {
                    this.$store.dispatch('Energy/GET_ENERGY', { BuildingId: this.building.BuildingId, type: 'daily', deviceId: this.meter.map(a=> a.deviceId), start: startDaily, end: endDaily, group: true }).then((dailyEnergy) => {
                        dailyEnergy.forEach(element => {
                            if (element.date === this.$dayjs().format('YY-MM-DD')) {
                                this.dailyEnergy = Number(element.Unit)
                            } else if (element.date === this.$dayjs().subtract(1, 'day').format('YY-MM-DD')) {
                                this.preDailyEnergy = Number(element.Unit)
                            }
                        });
                        resolve()
                    }).catch(() => { reject() })
                });
                const weekly = new Promise((resolve, reject) => {
                    this.$store.dispatch('Energy/GET_ENERGY', { BuildingId: this.building.BuildingId, type: 'weekly', deviceId: this.meter.map(a=> a.deviceId), start: startWeekly, end: endWeekly, group: true }).then((weeklyEnergy) => {
                        weeklyEnergy.forEach(element => {
                            if (Number(element.week) === this.$dayjs().week()) {
                                this.weeklyEnergy = Number(element.Unit)
                            } else if (Number(element.week) === this.$dayjs().subtract(1, 'week').week()) {
                                this.preWeeklyEnergy = Number(element.Unit)
                            }
                        })
                        resolve()
                    }).catch(() => { reject() })
                });
                const monthly = new Promise((resolve, reject) => {
                    this.$store.dispatch('Energy/GET_ENERGY', { BuildingId: this.building.BuildingId, type: 'monthly', deviceId: this.meter.map(a=> a.deviceId), start: startMonthly, end: endMonthly, group: true }).then((monthlyEnergy) => {
                        monthlyEnergy.forEach(element => {
                            if (Number(element.month) === this.$dayjs().month() + 1) {
                                this.monthlyEnergy = Number(element.Unit)
                            } else if (Number(element.month) === this.$dayjs().subtract(1, 'month').month() + 1) {
                                this.preMonthyEnergy = Number(element.Unit)
                            }
                        })
                        resolve()
                    }).catch(() => { reject() })
                });
                const yearly = new Promise((resolve, reject) => {
                    this.$store.dispatch('Energy/GET_ENERGY', { BuildingId: this.building.BuildingId, type: 'yearly', deviceId: this.meter.map(a=> a.deviceId), start: startYearly, end: endYearly, group: true }).then((yearlyEnergy) => {
                        yearlyEnergy.forEach(element => {
                            if (Number(element.year) === this.$dayjs().year()) {
                                this.yearlyEnergy = Number(element.Unit) // Math.floor(element.Unit).toLocaleString()
                            } else if (Number(element.year) === this.$dayjs().subtract(1, 'year').year()) {
                                this.preYearlyEnergy = Number(element.Unit)
                            }
                        })
                        resolve()
                    }).catch(() => { reject() })
                });

                this.loading = true
                Promise.all([daily, weekly, monthly, yearly]).then((values) => {
                    this.loading = false
                }).catch(() => {
                    this.loading = false
                })
            }

        }
    },
}
</script>