<template>
  <l-map ref="map" :center="center" :crs="crs"
    :style="`height: ${(fullscreen) ? heightFullscreen : height}px; width: 100%`" @ready="configMap()"
    >
    <v-snackbar v-model="errorSnack" :timeout="timeout">
      {{ errorMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <l-control-fullscreen position="topleft"
      :options="{ title: { 'false': 'Go big!', 'true': 'Be regular' } }"
  />
    <l-control-layers position="topright"></l-control-layers>
    <l-image-overlay v-if="floor.Image" :url="floor.Image" :bounds="bounds" />
    <l-control position="topright" v-if="!fullscreen">
      <v-btn icon @click="$emit('onFullscreen')"><v-icon>mdi-fullscreen</v-icon></v-btn>
    </l-control>

    <l-layer-group v-if="floor.assets" v-for="(item, index) in Object.keys(floor.assets)" :visible="true"
      layerType="overlay" :name="item" :key="index">
      <l-marker :ref="item.assetName" v-for="(item, index) in floor.assets[item]"
        :lat-lng="[Number(item.position.split(':')[0]), Number(item.position.split(':')[1])]" :key="index" :options="{
    draggable: (isSuperAdmin) ? true : false,
  }" @dragend="draggableEvent($event, item)">
        <l-icon :icon-size="[Number(item.IconSize.split(':')[0]), Number(item.IconSize.split(':')[1])]"
          :icon-url="item.icon">
        </l-icon>
        <l-tooltip :options="{ permanent: true, direction: 'bottom', offset: [-15, 50] }">
          <template v-if="item.alarm">
            <v-icon small  class="blink">mdi-circle</v-icon>
          </template>
          <template v-else>
            <v-icon small color="green">mdi-circle</v-icon>
          </template>
          {{ item.assetName }}</l-tooltip>
        <l-popup :options="{ autoClose: true, closeOnClick: true }">
          <v-simple-table dense>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td>Alarm</td>
                  <td>
                    <template v-if="item.alarm">
                      <v-icon class="blink">mdi-circle</v-icon> Alarm
                    </template>
                    <template v-else>
                      <v-icon color="green">mdi-circle</v-icon> Normal
                    </template>
                  </td>
                </tr>
                <tr>
                  <td>Notify</td>
                  <td>
                    <v-switch :disabled="role !== 'Admin'"  inset v-model="item.notify" :label="`${(item.notify) ? 'เปิด' : 'ปิด'}`"></v-switch>
                  </td>
                </tr>

                <tr>
                  <td>Asset Name</td>
                  <td>{{ item.assetName }}</td>
                </tr>
                <tr>
                  <td>Asset Type</td>
                  <td>{{ item.assetType }}</td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td>
                    <template v-if="item.status === 'Online'">
                      <v-icon color="green">mdi-wifi</v-icon>{{ item.status }}
                    </template>
                    <template v-else>
                      <v-icon color="red">mdi-wifi-off</v-icon>{{ item.status }}
                    </template>

                  </td>
                </tr>
                <tr>
                  <td>Location</td>
                  <td>{{ item.location }}</td>
                  
                </tr>
                <tr>
                  <td>Heartbeat</td>
                  <td>{{ $dayjs(item.heartbeat).format('DD/MM/YYYY HH:mm') }}</td>
                </tr>
              </tbody>

              <v-col cols="auto">
                <v-btn v-if="role == 'Admin'" small color="primary" :loading="posting" @click="save(item)">Save</v-btn>
              </v-col>

            </template>
          </v-simple-table>
        </l-popup>
      </l-marker>
    </l-layer-group>
  </l-map>
</template>

<script>
import { CRS, icon } from "leaflet";
import { LMap, LImageOverlay, LMarker, LPopup, LPolyline, LTileLayer, LControl, LIcon, LTooltip, LControlLayers, LLayerGroup } from "vue2-leaflet";
import 'leaflet/dist/leaflet.css';
// import { mapState } from 'vuex'
import { EventBus } from '@/plugins/event-bus'
import LControlFullscreen from 'vue2-leaflet-fullscreen';

export default {
  components: {
    LMap,
    LImageOverlay,
    LMarker,
    LPopup,
    LPolyline,
    LTileLayer,
    LControl,
    LIcon,
    LTooltip,
    LControlLayers,
    LLayerGroup,
    LControlFullscreen
  },
  props: {
    floor: {
      type: Object
    },
    fullscreen: {
      type: Boolean
    }
  },
  data() {
    return {
      bounds: [[0, 0], [600, 1200]],
      crs: CRS.Simple,
      center: [300, 600],
      iconSize: 60,
      lat: 0,
      lng: 0,
      posting: false,
      errorSnack: false,
      errorMessage: '',
      timeout: 2000,
    };
  },
  methods: {
    showAlert() {
      alert("Click!");
    },
    openPopup() {
      if (this.$refs.FL2COSMOKE002) {
        this.$refs.FL2COSMOKE002[0].mapObject.openPopup()
      }
    },
    draggableEvent(event, item) {
      var latlng = event.target.getLatLng();
      item.position = `${latlng.lat}:${latlng.lng}`
    },
    save(item) {
      this.posting = true
      const data = {
        serviceId: item.serviceId,
        IconSize: item.IconSize,
        MapImage: item.MapImage,
        assetName: item.assetName,
        assetType: item.assetType,
        connection: item.connection,
        building: item.building,
        floor: item.floor,
        icon: item.icon,
        interface: item.interface,
        location: item.location,
        logs: item.logs,
        pattern: item.pattern,
        position: item.position,
        showData: item.showData,
        deviceId: item.deviceId,
        child: item.child,
        assetId: item.assetId,
        debug: item.debug,
        maintenance: item.maintenance,
        notify: item.notify,
        repeateNotify: item.repeateNotify,
        debug: item.debug,
        notify: item.notify,
        repeateNotify: item.repeateNotify,
        maintenance: item.maintenance
      }

      this.$store.dispatch('Asset/UPDATE_ASSET', data).then((data) => {
        this.posting = false
        this.errorMessage = 'Update Success'
        this.errorSnack = true
        this.refreshMap()
      }).catch((error) => {
        this.posting = false
        this.errorMessage = error
        this.errorSnack = true
      })
    },
    refreshMap() {
      setTimeout(() => {
        this.$refs.map.mapObject.invalidateSize();
        this.center = [300, 600]
       }, 5);
    },   
    configMap() {
       setTimeout(() => {
        this.$refs.map.mapObject.invalidateSize();
       }, 0);
    }
  },
  mounted () {
    EventBus.$on('asset', this.refreshMap)
  },
  destroyed () {
    EventBus.$off('asset', this.refreshMap)
  },
  computed: {
     role: {
            get: function () {
                return this.$store.state.User.role
            }
        },
    dynamicSize() {
      return [60, 60];
    },
    height() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 220
        case 'sm': return 350
        case 'md': return 450
        case 'lg': return 600
        case 'xl': return 700
      }
    },
    heightFullscreen() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 370
        case 'sm': return 500
        case 'md': return 600
        case 'lg': return 750
        case 'xl': return 850
      }
    },
    isSuperAdmin: {
      get: function () {
        return this.$store.state.User.isSupperAdmin
      }
    }
  }
}
</script>

<style scss scoped>
 @-webkit-keyframes argh-my-eyes {
    0%   { color: #FFEBEE !important; }
    49% { color: #FFEBEE !important; }
    50% { color: #E53935 !important; }
    99% { color: #E53935 !important; }
    100% { color: #FFEBEE !important; }
  }
  @-moz-keyframes argh-my-eyes {
    0%   { color: #FFEBEE !important; }
    49% { color: #FFEBEE !important; }
    50% { color: #E53935 !important; }
    99% { color: #E53935 !important; }
    100% { color: #FFEBEE !important; }
  }
  @keyframes argh-my-eyes {
    0%   { color: #FFEBEE; }
    49% { color: #FFEBEE; }
    50% { color: #E53935; }
    99% { color: #E53935; }
    100% { color: #FFEBEE; }
  }

  .blink {
  -webkit-animation: argh-my-eyes 2s infinite !important;
  -moz-animation:    argh-my-eyes 2s infinite !important;
  animation:         argh-my-eyes 2s infinite !important;
}


</style>