<template>
    <v-card>
        <v-card-title>
            ส่งสัญญาณทดสอบแจ้งเตือน
            <v-spacer></v-spacer>
            <v-btn icon @click="$emit('onClose')">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text>
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <v-btn block @click="testAlarm" color="primary">Test Alarm</v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    name: 'TestAlarmCard',
    methods: {
        testAlarm() {
            this.$store.dispatch('Member/TEST_SIGNALR').then(() => {
                this.$emit('onClose')
            })
        }
    }
}


</script>