<template>
    <v-row>
        <v-col cols="12">
            <v-hover v-slot="{ hover }">
                <v-card :elevation="hover ? 6 : 2">
                    <v-card-title class="font-weight-bold">
                        <v-spacer/>
                        Fire Alarm Analytic
                        <v-spacer/>
                    </v-card-title>
                    <v-card-text>
                        <div ref="chart" style="width: 100%;height:600px;"></div>
                    </v-card-text>
                </v-card>
            </v-hover>
        </v-col>
    </v-row>
</template>

<script>
import * as echarts from 'echarts';
import * as alasql from 'alasql'

// prettier-ignore
const hours = [
    '12a', '1a', '2a', '3a', '4a', '5a', '6a',
    '7a', '8a', '9a', '10a', '11a',
    '12p', '1p', '2p', '3p', '4p', '5p',
    '6p', '7p', '8p', '9p', '10p', '11p'
];
// prettier-ignore
const days = [
    'Sunday','Monday','Tuesday','Wednesday','Thursday', 'Friday',  'Saturday'
];

// prettier-ignore
//const data = [[0, 0, 1], [0, 1, 2], [0, 2, 3], [0, 3, 4], [0, 4, 5], [0, 5, 6], [0, 6, 7], [0, 7, 8], [0, 8, 9], [0, 9, 10], [0, 10, 11], [0, 11, 12], [0, 12, 4], [0, 13, 1], [0, 14, 1], [0, 15, 3], [0, 16, 4], [0, 17, 6], [0, 18, 4], [0, 19, 4], [0, 20, 3], [0, 21, 3], [0, 22, 2], [0, 23, 5], [1, 0, 7], [1, 1, 0], [1, 2, 0], [1, 3, 0], [1, 4, 0], [1, 5, 0], [1, 6, 0], [1, 7, 0], [1, 8, 0], [1, 9, 0], [1, 10, 5], [1, 11, 2], [1, 12, 2], [1, 13, 6], [1, 14, 9], [1, 15, 11], [1, 16, 6], [1, 17, 7], [1, 18, 8], [1, 19, 12], [1, 20, 5], [1, 21, 5], [1, 22, 7], [1, 23, 2], [2, 0, 1], [2, 1, 1], [2, 2, 0], [2, 3, 0], [2, 4, 0], [2, 5, 0], [2, 6, 0], [2, 7, 0], [2, 8, 0], [2, 9, 0], [2, 10, 3], [2, 11, 2], [2, 12, 1], [2, 13, 9], [2, 14, 8], [2, 15, 10], [2, 16, 6], [2, 17, 5], [2, 18, 5], [2, 19, 5], [2, 20, 7], [2, 21, 4], [2, 22, 2], [2, 23, 4], [3, 0, 7], [3, 1, 3], [3, 2, 0], [3, 3, 0], [3, 4, 0], [3, 5, 0], [3, 6, 0], [3, 7, 0], [3, 8, 1], [3, 9, 0], [3, 10, 5], [3, 11, 4], [3, 12, 7], [3, 13, 14], [3, 14, 13], [3, 15, 12], [3, 16, 9], [3, 17, 5], [3, 18, 5], [3, 19, 10], [3, 20, 6], [3, 21, 4], [3, 22, 4], [3, 23, 1], [4, 0, 1], [4, 1, 3], [4, 2, 0], [4, 3, 0], [4, 4, 0], [4, 5, 1], [4, 6, 0], [4, 7, 0], [4, 8, 0], [4, 9, 2], [4, 10, 4], [4, 11, 4], [4, 12, 2], [4, 13, 4], [4, 14, 4], [4, 15, 14], [4, 16, 12], [4, 17, 1], [4, 18, 8], [4, 19, 5], [4, 20, 3], [4, 21, 7], [4, 22, 3], [4, 23, 0], [5, 0, 2], [5, 1, 1], [5, 2, 0], [5, 3, 3], [5, 4, 0], [5, 5, 0], [5, 6, 0], [5, 7, 0], [5, 8, 2], [5, 9, 0], [5, 10, 4], [5, 11, 1], [5, 12, 5], [5, 13, 10], [5, 14, 5], [5, 15, 7], [5, 16, 11], [5, 17, 6], [5, 18, 0], [5, 19, 5], [5, 20, 3], [5, 21, 4], [5, 22, 2], [5, 23, 0], [6, 0, 1], [6, 1, 0], [6, 2, 0], [6, 3, 0], [6, 4, 0], [6, 5, 0], [6, 6, 0], [6, 7, 0], [6, 8, 0], [6, 9, 0], [6, 10, 1], [6, 11, 0], [6, 12, 2], [6, 13, 1], [6, 14, 3], [6, 15, 4], [6, 16, 0], [6, 17, 0], [6, 18, 0], [6, 19, 0], [6, 20, 1], [6, 21, 2], [6, 22, 2], [6, 23, 6]];
const data  =[
    [
        0,
        14,
        11
    ],
    [
        0,
        15,
        25
    ],
    [
        0,
        16,
        23
    ],
    [
        0,
        11,
        5
    ],
    [
        1,
        9,
        6
    ],
    [
        6,
        17,
        5
    ],
    [
        5,
        15,
        4
    ],
    [
        0,
        9,
        5
    ],
    [
        6,
        12,
        4
    ],
    [
        1,
        15,
        3
    ],
    [
        0,
        4,
        2
    ],
    [
        6,
        14,
        12
    ],
    [
        0,
        17,
        7
    ],
    [
        6,
        10,
        5
    ],
    [
        3,
        9,
        2
    ],
    [
        2,
        19,
        3
    ],
    [
        4,
        19,
        1
    ],
    [
        5,
        19,
        2
    ],
    [
        4,
        11,
        5
    ],
    [
        6,
        8,
        4
    ],
    [
        1,
        14,
        4
    ],
    [
        1,
        19,
        1
    ],
    [
        0,
        12,
        8
    ],
    [
        5,
        16,
        8
    ],
    [
        2,
        14,
        6
    ],
    [
        5,
        9,
        5
    ],
    [
        4,
        23,
        3
    ],
    [
        1,
        17,
        2
    ],
    [
        6,
        15,
        4
    ],
    [
        0,
        7,
        1
    ],
    [
        4,
        18,
        1
    ],
    [
        0,
        10,
        2
    ],
    [
        0,
        13,
        3
    ],
    [
        4,
        16,
        1
    ],
    [
        4,
        6,
        2
    ],
    [
        1,
        7,
        3
    ],
    [
        1,
        22,
        2
    ],
    [
        3,
        14,
        1
    ],
    [
        2,
        0,
        2
    ],
    [
        2,
        15,
        4
    ],
    [
        6,
        11,
        2
    ],
    [
        3,
        10,
        1
    ],
    [
        1,
        13,
        3
    ],
    [
        1,
        10,
        1
    ],
    [
        1,
        16,
        1
    ],
    [
        3,
        19,
        1
    ],
    [
        1,
        11,
        1
    ],
    [
        5,
        13,
        2
    ],
    [
        6,
        9,
        1
    ],
    [
        5,
        20,
        1
    ],
    [
        6,
        16,
        1
    ],
    [
        5,
        8,
        1
    ],
    [
        5,
        10,
        1
    ]
]

//const title = [];
//const singleAxis = [];
//const series = [];



//console.log(series)



export default {
    data() {
        return {
            loading: false,
            items: [],
            title: [],
            singleAxis: [],
            series: [],
            serviceId: '20340ea6-88dd-11ee-b9d1-0242ac120002',
            myChart: null,
            option: {
                toolbox: {
                    show: true,
                    feature: {
                        dataView: { readOnly: true },
                        restore: {},
                        saveAsImage: {}
                    }
                },
                tooltip: {
                    position: 'top'
                }
            }
        }
    },
    methods: {
        getReport() {
            this.loading = true
            this.$store.dispatch('Report/GET_REPORT', { serviceId: this.serviceId }).then((reportItems) => {
               
                //alasql()
                //[0, 0, 1] day, h , event

                reportItems.forEach((el)=>{
                    el.weekday = this.$dayjs(el.createDatetime).local().day()
                    el.H = this.$dayjs(el.createDatetime).local().format('H')
                    
                })
                let data = alasql('SELECT Number(H) AS hour, weekday, COUNT(*) AS event FROM ? GROUP BY H,weekday', [reportItems])
                let data_set = []
                data.forEach(el=> {
                    data_set.push([el.weekday,el.hour,el.event])
                })
                //console.log(data_set)
                this.title = []
                this.singleAxis = []
                this.series = []
                days.forEach((day, idx) => {
                    this.title.push({
                        textBaseline: 'middle',
                        top: ((idx + 0.5) * 100) / 7 + '%',
                        text: day
                    });
                    this.singleAxis.push({
                        left: 150,
                        type: 'category',
                        boundaryGap: false,
                        data: hours,
                        top: (idx * 100) / 7 + 5 + '%',
                        height: 100 / 7 - 10 + '%',
                        axisLabel: {
                            interval: 2
                        }
                    });
                        this.series.push({
                            singleAxisIndex: idx,
                            coordinateSystem: 'singleAxis',
                            type: 'scatter',
                            data: [],
                            symbolSize: function (dataItem) {
                                return dataItem[1] * 4;
                            }
                        });
                    });

                    console.log('data_set')
                    data_set.forEach( (dataItem)=> {
                        this.series[dataItem[0]].data.push([dataItem[1], dataItem[2]]);
                    });


                    console.log({
                toolbox: {
                    show: true,
                    feature: {
                        dataView: { readOnly: true },
                        restore: {},
                        saveAsImage: {}
                    }
                },
                tooltip: {
                    position: 'top'
                },
                title: this.title,
                singleAxis: this.singleAxis,
                series: this.series
                })
                this.myChart.setOption({
                toolbox: {
                    show: true,
                    feature: {
                        dataView: { readOnly: true },
                        restore: {},
                        saveAsImage: {}
                    }
                },
                tooltip: {
                    position: 'top'
                },
                title: this.title,
                singleAxis: this.singleAxis,
                series: this.series
                });

                //this.items = reportItems
                this.loading = false

            }).catch((error) => {
                console.log(error)
                this.loading = false
            })

        }
    },
    mounted() {
        this.myChart = echarts.init(this.$refs.chart);
        //this.myChart.setOption(this.option);
        this.getReport()
        window.addEventListener('resize', () => {
            this.myChart.resize()
        });

    },
}
</script>

<style lang="scss" scoped></style>