<template>
     <v-app-bar
      app
      color="primary"
      dense
      hide-on-scroll
      clipped-right
    >
    <v-app-bar-nav-icon color="white" @click.stop="$emit('drawerChange')"></v-app-bar-nav-icon>
    <v-toolbar-title class="white--text font-weight-bold">
    <v-img contain width="40px" :src="require('@/assets/nexter_living.webp')"></v-img>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn color="white" icon   @click.stop="$emit('alarmDrawerChange')">       
    <v-badge
          offset-x="10"
          offset-y="10"
          :content="alertLatest.length">
    <v-icon >mdi-bell-outline</v-icon> 
    </v-badge>
  </v-btn>
    <v-divider
      class="pr-3 mr-3 white--text"
      vertical/>
      <div class="text-body white--text" v-if="!$vuetify.breakpoint.mobile">
    {{user.name}}
    <br><span class="text-caption">{{tenant.TenantName}}</span>
    </div>
    <UserMenu @userProfile="profileDialog=true" />
    <v-snackbar v-model="network" :timeout="-1" bottom app>
                ขาดการเชื่อมต่ออินเตอร์เน็ต
                <template v-slot:action="{ attrs }">
                    <v-btn color="blue" text v-bind="attrs" @click="network = false">
                        Close
                    </v-btn>
                </template>
    </v-snackbar>

    <v-dialog v-model="modal" width="300px" style="z-index: 99999;">
      <v-card>
        <v-card-title >
          {{ title }}
        </v-card-title>
        <v-card-text class="red--text">
          {{ message }}
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" block @click="postAcknowledge" @loading="posting">Acknowledge</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </v-app-bar>
</template>
<script>
import UserMenu from '@/components/layout/UserMenu.vue'
import { mapState } from 'vuex'
import { initSignalR } from '@/plugins/signalr'
import { EventBus } from '@/plugins/event-bus'
import { ringtone } from '@/plugins/ringtone'

export default {
    components: {
      UserMenu,
    },
    model: {
      prop: 'drawer',
      event: 'change'
    },
    props: {
      drawer: {
        type: Boolean
      },
    },
    computed: {
      ...mapState('User', ['user', 'tenant', 'roles']),
      ...mapState('Alert', ['alertLatest']),
      building: {
          get: function () {
            return this.$store.state.User.building
          },
          set: function (newValue) {
            this.$store.dispatch('User/SET_BUILDING', newValue)
          }
      },
    },
    mounted () {
      initSignalR();
      window.addEventListener("offline",this.isOffLine);
      window.addEventListener("online",this.isOnLine);
      ringtone.muted = true; 
      EventBus.$on('alarm', this.playAlarm);
      this.autoRefresh();
    },
    async destroyed () {
      EventBus.$off('alarm', this.playAlarm)
    },
    methods: {
      isOnLine(){
        this.network = false
      },
      isOffLine(){
        this.network = true
      },
      playAlarm (device) {
          this.refresh()
          this.modal = true
          this.device = device
          this.title = `แจ้งเตือน ${device.assetName}`
          this.message = `${device.message}` //`${device.message}`
          this.play()
      },
      stopAlarm() {
        ringtone.pause()
        this.modal = false
      },
      postAcknowledge() {
        this.posting = true
        let data = {
          tenantId: this.device.tenantId,
          buildingId: this.device.buildingId,
          message: this.message
        }
        this.$store.dispatch('Acknowledge/POST_ACKNOWLEDGE',data).then(()=>{
          this.posting = false
          ringtone.pause()
        this.modal = false
        }).catch((err)=>{
          this.posting = false
          ringtone.pause()
        this.modal = false
            console.log(err)
        })
      },
      refresh() {
        this.$store.dispatch('Asset/GET_ALL_ASSET', this.building).then(()=>{
        }).catch((err)=>{
            console.log(err)
        })
      },
      buildStr (obj) {
        let message = ''
        Object.keys(obj).forEach(function(key) {
            message += key  + ": " + obj[key] + '\n';
        });
        return message
      },
      autoRefresh() {
          this.interval = setInterval(()=>{this.refresh()}, 30000)
        },
      getAlertLatest() {

            this.$store.dispatch('Alert/GET_ALERT_LATEST').then(() => {
            }).catch(() => {
            })
        },
      play() {
        ringtone.volume = 1
        ringtone.muted = false; 
        ringtone.loop = true
        var resp = ringtone.play();
        if (resp!== undefined) {
            resp.then(_ => {
              // autoplay starts!
            }).catch(error => {
              console.log(error)
              //show error
            });
        }
      }
    },
    destroyed () {
      if (this.interval) {
          clearInterval(this.interval)
        } ;
    },
    data() {
        return {
          message: '',
          modal: false,
          title: '',
          interval: null,
          device: null,
          posting: false,
          network: false,

        }
    },
}

</script>