import { writeFile, utils } from 'xlsx'
// import * as alasql from 'alasql'
export const xlsxMixins = {
  methods: {
    exportToXLS (filename, { headers, items }) {
      const cloneHeaders = [...headers]
      const cloneItems = [...items]
      const exportItems = []
      cloneItems.forEach((element) => {
        const keys = Object.keys(element)
        console.log(keys)
      })
      cloneItems.forEach((element) => {
        const keys = Object.keys(element)
        const item = {}
        keys.forEach((sub) => {
          cloneHeaders.forEach((header) => {
            if (header.value === sub) {
              item[header.text] = element[sub]
            }
          })
        })

        exportItems.push(item)
      })

      const dataWS = utils.json_to_sheet(exportItems, { dateNF: 'dd/mm/yyyy HH:MM:ss' })
      const wb = utils.book_new()
      utils.book_append_sheet(wb, dataWS)
      writeFile(wb, filename + '.xlsx')
    }
  }
}
