<template>
    <v-hover v-slot="{ hover }">
        <v-card :elevation="hover ? 6 : 2" class="mb-4" v-if="!loading">
            <v-card-title>{{title}}</v-card-title>
            <v-list>
                <v-list-group v-for="item in items" :key="item.title" v-model="item.active" :prepend-icon="item.action"
                    no-action>
                    <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-title v-text="item.title"></v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-list-item-action-text>Total. {{item.total}} mins</v-list-item-action-text>
                        </v-list-item-action>
                    </template>

                    <v-list-item v-for="child in item.items" :key="child.title">
                        <v-list-item-avatar tile>
                            <v-img :src="require('@/assets/power_meter.webp')"></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>

                            <v-list-item-title class="red--text" v-text="child.title"></v-list-item-title>
                            <v-list-item-subtitle v-text="child.total_minutes + ' mins'"></v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-group>
            </v-list>
        </v-card>
        <v-skeleton-loader v-else class="mx-auto" type="card"></v-skeleton-loader>
    </v-hover>
</template>
<script>
import * as alasql from 'alasql'

export default {
    props: {
        title: {
            type: String,
            default: 'Power Quality'
        },
        start: {
            type: Number,
            default: 1722186000000
        },
        end: {
            type: Number,
            default: 1725262838000
        }
    },
    computed: {
        building: {
            get: function () {
                return this.$store.state.User.building
            },
            set: function (newValue) {
                this.$store.dispatch('User/SET_BUILDING', newValue)
            },
        },
    },
    data() {
        return {
            loading: false,
            items: [
                {
                    action: 'mdi-flash-triangle',
                    items: [
                    ],
                    title: 'Over Voltage',
                    total: 0,
                },
                {
                    action: 'mdi-lightning-bolt',
                    items: [
                    ],
                    title: 'Under Voltage',
                    total: 0,
                },
                {
                    action: 'mdi-current-dc',
                    items: [
                    ],
                    title: 'Over Current',
                    total: 0,
                },
                {
                    action: 'mdi-scale-unbalanced',
                    items: [
                    ],
                    title: 'Unbalance',
                    total: 0,
                },
                {
                    action: 'mdi-current-ac',
                    items: [
                        { title: 'อาคารศรีสวรินทิรานุสรณ์ 150 ปี ชั้น 1', total: 0}
                    ],
                    title: 'Power Factor',
                    total: 0,
                }
            ]
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        getData() {
            this.loading = true
            this.$store.dispatch('Energy/GET_ELECTRICAL', { BuildingId: this.building.BuildingId, start: this.start, end: this.end }).then((data) => {
               this.loading = false
               let prepareData =alasql(`select assetName as title, assetId, DATE(createAt) as createAt, DATE(resolveAt) as resolveAt, DATEDIFF(minute, DATE(createAt), IF(resolveAt is null, NOW(), DATE(resolveAt))) as total_minutes from ? where voltage_too_hight = true`,[data])
               this.items[0].items = alasql(`select title, SUM(total_minutes) as total_minutes from ? group by title `,[prepareData])
               let sum = alasql(`select SUM(total_minutes) as sum_total from ?`,[this.items[0].items])
               if(sum.length > 0){
                   this.items[0].total = sum[0].sum_total
               }

               prepareData =alasql(`select assetName as title, assetId, DATE(createAt) as createAt, DATE(resolveAt) as resolveAt, DATEDIFF(minute, DATE(createAt), IF(resolveAt is null, NOW(), DATE(resolveAt))) as total_minutes from ? where voltage_too_low = true`,[data])
               this.items[1].items = alasql(`select title, SUM(total_minutes) as total_minutes from ? group by title `,[prepareData])
               sum = alasql(`select SUM(total_minutes) as sum_total from ?`,[this.items[1].items])
                if(sum.length > 0){
                     this.items[1].total = sum[0].sum_total
                }

                prepareData = alasql(`select assetName as title, assetId, DATE(createAt) as createAt, DATE(resolveAt) as resolveAt, DATEDIFF(minute, DATE(createAt), IF(resolveAt is null, NOW(), DATE(resolveAt))) as total_minutes from ? where current_too_hight = true`,[data])
                this.items[2].items = alasql(`select title, SUM(total_minutes) as total_minutes from ? group by title `,[prepareData])
                sum = alasql(`select SUM(total_minutes) as sum_total from ?`,[this.items[2].items])
                 if(sum.length > 0){
                        this.items[2].total = sum[0].sum_total
                 }
                
               prepareData = alasql(`select assetName as title, assetId, DATE(createAt) as createAt, DATE(resolveAt) as resolveAt, DATEDIFF(minute, DATE(createAt), IF(resolveAt is null, NOW(), DATE(resolveAt))) as total_minutes from ? where unbalance = true`,[data])
               this.items[3].items = alasql(`select title, SUM(total_minutes) as total_minutes from ? group by title`,[prepareData])
               sum = alasql(`select SUM(total_minutes) as sum_total from ?`,[this.items[3].items])
                 if(sum.length > 0){
                        this.items[3].total = sum[0].sum_total
                 }

                prepareData = alasql(`select assetName as title, assetId, DATE(createAt) as createAt, DATE(resolveAt) as resolveAt, DATEDIFF(minute, DATE(createAt), IF(resolveAt is null, NOW(), DATE(resolveAt))) as total_minutes from ? where power_factor = true`,[data])
                this.items[4].items = alasql(`select title, SUM(total_minutes) as total_minutes from ? group by title`,[prepareData])
                sum = alasql(`select SUM(total_minutes) as sum_total from ?`,[this.items[4].items])
                 if(sum.length > 0){
                        this.items[4].total = sum[0].sum_total
                 }

              
              
            }).catch(()=>{
                this.loading = false
            })
        }
    },
}

</script>