<template>
    <v-avatar class="mx-auto" size="100">
                            <v-icon size="100" v-if="aqi < 50" color="green">mdi-emoticon-happy-outline</v-icon>
                            <v-icon size="100" v-else-if="aqi < 100"
                              color="yellow">mdi-emoticon-neutral-outline</v-icon>
                            <v-icon size="100" v-else-if="aqi < 200"
                              color="orange">mdi-emoticon-sad-outline</v-icon>
                            <v-icon size="100" v-else color="red">mdi-emoticon-frown-outline</v-icon>
 </v-avatar>

</template>

<script>


export default {
    props: {
        aqi: {
            type: Number
        },
    },
        data() {
            return {

            }}
            
    }
</script>

<style lang="scss" scoped>

</style>