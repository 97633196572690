import { getReportList, getReport } from '@/api/'


const state = () => ({
    reportList: []
})

const mutations = {
    SET_REPORT_LIST(state, data){
        state.reportList = data
    }
}

const actions = {
    GET_REPORT_LIST({commit, rootState}) {
        return new Promise((resolve, reject) => {
            getReportList({buildingId: rootState.User.building.BuildingId },(message)=>{
                if (message.data) {
                    commit('SET_REPORT_LIST', message.data)
                    resolve(message.data)
                } else {
                    resolve()
                }
            }, (error)=>{
                reject(error)
            })
        })
    },
    GET_REPORT({commit, rootState} , data) {
        return new Promise((resolve, reject) => {
            getReport({tenantId: rootState.User.tenant.TenantId, buildingId: rootState.User.building.BuildingId, serviceId: data.serviceId },(message)=>{
                if (message.data) {
                    resolve(message.data)
                } else {
                    resolve()
                }
            }, (error)=>{
                reject(error)
            })
        })
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
  }
  