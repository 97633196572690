<template>
    <v-card flat width="300px">
        <v-snackbar v-model="errorSnack" :timeout="timeout">
            {{ errorMessage }}
            <template v-slot:action="{ attrs }">
                <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
                    Close
                </v-btn>
            </template>
        </v-snackbar>
        <v-card-title style="background-color: rgb(23,20,20,0.1);">
            <v-avatar>
                <v-tooltip bottom v-if="asset.status === 'Online'">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" size="32" color="primary">mdi-engine</v-icon>
                    </template>
                    <span>ออนไลน์</span>
                </v-tooltip>
                <v-tooltip bottom v-else>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" size="32" color="red">mdi-engine</v-icon>
                    </template>
                    <span>ออฟไลน์</span>
                </v-tooltip>
            </v-avatar>
            {{ asset.assetName }}<v-spacer></v-spacer>
            <ASSET_ALERT_ICON />
            <v-spacer />
            <v-avatar size="50" tile>
                <v-img contain :src="asset.MapImage || require('@/assets/undraw_photos_re_pvh3.svg')"></v-img>
            </v-avatar>
        </v-card-title>
        <v-card-text>
            <v-row dense class="mt-2">
                <v-col cols="12">
                    {{ asset.location }}
                </v-col>
                <v-col cols="12">
                    <div class="text-caption">ข้อมูลเมื่อ {{ $dayjs(asset.heartbeat).format('LLL') }}</div>
                </v-col>
                <v-col cols="12" class="font-weight-bold">
                    {{ asset.machineState }}
                </v-col>
            </v-row>
            <v-simple-table dense>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="center-text">ชื่อ</th>
                            <th class="center-text">สถานะ</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(subItem, index) in Object.keys(JSON.parse(asset.data))" :key="index">
                            <td class="center-text" width="100px">
                                {{ subItem }}
                            </td>
                            <td class="center-text">
                                {{ JSON.parse(asset.data)[subItem] }}
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card-text>
        <v-card-actions>
            <ASSET_MENU :asset="asset">
                <v-btn v-if="role == 'Admin'" small color="primary" :loading="posting" @click="save()">Save</v-btn>
            </ASSET_MENU>
        </v-card-actions>
    </v-card>

</template>

<script>
import ASSET_MENU from '@/components/asset/asset_menu.vue'
import ASSET_ALERT_ICON from '@/components/asset/asset_alert_icon.vue'

export default {
    props: {
        asset: {
            type: Object,
            default: {}
        },
    },
    components: {
        ASSET_MENU,
        ASSET_ALERT_ICON
    },
    data() {
        return {
            posting: false,
            assetType: [],
            errorSnack: false,
            errorMessage: '',
            timeout: 2000,
        }
    },
    mounted() {
        // this.url = this.device.image
    },
    computed: {
        role: {
            get: function () {
                return this.$store.state.User.role
            }
        },
        units() {
            return this.$store.state.User.units
        }
    },
    methods: {
        save() {
            this.posting = true
            const data = {
                serviceId: this.asset.serviceId,
                IconSize: this.asset.IconSize,
                MapImage: this.asset.MapImage,
                assetName: this.asset.assetName,
                assetType: this.asset.assetType,
                connection: this.asset.connection,
                building: this.asset.building,
                floor: this.asset.floor,
                icon: this.asset.icon,
                interface: this.asset.interface,
                location: this.asset.location,
                logs: this.asset.logs,
                pattern: this.asset.pattern,
                position: this.asset.position,
                showData: this.asset.showData,
                deviceId: this.asset.deviceId,
                child: this.asset.child,
                assetId: this.asset.assetId,
                debug: this.asset.debug,
                maintenance: this.asset.maintenance,
                notify: this.asset.notify,
                repeateNotify: this.asset.repeateNotify,
                debug: this.asset.debug,
                notify: this.asset.notify,
                repeateNotify: this.asset.repeateNotify,
                maintenance: this.asset.maintenance
            }

            this.$store.dispatch('Asset/UPDATE_ASSET', data).then((data) => {
                this.posting = false
                this.errorMessage = 'Update Success'
                this.errorSnack = true
            }).catch((error) => {
                this.posting = false
                this.errorMessage = error
                this.errorSnack = true
            })
        },
    },
}
</script>