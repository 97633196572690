<template>
  <div ref="mixchart" class="mt-5" style="width: 100%;height:400px;">
  </div>
</template>
<script>
import * as echarts from 'echarts/core';
import {
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent
} from 'echarts/components';
import { BarChart, LineChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import * as alasql from 'alasql'

echarts.use([
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  BarChart,
  LineChart,
  CanvasRenderer,
  UniversalTransition
]);

export default {
  props: {
    energyValue: {
      type: Array
    },
    meter: {
      type: Array
    },
    changed: {
      type: Boolean
    }
  },
  data() {
    return {
      myChart: null,
      toggle_exclusive: 0,
      option: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        toolbox: {
          feature: {
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ['line', 'bar'] },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        legend: {
      
        },
        xAxis: [
          {
            type: 'category',
            axisLabel: {
              formatter: (value) => {
                return this.$dayjs(value, 'DD-MMM').format('DD-MMM')
              }
            },
            axisPointer: {
              type: 'shadow'
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: 'kWh',
            min: 0,
            axisLabel: {
              formatter: '{value} kWh'
            }
          },
          {
            type: 'value',
            name: 'Price',
            min: 0,
            axisLabel: {
              formatter: '{value} Baht'
            }
          }
        ],
        series: [
        ],
        dataset: {
          dimensions: ['date'],
        source: [
         
          ]
        }
      }
    }
  },
  watch: {
    meter(value) {
      if (value) {
        this.setData()
      }
    },
  },
  methods: {
    setData() {
        const year = [...new Set(this.energyValue.map(item => item.year))]
        this.energyValue.forEach((element)=>{
          element.day = this.$dayjs(element.date, 'YY-MM-DD').format('DD-MMM')
        })
        let prepareData = alasql(`select day, sum(Unit) as Unit ,year  from ? group by day, year`, [this.energyValue])
        var o = prepareData.reduce( (a,b) => {
            a[b.day] = a[b.day] || [];
            a[b.day].push({[b.year]:b.Unit.toFixed(0)});
            return a;
        }, {})
        var a = Object.keys(o).map((k)=>{
          return {date : k, ...(o[k])?Object.assign.apply({},o[k]):0};  //{ year : k, week : };
        })
        this.option.dataset.series = []
        year.forEach((element)=>{
          this.option.series.push( { type: 'line' })
        })
        this.option.dataset.dimensions = [... this.option.dataset.dimensions,...year ]
        this.option.dataset.source = a
        this.myChart.setOption(this.option);
        this.myChart.resize()
    }
  },
  mounted() {
    this.myChart = echarts.init(this.$refs.mixchart);
    const resizeObserver = new ResizeObserver((entries) => {
      this.myChart.resize();
    });
    resizeObserver.observe(this.$refs.mixchart);
    this.setData()
  },
}
</script>