<template>
    <v-hover v-slot="{ hover }">
        <v-card :elevation="hover ? 6 : 2" class="mt-2">
            <v-card-text>
                <v-data-table :search="search" :loading="loading" :headers="headers" :items="alert"
                    sort-by="createDatetime" :sort-desc="true">
                    <template v-slot:top>
                        <v-row>
                            <v-spacer />
                            <v-col cols="12" md="2">
                                <v-text-field label="Search" v-model="search" solo dense
                                    prepend-inner-icon="mdi-magnify"></v-text-field>
                            </v-col>
                            <v-col cols="auto" class="mt-2">
                                เลือกช่วงเวลา
                            </v-col>
                            <v-col cols="auto">
                                <DateRangePicker v-model="range" />
                            </v-col>
                            <v-col cols="auto">
                                <v-btn color="primary" :disabled="loading" @click="getAlert">View</v-btn>
                            </v-col>
                            <v-col cols="auto">
                                <v-btn color="primary" outlined :disabled="loading"
                                    @click="exportToXLS(`Export${$dayjs().format('DDMMMYYYYHHmmss')}`, { headers: headers, items: alert })"><v-icon>mdi-export</v-icon>Export</v-btn>
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:item.createDatetime="{ item }">
                        {{ $dayjs(item.createDatetime).local().format('DD MMM YY HH:mm') }}
                    </template>
                    <template v-slot:item.resolveTime="{ item }">
                        {{ (item.resolveTime) ? $dayjs(item.resolveTime * 1000).local().format('DD MMM YY HH:mm') : ''
                        }}
                    </template>

                    <template v-slot:item.resolve="{ item }">
                        <v-icon color="green" v-if="item.resolve">mdi-check</v-icon>
                        <v-icon color="red" v-else>mdi-alert</v-icon>
                    </template>
                    <template v-slot:item.data="{ item }">
                        {{ item[`${item.element}`] }}
                    </template>
                    <template v-slot:item.unit="{ item }">
                        {{ units[item.element] }}
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-hover>
</template>
<script>
import { xlsxMixins } from '@/mixins/xlsx'
import DateRangePicker from '@/components/common/DateRangePicker.vue'

export default {
    mixins: [xlsxMixins],
    components: {
        DateRangePicker,
    },
    data() {
        return {
            headers: [

            ],
            loading: false,
            search: '',
            interval: null,
            range: {
                startDate: this.$dayjs().subtract(1, 'month').toDate(),
                endDate: this.$dayjs().toDate(),
            }
        }
    },
    mounted() {
        this.setHeader();
        this.getAlert();
        this.interval = setInterval(() => {
            this.getAlert();
        }, 60000)
    },
    destroyed() {
        this.interval = null
    },
    computed: {
        building: {
            get: function () {
                return this.$store.state.User.building
            },
            set: function (newValue) {
                this.$store.dispatch('User/SET_BUILDING', newValue)
            }
        },
        alert: {
            get: function () {
                return this.$store.state.Alert.alert
            }
        },
        units() {
            return this.$store.state.User.units
        },
        role: {
            get: function () {
                return this.$store.state.User.role
            }
        },
    },
    watch: {
        building() {
            this.getAlert();
        }
    },
    methods: {
        setHeader() {
            if (this.role === 'Admin') {
                this.headers = [
                    { text: 'Start', value: 'createDatetime' },
                    { text: 'End', value: 'resolveTime' },
                    { text: 'Device Name', value: 'assetName' },
                    { text: 'Location', value: 'location' },
                    { text: 'Device ID', value: 'deviceId' },
                    { text: 'Status', value: 'resolve' },
                    { text: 'Title', value: 'title' },
                    { text: 'Message', value: 'message' },
                    { text: 'Parameter', value: 'element' },
                    { text: 'Data', value: 'data' },
                    { text: 'Unit', value: 'unit' }
                ]
            } else {
                this.headers = [
                    { text: 'Start', value: 'createDatetime' },
                    { text: 'End', value: 'resolveTime' },
                    { text: 'Device Name', value: 'assetName' },
                    { text: 'Location', value: 'location' },
                    { text: 'Device ID', value: 'deviceId' },
                    { text: 'Status', value: 'resolve' },
                    { text: 'Title', value: 'title' },
                    { text: 'Message', value: 'message' },
                    { text: 'Parameter', value: 'element' }
                ]
            }

        },
        getAlert() {
            this.loading = true
            this.$store.dispatch('Alert/GET_ALERT',{start: this.range.startDate.getTime(), end: this.range.endDate.getTime()}).then(() => {
                this.loading = false
            }).catch(() => {
                this.loading = false
            })
        }
    },
}

</script>