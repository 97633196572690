<template>
    <v-container fluid>
      <!-- <v-row >
        <v-col class="text-h5 font-weight-bold">
          <v-icon>mdi-chart-line</v-icon>Energy analysis
        </v-col>
        <v-col cols="auto">
          <v-autocomplete label="อาคาร" v-model="selectedBuilding" :items="buildings"/>
        </v-col>
      </v-row> -->
      <Head>
        <template v-slot:title>
          <v-icon>mdi-chart-line</v-icon>Energy analysis
        </template>
        </Head>
      <v-row>
        <v-col cols="4">
 <DAILY_POWER_QUALITY/>
        </v-col>
        <v-col cols="4">
 <WEEKLY_POWER_QUALITY/>
        </v-col>
        <v-col cols="4">
 <MONTHLY_POWER_QUALITY/>
        </v-col>
      </v-row>
      
    </v-container>
  </template>
  
  <script>
  // import PIE_CHART from '@/components/charts/pie_chart'
  // import BAR_CHART from '@/components/charts/bar_chart'
  // import ALERT_DATATABLE from '@/components/alerts/alert_datatable'
  // import SUMMARY_CHART from '@/components/charts/summary_chart'
  import DAILY_POWER_QUALITY from '@/components/energy_analysis/daily_power_quality'
  import MONTHLY_POWER_QUALITY from '@/components/energy_analysis/monthly_power_quality'
  import WEEKLY_POWER_QUALITY from '@/components/energy_analysis/weekly_power_quality'
  import Head from '@/components/layout/Head.vue'

    export default {
      name: 'HelloWorld',
      components: {
        DAILY_POWER_QUALITY,
        MONTHLY_POWER_QUALITY,
        WEEKLY_POWER_QUALITY,
        Head
      },
      data: () => ({
        buildings: ['อาคารศรีสวรินทิรานุสรณ์ 150 ปี'],
        selectedBuilding: 'อาคารศรีสวรินทิรานุสรณ์ 150 ปี',
        value: [
          423,
          446,
          675,
          510,
          590,
          610,
          760,
        ],
        items: [
        {
          action: 'mdi-flash-triangle',
          items: [{ title: 'List Item' }],
          title: 'Over Voltage',
        },
        {
          action: 'mdi-lightning-bolt',
          active: true,
          items: [
            { title: 'Breakfast & brunch' },
            { title: 'New American' },
            { title: 'Sushi' },
          ],
          title: 'Under Voltage',
        },
        {
          action: 'mdi-current-dc',
          items: [{ title: 'List Item' }],
          title: 'Over Current',
        },
        {
          action: 'mdi-scale-unbalanced',
          items: [{ title: 'List Item' }],
          title: 'Unbalance',
        },
        {
          action: 'mdi-current-ac',
          items: [{ title: 'List Item' }],
          title: 'Power Factor',
        },
        {
          action: 'mdi-sine-wave',
          items: [{ title: 'List Item' }],
          title: 'Harmonic',
        },
        {
          action: 'mdi-current-ac',
          items: [{ title: 'List Item' }],
          title: 'Frequency',
        },
      ],
        ecosystem: [
          {
            text: 'vuetify-loader',
            href: 'https://github.com/vuetifyjs/vuetify-loader',
          },
          {
            text: 'github',
            href: 'https://github.com/vuetifyjs/vuetify',
          },
          {
            text: 'awesome-vuetify',
            href: 'https://github.com/vuetifyjs/awesome-vuetify',
          },
        ],
        importantLinks: [
          {
            text: 'Documentation',
            href: 'https://vuetifyjs.com',
          },
          {
            text: 'Chat',
            href: 'https://community.vuetifyjs.com',
          },
          {
            text: 'Made with Vuetify',
            href: 'https://madewithvuejs.com/vuetify',
          },
          {
            text: 'Twitter',
            href: 'https://twitter.com/vuetifyjs',
          },
          {
            text: 'Articles',
            href: 'https://medium.com/vuetify',
          },
        ],
        whatsNext: [
          {
            text: 'Explore components',
            href: 'https://vuetifyjs.com/components/api-explorer',
          },
          {
            text: 'Select a layout',
            href: 'https://vuetifyjs.com/getting-started/pre-made-layouts',
          },
          {
            text: 'Frequently Asked Questions',
            href: 'https://vuetifyjs.com/getting-started/frequently-asked-questions',
          },
        ],
      }),
    }
  </script>
  