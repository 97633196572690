import { getAir } from '@/api/'

const state = () => ({})

const mutations = {}

const actions = {
    GET_AIR({commit}, data) {
        return new Promise((resolve, reject) => {
            getAir({buildingId: data.BuildingId, type: data.type, deviceId: data.deviceId, start: data.start, end: data.end}, message =>{
            resolve(message.data)
          }, error=> {
            reject(error)
          })
        })
      }
}


export default {
    namespaced: true,
    state,
    actions,
    mutations
  }