import { getHVACPlant, getChiller } from '@/api/'

const state = () => ({})

const mutations = {}

const actions = {
    GET_HVAC_PLANT({commit}, data) {
        return new Promise((resolve, reject) => {
            getHVACPlant({buildingId: data.BuildingId}, message =>{
            resolve(message.data)
          }, error=> {
            reject(error)
          })
        })
    },
    GET_CHILLER({commit}, data) {
      return new Promise((resolve, reject) => {
        getChiller({buildingId: data.BuildingId}, message =>{
          resolve(message.data)
        }, error=> {
          reject(error)
        })
      })
    }
}


export default {
    namespaced: true,
    state,
    actions,
    mutations
  }