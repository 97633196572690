<template>
    <v-row >
        <v-col cols="auto" class="text-h5 font-weight-bold">
            <slot name="title">

            </slot>
            <div class="text-subtitle-1 text-center">
                <slot name="subtitle">

                </slot>
            </div>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" md="4">
          <v-select label="อาคาร" item-text="BuildingName" return-object v-model="building" :items="buildingList"/>
        </v-col>
      </v-row>
</template>

<script>
import { mapState } from 'vuex';

export default {
computed: {
    ...mapState('User',['buildingList']),
      building: {
        get: function() {
          return this.$store.state.User.building
        },
        set: function(newValue) {
          this.$store.dispatch('User/SET_BUILDING', newValue)
        }
      },
},
}
</script>