<template>
    <v-hover v-slot="{ hover }">
        <v-card :elevation="hover ? 6 : 2">
            <v-card-text>
                <v-row>
                    <v-col cols="auto">
                        <div class="text-left text-h5 font-weight-bold black--text">
                            <v-icon>mdi-chart-timeline-variant-shimmer</v-icon>Energy distribution by load groups
                        </div>
                        <div class="text-left text-subtitle-1">ปริมาณการใช้ไฟฟ้าตามประเภท</div>
                    </v-col>
                    <v-spacer />
                    <v-col cols="auto">
                        เลือกช่วงเวลา
                        <DateRangePicker class="ml-2" v-model="range" :timePicker="false"/>
                    </v-col>
                    <v-col cols="2">
                        <v-select :disabled="loading" dense solo v-model="selectedMeter" multiple :items="assets"
                            item-text="assetName" item-value="deviceId"></v-select>
                    </v-col>
                    <v-col cols="auto"> <v-btn color="primary" :disabled="loading"
                            @click="getEnergy()">View</v-btn></v-col>
                </v-row>
                <div ref="chart" style="width: 100%;height:300px;"></div>
            </v-card-text>
        </v-card>
    </v-hover>
</template>
<script>
import * as echarts from 'echarts/core';
import {
    TitleComponent,
    TooltipComponent,
    LegendComponent
} from 'echarts/components';
import { PieChart } from 'echarts/charts';
import { LabelLayout } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import * as alasql from 'alasql'
import DateRangePicker from '@/components/common/DateRangePicker.vue'

echarts.use([
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    PieChart,
    CanvasRenderer,
    LabelLayout
]);

export default {
    props: {
        meter: {
            type: Array
        },
        update: {
            type: Boolean
        }
    },
    computed: {
        building: {
            get: function () {
                return this.$store.state.User.building
            },
            set: function (newValue) {
                this.$store.dispatch('User/SET_BUILDING', newValue)
            }
        },
        assets() {
            return this.$store.state.Asset.assetEnergy.filter((el) => el.assetType === 'PowerMeter')
        },
        meterNameList() {
            return this.$store.state.Asset.assetEnergy.filter((el) => el.parent === this.meter.deviceId).map(a => a.deviceId)
        },
        energyValue() {
            return this.$store.state.Energy.monthlyEnergyCompare
        }
    },
    components: {
        DateRangePicker,
    },
    watch: {
        meter(val) {
            if (val) {
                this.getEnergy()
            }
        }
    },
    data() {
        return {
            loading: false,
            range: {
                startDate: this.$dayjs().startOf('month').toDate(),
                endDate: this.$dayjs().endOf('month').toDate(),
            },
            myChart: null,
            selectedMeter: [],
        }
    },
    methods: {
        getEnergy() {
            this.loading = true
            this.$store.dispatch('Energy/GET_MONTHLY_ENERGY', { BuildingId: this.building.BuildingId, type: 'monthly', deviceId: this.selectedMeter, start: this.range.startDate.getTime(), end: this.range.endDate.getTime(), group: false }).then((raw) => {
                let data = alasql(`select * from ? as P1 JOIN ? as P2 ON  P1.deviceId = P2.deviceId`, [this.assets, raw])
                this.myChart.setOption(
                    {
                        title: {
                            text: '',
                            subtext: '',
                            left: 'center'
                        },
                        tooltip: {
                            trigger: 'item',
                            formatter: (value) => {
                                return `${value.name}: ${value.value} kWh`
                            }
                        },
                        toolbox: {
                            feature: {
                                dataView: { show: true, readOnly: false },
                                restore: { show: true },
                                saveAsImage: { show: true }
                            }
                        },
                        legend: {
                            show: true,
                            orient: 'horizontal',
                            left: 'left',
                        },

                        series: [
                            {
                                type: 'pie',
                                radius: '50%',
                                name: 'kWh',
                                data: data.map(item => {
                                    return { name: item.assetName, value: Math.floor(item.Unit) };
                                }), emphasis: {
                                    itemStyle: {
                                        shadowBlur: 10,
                                        shadowOffsetX: 0,
                                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                                    }
                                }
                            }
                        ]
                    });
                this.myChart.resize()
                this.loading = false
            }).catch(() => {
                this.loading = false
            })
        }
    },
    mounted() {
        this.myChart = echarts.init(this.$refs.chart);
        const resizeObserver = new ResizeObserver((entries) => {
            this.myChart.resize();
        });
        resizeObserver.observe(this.$refs.chart);
        this.selectedMeter = this.meter.map(a => a.deviceId)
        this.getEnergy()
    },
}
</script>