
import { getDevice, getTelemetryLastest, getAlert, getDeviceSummaryPromise, getTelemetryPromise, getAlertRule, postUploadFile, getBuilding, getAsset,getEnergy, getUser, getUnit,getAssetType } from '@/api/'
import Vue from 'vue'
import * as alasql from 'alasql'

function select (data, key, item) {
  const selectedData = {}
  selectedData[key] = data[key].map(function (d) { return d[item] })
  return selectedData
}

const groupBy = key => array =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key]
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj)
    return objectsByKeyValue
  }, {})

const groupByAssetType = groupBy('assetType')
const groupByStatus = groupBy('status')

const state = () => ({
  user: {},
  tenant: {},
  tenantList: [],
  devices: [],
  units: {},
  notification: true,
  alerts: [],
  permission: null,
  permissionLoading: false,
  role: null,
  macs: [],
  buildingList: [],
  assetList: [],
  building: {},
  isSupperAdmin: false,
  assetType: [],
})

const mutations = {
  SET_USER (state, data) {
    state.user = data
  },
  SET_UNIT(state, data) {
    let units = {}
    data.forEach((el)=>{
      units[el.element] = el.Unit
    })
    state.units = units
  },
  SET_ASSET_TYPE(state, data) {
    state.assetType = data
  },
  SET_ADMIN(state, data) {
    state.isSupperAdmin = data
  },
  SET_TENANT (state, data) {
    state.tenant = data
  },
  SET_PERMISSION (state, data) {
    state.permission = data
  },
  SET_PERMISSION_LOADING (state, data) {
    state.permissionLoading = data
  },
  SET_DEVICES(state, data) {
    const {devices,telemetry,alert} = data
    devices.forEach(device => {
      telemetry.forEach(telemetry => {
        if(device.mac === telemetry.mac) {
          device.value = telemetry
        }
      })
      alert.forEach(alert=> {
        if(device.mac === alert.mac) {
          device.alert = alert
        }
      })
      device.refreshTime = Vue.prototype.$dayjs()
    });
   
    state.devices = devices
  },
  SET_ALERT(state, data){
    state.alerts = data
  },
  SET_DEVICES_TELEMETRY(state, data) {
    state.devices.forEach(device => {
      data.forEach(telemetry=> {
        if(device.mac === telemetry.mac) {
          device.value = telemetry
        }
      })
      device.refreshTime = Vue.prototype.$dayjs()
    });
  },
  SET_DEVICES_ALERT(state, data) {
    state.devices.forEach(device => {
      data.forEach(alert=> {
        if(device.mac === alert.mac) {
          device.alert = alert
        }
      })
    });
  },
  SET_ROLES (state) {
    const roles = []
    state.user.roles.forEach(el=> {
      roles.push(JSON.parse(el))
    })
    if(state.user.isSupperAdmin) {
      state.role = 'Admin'
    }else{
      state.role = roles.filter(el=> el.TenantId === state.building.partitionKey && el.BuildingId === state.building.BuildingId)[0].Role
    }
  },
  SET_MACS(state, data) {
    state.macs= data
  },
  SET_TENANT_LIST(state, data) {
    state.tenantList = data
  },
  SET_BUILDING_LIST(state, data) {
    state.buildingList = data
  },
  SET_BUILDING(state, data){
    state.building = data
  },
  SET_NOTIFICATION(state, data) {
    state.notification = data
  }
}

const actions = {
  SET_NOTIFICATION({commit}, data) {
    commit('SET_NOTIFICATION', data)
  },
  CHECK_PERMISSION ({ commit, state }, { endpoint, method }) {
    return new Promise((resolve) => {
      if (Array.isArray(state.permission)) {
        const hasPermit = state.permission.filter((element) => element.endpoint === endpoint && element.method === method)
        if (hasPermit.length > 0) {
          resolve(true)
        } else {
          resolve(false)
        }
      } else {
        resolve(false)
      }
    })
  },
  UPDATE_TELEMETRY({ commit, state }) {
    return new Promise((resolve, reject) => {
      // console.log('UPDATE_TELEMETRY')
      const body = {
        trackingId: state.macs
      }
      getTelemetryLastest(body, message => {
        commit('SET_DEVICES_TELEMETRY', message.data.value)
        resolve(message.data.value)
      }, error=> {
        reject(error)
      })
    })
  },
  GET_ALL_BUILDING({ commit }) {
    return new Promise((resolve, reject) => {
      getBuilding( message=>{
        commit('SET_BUILDING_LIST', message.data)
        resolve(message.data)
      }, error=> {
        reject(error)
      })
    })
  },
  GET_DEVICES ({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      getDevice({ deviceType: 'sensor', tenantId: rootState.User.tenant.tenantId }, message => {
        const devices = message.data.value
        const macs = select(message.data, 'value', 'mac')
        const body = {
          trackingId: macs.value
        }
        commit('SET_MACS',macs.value)
        getTelemetryLastest(body, message => {
          const telemetry = message.data.value
          getAlertRule({tenantId: rootState.User.tenant.tenantId}, message =>{
            const alert = message.data.value
            commit('SET_DEVICES', {devices:devices, telemetry: telemetry, alert: alert})
            resolve(message.data.value)
          })
        }, error=> {
          reject(error)
        })
        
      }, error => {
        reject(error)
      })
    })
  },
  GET_ALERT ({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      const startDate = Vue.prototype.$dayjs().subtract(7, 'day').format('YYYY-MM-DD')
      const endDate =  Vue.prototype.$dayjs().format('YYYY-MM-DD')
      getAlert({ tenantId: rootState.User.tenant.tenantId,startTime: startDate, endTime: endDate}, message => {
        commit('SET_ALERT', message.data.value)
        resolve(message.data.value)
      }, error => {
        reject(error)
      })
    })
  },
  GET_DEVICE_SUMMARY({ commit, rootState },{trackingId, range}) {
    let continuationToken = ''
    return new Promise(async (resolve, reject) => {
      const startDate = Vue.prototype.$dayjs(range.start).format('YYYY-MM-DD')
      const endDate =  Vue.prototype.$dayjs(range.end).format('YYYY-MM-DD')
      const message = await getDeviceSummaryPromise({ trackingId: trackingId, startTime: startDate, endTime: endDate, continuationToken: continuationToken })
      if (message.data.continuationToken === '') {
        resolve(message.data.value)
      } else {
        let data = message.data.value
        continuationToken = message.data.continuationToken
        while (continuationToken) {
          const loopMessage = await getDeviceSummaryPromise({ trackingId: trackingId, startTime: startDate, endTime: endDate, continuationToken: continuationToken })
          continuationToken = loopMessage.data.continuationToken
          data = [...data, ...loopMessage.data.value]
          resolve(data)
        }
      }
    })
  },
  GET_TELEMETRY ({ commit, rootState },{trackingId, range}) {
    let continuationToken = ''
    return new Promise(async (resolve, reject) => {
      const startDate = Vue.prototype.$dayjs(range.start).format('YYYY-MM-DD')
      const endDate =  Vue.prototype.$dayjs(range.end).format('YYYY-MM-DD')
      const message = await getTelemetryPromise({ trackingId: trackingId, startTime: startDate, endTime: endDate, continuationToken: continuationToken })
      if (message.data.continuationToken === '') {
        resolve(message.data.value)
      } else {
        let data = message.data.value
        continuationToken = message.data.continuationToken
        while (continuationToken) {
          const loopMessage = await getTelemetryPromise({ trackingId: trackingId, startTime: startDate, endTime: endDate, continuationToken: continuationToken })
          continuationToken = loopMessage.data.continuationToken
          data = [...data, ...loopMessage.data.value]
          resolve(data)
        }
      }
    })
  },
  GET_PERMISSION ({ commit, rootState }, roleId) {
    return new Promise((resolve, reject) => {
      // getPermission({ tenantId: rootState.User.tenant.tenantId, roleId: roleId }, message => {
      //   commit('SET_PERMISSION', message.data.value)
      //   resolve(message)
      // }, error => {
      //   reject(error)
      // })
    })
  },
  POST_UPLOAD_FILE({commit}, data) {
    return new Promise((resolve, reject)=> {
      postUploadFile(data, message=>{
        resolve(message.data)
      }, error=> {
        reject(error)
      })
    })
  },
  GET_USER ({ commit }) {
    return new Promise((resolve, reject)=> {
      getUser(message=>{
        resolve(message.data)
      }, error=> {
        reject(error)
      })
    })
  }, //getUnit
  GET_UNIT ({ commit }) {
    return new Promise((resolve, reject)=> {
      getUnit(message=>{
        resolve(message.data)
      }, error=> {
        reject(error)
      })
    })
  },
  GET_ASSET_TYPE ({ commit }) {
    return new Promise((resolve, reject)=> {
      getAssetType(message=>{
        resolve(message.data)
      }, error=> {
        reject(error)
      })
    })
  },
  SET_ADMIN({commit}, data) {
    commit('SET_ADMIN', data.isSupperAdmin)
  },
  SET_USER ({ commit, state, dispatch }, data) {
    return new Promise((resolve, reject) => {
      dispatch('SET_ADMIN', data)
      commit('SET_PERMISSION_LOADING', true)
      dispatch('GET_USER').then((profile)=> {
        commit('SET_USER', data)
          dispatch('GET_ASSET_TYPE').then((assetType)=> {
            commit('SET_ASSET_TYPE', assetType)
            dispatch('GET_UNIT').then((units)=> {
              commit('SET_UNIT', units)
              const map = new Map(profile.map(pos => [pos.TenantId, pos]));
              const tenent = [...map.values()];
              dispatch('SET_TENANT', tenent[0])
              commit('SET_TENANT_LIST', tenent)
              dispatch('GET_ALL_BUILDING').then((message)=> {
                dispatch('SET_BUILDING', message[0]).then(()=>{
                  commit('SET_ROLES')
                  commit('SET_PERMISSION_LOADING', false)
                  resolve(message)
                }).catch(()=>{
                  commit('SET_PERMISSION_LOADING', false)
                  console.log(error)
                  reject(error)
                })
              }).catch((error) => {
                commit('SET_PERMISSION_LOADING', false)
                console.log(error)
                reject(error)
              })
            }).catch((error)=> {
              commit('SET_PERMISSION_LOADING', false)
              console.log(error)
              reject(error)
            })
          }).catch((error)=> {
            commit('SET_PERMISSION_LOADING', false)
            console.log(error)
            reject(error)
          })
      }).catch((error)=> {
        commit('SET_PERMISSION_LOADING', false)
        console.log(error)
        reject(error)
      })
 
    })
  },
  SET_SELECTED_MAIN_METER({commit}, data) {
    commit('SET_SELECTED_MAIN_METER', data)
  },
  SET_SELECTED_MAIN_WATER_METER({commit}, data) {
    commit('SET_SELECTED_MAIN_WATER_METER', data)
  },
  SET_BUILDING({commit, dispatch}, data) {
    return new Promise((resolve, reject) => {
    commit('SET_BUILDING', data)
    dispatch('Asset/GET_ALL_ASSET',data,{ root: true }).then((message)=> {
      dispatch('Alert/GET_ALERT_LATEST',null,{ root: true }).then(()=>{
        dispatch('Energy/SET_SELECTED_MAIN_METER_BUILDING_CHANGE',null ,{ root: true })
        dispatch('Water/SET_SELECTED_MAIN_WATER_METER_BUILDING_CHANGE',null, { root: true })
        resolve(message)
      })
    }).catch((error)=>{
      reject(error)
    })
    })
  },
  SET_TENANT ({ commit,dispatch }, data) {
    return new Promise((resolve, reject) => {
      commit('SET_TENANT', data)
      dispatch('GET_ALL_BUILDING').then((message)=> {
        dispatch('SET_BUILDING', message[0]).then((message)=> {
          resolve(message)
        }).catch(()=>{
          console.log(error)
          reject()
        })
      }).catch((error) => {
        console.log(error)
        reject()
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
