<template>
    <v-hover v-slot="{ hover }">
      <v-card :elevation="hover ? 6 : 2" class="mt-2 mr-2 ml-2 mb-2" > 
          <v-card-text>
          <v-data-table :search="search" :loading="loading" :headers="headers" :items="connector">
            <template v-slot:top>
                <v-row>
                <v-spacer/>
              <v-col cols="2">
              <v-text-field label="Search" v-model="search" solo dense prepend-inner-icon="mdi-magnify"></v-text-field>
              </v-col>
            </v-row>
            </template>
              <template v-slot:item.apTime="{ item }">
          {{$dayjs(item.apTime * 1000).fromNow()}}
      </template>
          </v-data-table>
          </v-card-text>
      </v-card>
      </v-hover>
  </template>
  <script>
  export default {
      data() {
          return {
              headers: [
                  {text: 'Device ID', value: 'rowKey'},
                  {text: 'API KEY', value: 'username'},
                  {text: 'API Secret', value: 'password'}
              ],
              loading: false,
              search: '',
              interval: null
          }
      },
      mounted () {
          this.getConnector();
        //   this.interval =  setInterval(()=>{
        //       this.getConnector();
        //   }, 60000)
      },
      destroyed () {
          this.interval = null
      },
      computed: {
          building: {
          get: function () {
            return this.$store.state.User.building
          },
          set: function (newValue) {
            this.$store.dispatch('User/SET_BUILDING', newValue)
          }
        },
        connector: {
          get: function () {
            return this.$store.state.Network.connector
          }
        }
      },
      methods: {
          getConnector() {
              this.loading = true
              this.$store.dispatch('Network/GET_CONNECTOR',{buildingId: this.building.BuildingId}).then(()=>{
                  this.loading = false
              }).catch(()=>{
                  this.loading = false
              })
          }
      },
  }
  
  </script>