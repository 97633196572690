<template>
    <v-card>
        <v-card-title>
            คำนวณประวัติการใช้ไฟฟ้าย้อนหลัง <v-spacer/>  <v-btn icon @click="$emit('onClose')"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
            <v-snackbar v-model="errorSnack" :timeout="timeout">
                {{ errorMessage }}
                <template v-slot:action="{ attrs }">
                    <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
                        Close
                    </v-btn>
                </template>
            </v-snackbar>
            <v-row>
                <v-col cols="12">
                    <v-text-field disabled label="DeviceId" :rules="[v => !!v || 'Please specify']"
                        v-model="asset.deviceId"></v-text-field>
                    <v-text-field :disabled="loading" label="Maximum" type="number" :rules="[v => !!v || 'Please specify']"
                        v-model="maximum"></v-text-field>
                    <v-menu :disabled="loading" ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="date"
                        transition="scale-transition" offset-y max-width="290px" min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="date" label="เลือกเดือน" append-icon="mdi-calendar" readonly
                                v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="date" type="month" no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="menu = false">
                                Cancel
                            </v-btn>
                            <v-btn text color="primary" @click="$refs.menu.save(date)">
                                OK
                            </v-btn>
                        </v-date-picker>
                    </v-menu>

                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
            <v-spacer/><v-btn v-if="role === 'Admin'" color="primary" :loading="loading"
                @click="reprocess">REPROCESS</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    props: {
        asset: {
            type: Object
        }
    },
    data() {
        return {
            loading: false,
            maximum: 999999,
            date: new Date().toISOString().substr(0, 7),
            menu: false,
            errorSnack: false,
            errorMessage: '',
            timeout: 2000,
        }
    },
    mounted() {
        if (this.asset.data) {
            this.maximum = Number(JSON.parse(this.asset.data)['EnergyConsumption']) + 100
        }
    },
    watch: {
        date(val) {
            console.log(new Date(this.date).getTime())
        }
    },
    computed: {
        role: {
            get: function () {
                return this.$store.state.User.role
            }
        },
        building: {
            get: function () {
                return this.$store.state.User.building
            },
            set: function (newValue) {
                this.$store.dispatch('User/SET_BUILDING', newValue)
            },
        },
      
    },
    methods: {
        reprocess() {
            this.loading = true
            this.$store.dispatch('Energy/GET_ENERGY_REPROCESS', { buildingId: this.building.BuildingId, deviceId: this.asset.deviceId, period: new Date(this.date).getTime(), maximum: this.maximum }).then((message) => {
                this.loading = false
                this.errorSnack = true
                this.errorMessage = message.data
            }).catch((error) => {
                this.loading = false
                this.errorSnack = true
                this.errorMessage = error
            })
        }

    }
}
</script>