<template>
  <v-container fluid>
    
    <!-- <v-row >
      <v-col class="text-h5 font-weight-bold">
        <v-icon>mdi-view-dashboard</v-icon>Energy Dashboard
      </v-col>
      <v-col cols="auto">
        <v-autocomplete label="อาคาร" v-model="selectedBuilding" :items="buildings"/>
      </v-col>
    </v-row> -->
    <Head>
        <template v-slot:title>
          <v-icon>mdi-view-dashboard</v-icon>Energy Dashboard
        </template>
        </Head>
    <IAQ_SUMMARY/>
    <v-row class="pt-2">
    <ENERGY_SUMMARY/>
      <v-col cols="12">
    <ENERGY_COMPARISON/>
      </v-col>
     <v-col cols="6">
      <ENERGY_DISTRIBUTION_BY_LOAD/>
     </v-col>
     <v-col cols="6">
   <ENERGY_DISTRIBUTION_BY_TIME/>
     </v-col>
    </v-row>
  </v-container>
</template>

<script>
import IAQ_SUMMARY from '@/components/energy_management/iaq_summary'
import ENERGY_DISTRIBUTION from '@/components/energy_management/energy_distribution'
import ENERGY_DISTRIBUTION_BY_LOAD from '@/components/energy_management/energy_distribution_by_load'
import ENERGY_SUMMARY from '@/components/energy_management/energy_summary'
import ENERGY_COMPARISON from '@/components/energy_management/energy_comparison'
import ENERGY_DISTRIBUTION_BY_TIME from '@/components/energy_management/energy_distribution_by_time'
import Head from '@/components/layout/Head.vue'

// import SUMMARY_CHART from '@/components/charts/summary_chart'

  export default {
    name: 'EnergyManagementView',
    components: {
      IAQ_SUMMARY,
      ENERGY_DISTRIBUTION,
      ENERGY_DISTRIBUTION_BY_LOAD,
      ENERGY_SUMMARY,
      ENERGY_COMPARISON,
      ENERGY_DISTRIBUTION_BY_TIME,
      Head
    },
    data: () => ({
      buildings: ['อาคารศรีสวรินทิรานุสรณ์ 150 ปี'],
      selectedBuilding: 'อาคารศรีสวรินทิรานุสรณ์ 150 ปี',
      value: [
        423,
        446,
        675,
        510,
        590,
        610,
        760,
      ]
    }),
  }
</script>
