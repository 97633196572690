<template>
  <v-navigation-drawer app clipped right v-bind:value="drawer" @input="$emit('update:drawer', $event)"
    class="elevation-2">
    <v-list three-line>
      <v-list-item v-if="alertLatest.length === 0">
        <v-list-item-content>
          <v-list-item-title class="text-center"><v-icon
              color="green">mdi-check-circle-outline</v-icon></v-list-item-title>
          <v-list-item-title class="text-center">ไม่มีรายการแจ้งเตือน</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <template v-for="(item, index) in alertLatest">
        <v-card flat :key="index" @click="navigate(item)">
          <v-card-title><v-icon color="red">mdi-alert</v-icon>{{ item.assetName }}</v-card-title>
          <v-card-text>
            {{ `${item.title}\n ${item.message}\n ${item.location}\n${(item.showData) ? buildStr(item.data) : ''} เวลา:
            ${$dayjs(item.createDatetime).local().format('DD MMM YYYY HH:mm')}`}}
          </v-card-text>
        </v-card>
        <v-divider :key="index + 1 + '_'" />
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    drawer: {
      type: Boolean
    },
  },
  computed: {
    ...mapState('Alert', ['alertLatest']),
  },
  data() {
    return {
      services: [
        { name: 'Electrical', nameTH: 'ระบบไฟฟ้า', service: 'ENERGY', icons: 'mdi-lightning-bolt-circle', navigate: 'electrical', subgroup: false, active: false, id: '20340ea6-88dd-11ee-b9d1-0242ac120003', color: 'primary', alarm: false },
        { name: 'Sanitary', nameTH: 'ระบบสุขาภิบาล', service: 'SANITARY', icons: 'mdi-waves-arrow-up', navigate: 'sanitary', subgroup: false, active: false, id: '20340ea6-88dd-11ee-b9d1-0242ac120004', color: 'blue', alarm: false },
        { name: 'HVAC', nameTH: 'ระบบปรับอากาศ', service: 'HVAC', icons: 'mdi-hvac', navigate: 'hvac', subgroup: false, active: false, id: '5b3c30a7-978d-45e0-beda-0a1bf7ba8048', color: 'grey', alarm: false },
        { name: 'FireAlarm', nameTH: 'ระบบแจ้งเตือนเหตุเพลิงไหม้', service: 'FIREALARM', icons: 'mdi-fire', navigate: 'fire_alarm', subgroup: true, active: true, id: '20340ea6-88dd-11ee-b9d1-0242ac120002', color: 'red', alarm: false },
        { name: 'Machine', nameTH: 'ระบบติดตามสถานะเครื่องจักร', service: 'Machine Status', icons: 'mdi-engine', navigate: 'machine', subgroup: true, active: true, id: '32a218c-88dd-11ee-b9d1-0242ac12001a', color: 'green', alarm: false },
        { name: 'AirQuality', nameTH: 'ระบบติดตามคุณภาพอากาศ', service: 'AIR_QUALITY', icons: 'mdi-air-filter', navigate: 'air_quality', subgroup: true, active: true, id: '20340ea6-88dd-11ee-b9d1-0242ac120001', color: 'teal darken-3', alarm: false }
      ],
    }
  },
  methods: {
    navigate(item) {
      console.log(item.serviceId)
      const page = this.services.filter(el => el.id === item.serviceId)
      if (page.length > 0) {
        this.$router.push({ name: page[0].navigate }).catch((error) => { console.log(error) })
      }
    },
    buildStr(obj) {
      if (obj) {
        let message = ''
        Object.keys(obj).forEach(function (key) {
          message += key + ": " + obj[key] + '\n';
        });
        return message
      } else {
        return ''
      }

    },
  },
}
</script>