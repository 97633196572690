<template>
    <v-container fluid>
      <Head>
        <template v-slot:title>
          <v-btn icon :to="{name: 'overview'}"><v-icon>mdi-arrow-left</v-icon></v-btn>
          <v-icon >mdi-chart-box-outline</v-icon>Report
        </template>
        <template v-slot:subtitle>
       รายงาน
      </template>
        </Head>
      <v-row>
        <v-col cols="12">
          <Table/>
        </v-col>
      </v-row>
     
       
      
    </v-container>
  </template>
  
  <script>
  import Head from '@/components/layout/Head.vue'
  import FilterData from '@/components/report/report_filter.vue'
  import Table from '@/components/report/report_table.vue'

    export default {
      name: 'HelloWorld',
      components: {
        Head,
        FilterData,
        Table
      },
     data() {
        return {
        }
     },
    }
  </script>
  