<template>
    <div ref="chart" style="width: 100%;height:120px;"></div>
  </template>
  <script>
  import * as echarts from 'echarts/core';
  import { GridComponent } from 'echarts/components';
  import { LineChart } from 'echarts/charts';
  import { UniversalTransition } from 'echarts/features';
  import { CanvasRenderer } from 'echarts/renderers';
import { name } from 'dayjs/locale/th';
  
  echarts.use([GridComponent, LineChart, CanvasRenderer, UniversalTransition]);
  Array.prototype.max = function() {
    return Math.max.apply(null, this);
  };
  
  Array.prototype.min = function() {
    return Math.min.apply(null, this);
  };
  
  export default {
      props: {
          category: {
              type: Array,
          },
          data: {
              type: Array,
          },
          element: {
            type: String
          },
          unit: {
            type: String
          }
      },
      data() {
          return {
              mychart: null
          }
      },
      watch: {
          category(val) {
              val && this.setChart()
          },
          data(val) {
               val && this.setChart()
          }
      },
      methods: {
          setChart() {
              this.mychart = echarts.init(this.$refs.chart);
              this.mychart.setOption({

                xAxis: {
                  type: 'category',
                  data: this.category,
                  axisLabel: {
                    interval: 'auto'
                  }
                },
                yAxis: {
                  type: 'value',
                  name: this.unit,
                },
                tooltip: {
                  trigger: 'axis',
                  formatter: (params) => {
                      return params[0].value + ' ' + this.unit
                    }
                },
                grid: [{
                  top: '25%',
                  left: '3%',
                  right: '4%',
                  bottom: '3%',
                  containLabel: true
                }],
                series: [
                  {
                    data: this.data,
                    type: 'bar',
                    smooth: true
                  }
                ]
              });
              this.mychart.resize()
          }
      },
      mounted () {
          this.mychart = echarts.init(this.$refs.chart);
          const resizeObserver = new ResizeObserver((entries) => {
              this.mychart.resize();
          });
          resizeObserver.observe(this.$refs.chart);
          this.setChart()
      },
  }
  </script>