<template>
    <div :class="`text-${align}`">
        <v-dialog v-model="historyDialog" v-if="currentItem" scrollable>
            <DataHistory :asset="currentItem" @onClose="historyDialog = false"></DataHistory>
        </v-dialog>
        <v-dialog width="600px" v-model="editDialog" v-if="currentItem" scrollable>
            <AssetEdit :asset="currentItem" @onClose="editDialogClose"></AssetEdit>
        </v-dialog>
        <v-dialog v-model="alertDialog" v-if="currentItem" scrollable>
            <AlertSetting :asset="currentItem" @onClose="alertDialog = false"></AlertSetting>
        </v-dialog>
        <v-dialog v-model="tokenDialog" v-if="currentItem">
            <TokenSetting :asset="currentItem" @onClose="tokenDialog = false"></TokenSetting>
        </v-dialog>
        <v-dialog v-model="emailDialog" v-if="currentItem">
            <EmailSetting :asset="currentItem" @onClose="emailDialog = false"></EmailSetting>
        </v-dialog>
        <v-dialog v-model="alertHistoryDialog" v-if="currentItem" scrollable>
            <AlertHistory :asset="currentItem" @onClose="alertHistoryDialog = false"></AlertHistory>
        </v-dialog>
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon
                    @click="openAlertHistoryDialog()"><v-icon>mdi-history</v-icon></v-btn>
            </template>
            <span>ประวัติแจ้งเตือน</span>
        </v-tooltip>
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon @click="openHistoryDialog()"><v-icon>mdi-table</v-icon></v-btn>
            </template>
            <span>ประวัติข้อมูล</span>
        </v-tooltip>

        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon @click="openEditDialog()"><v-icon>mdi-pencil</v-icon></v-btn>
            </template>
            <span>แก้ไขข้อมูล</span>
        </v-tooltip>

        <v-tooltip bottom v-if="role == 'Admin'">
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon @click="openAlertDialog()"><v-icon>mdi-alert-plus</v-icon></v-btn>
            </template>
            <span>เงื่อนไขการแจ้งเตือน</span>
        </v-tooltip>

        <v-tooltip bottom v-if="role == 'Admin'">
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon @click="openTokenDialog()"><v-icon>$LineIcon</v-icon></v-btn>
            </template>
            <span>ไลน์</span>
        </v-tooltip>

        <v-tooltip bottom v-if="role == 'Admin'">
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon @click="openEmailDialog()"><v-icon>mdi-email</v-icon></v-btn>
            </template>
            <span>อีเมล</span>
        </v-tooltip>

        <slot name="extended"></slot>
    </div>

</template>

<script>
import DataHistory from '@/components/asset/asset_history.vue'
import AssetEdit from '@/components/asset/asset_edit.vue'
import AlertSetting from '@/components/asset/asset_alert_setting.vue'
import TokenSetting from '@/components/asset/asset_token_setting.vue'
import EmailSetting from '@/components/asset/asset_email_setting.vue'
import AlertHistory from '@/components/asset/asset_alert_history.vue'

export default {
    props: {
        asset: {
            type: Object
        },
        align: {
            type: String,
            default: 'left'
        }
    },
    components: {
        AssetEdit,
        DataHistory,
        AlertSetting,
        TokenSetting,
        EmailSetting,
        AlertHistory
    },
    data() {
        return {
            currentItem: null,
            historyDialog: false,
            editDialog: false,
            alertDialog: false,
            tokenDialog: false,
            emailDialog: false,
            alertHistoryDialog: false,
        }
    },
    computed: {
        units() {
            return this.$store.state.User.units
        },
        role: {
            get: function () {
                return this.$store.state.User.role
            }
        },
    },
    mounted() {
        this.currentItem = this.asset;
    },
    methods: {
        openHistoryDialog() {
            this.currentItem = this.asset
            this.historyDialog = true
        },
        openEditDialog() {
            this.currentItem = this.asset
            this.editDialog = true
        },
        openAlertDialog() {
            this.currentItem = this.asset
            this.alertDialog = true
        },
        openTokenDialog() {
            this.currentItem = this.asset
            this.tokenDialog = true
        },
        editDialogClose() {
            this.editDialog = false
            this.refresh()
        },
        openEmailDialog() {
            this.currentItem = this.asset
            this.emailDialog = true
        },
        openAlertHistoryDialog() {
            this.currentItem = this.asset
            this.alertHistoryDialog = true
        }
    },
}
</script>