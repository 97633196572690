<template>
    <v-container fluid>
      <Head>
        <template v-slot:title>
            <v-btn icon :to="{name: 'overview'}"><v-icon>mdi-arrow-left</v-icon></v-btn>
          <v-icon color="grey">mdi-hvac</v-icon>HVAC
        </template>
        </Head>
        <v-tabs centered v-model="tab">
    <v-tab>Plant</v-tab>
    <v-tab>Chiller</v-tab>
    <v-tab>Machine</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
       <HVAC_PLANT/>
      </v-tab-item>
      <v-tab-item>
        <CHILLER_LIST/>
        <!-- <v-card flat>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="3">
                <v-hover v-slot="{ hover }">
                  <v-card :elevation="hover ? 6 : 2" >
                  <v-card-title>
                    Chiller Carrier No.2
                  </v-card-title>
                  <v-card-text>
                   <v-img :src="require('@/assets/chiller_carrier.png')" height="200px" contain></v-img>
                   <v-row>
                    <v-col cols="6">
                      <v-card>
                        <v-img :src="require('@/assets/cooling_tower.png')" contain></v-img>
                      </v-card>
                    </v-col>
                    <v-col cols="6">
                      <v-card>
                        <v-img :src="require('@/assets/cooling_tower.png')" contain></v-img>
                      </v-card>
                    </v-col>
                   </v-row>
                  </v-card-text>
                </v-card>
                </v-hover>
                </v-col>
                <v-col cols="12" md="3">
                  <v-hover v-slot="{ hover }">
                    <v-card :elevation="hover ? 6 : 2" >
                  <v-card-title>
                    Chiller Carrier No.4
                  </v-card-title>
                  <v-card-text>
                   <v-img :src="require('@/assets/chiller_carrier.png')" height="200px" contain></v-img>
                   <v-row>
                    <v-col cols="6">
                      <v-card>
                        <v-img :src="require('@/assets/cooling_tower.png')" contain></v-img>
                      </v-card>
                    </v-col>
                    <v-col cols="6">
                      <v-card>
                        <v-img :src="require('@/assets/cooling_tower.png')" contain></v-img>
                      </v-card>
                    </v-col>
                   </v-row>
                  </v-card-text>
                </v-card>
                </v-hover>  
                </v-col>
                <v-col cols="12" md="3">
                  <v-hover v-slot="{ hover }">
                    <v-card :elevation="hover ? 6 : 2" >
                  
                  <v-card-title>
                    Chiller Daikin No.1
                  </v-card-title>
                  <v-card-text>
                   <v-img :src="require('@/assets/chiller_daikin.jpg')" height="200px" contain></v-img>
                   <v-row>
                    <v-col cols="6">
                      <v-card>
                        <v-img :src="require('@/assets/cooling_tower.png')" contain></v-img>
                      </v-card>
                    </v-col>
                    <v-col cols="6">
                      <v-card>
                        <v-img :src="require('@/assets/cooling_tower.png')" contain></v-img>
                      </v-card>
                    </v-col>
                   </v-row>
                  </v-card-text>
                </v-card>
                </v-hover>
                </v-col>
                <v-col cols="12" md="3">
                  <v-hover v-slot="{ hover }">
                    <v-card :elevation="hover ? 6 : 2" >
                  <v-card-title>
                    Chiller Daikin No.3
                  </v-card-title>
                  <v-card-text>
                   <v-img :src="require('@/assets/chiller_daikin.jpg')" height="200px" contain></v-img>
                   <v-row>
                    <v-col cols="6">
                      <v-card>
                        <v-img :src="require('@/assets/cooling_tower.png')" contain></v-img>
                      </v-card>
                    </v-col>
                    <v-col cols="6">
                      <v-card>
                        <v-img :src="require('@/assets/cooling_tower.png')" contain></v-img>
                      </v-card>
                    </v-col>
                   </v-row>
                  </v-card-text>
                </v-card>
                </v-hover>
                </v-col>
            </v-row>
          </v-card-text>
        </v-card> -->
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text >
            <MACHINE_VIEW :assets="hvac_machine"/>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    </v-container>
  </template>
  
  <script>
  import Head from '@/components/layout/Head.vue'
  import MACHINE_VIEW from '@/components/machine/machine_view'
  import HVAC_PLANT from '@/components/hvac/hvac_plant.vue'
  import CHILLER_LIST from '@/components/hvac/chiller_list.vue'
    export default {
      name: 'HelloWorld',
      components: {
        Head,
        MACHINE_VIEW,
        HVAC_PLANT,
        CHILLER_LIST
      },
      data() {
        return {
            tab: null,
        }
      },
   computed: {
      hvac_machine() {
            return this.$store.state.Asset.assetAll.filter(el =>  el.machine === true && el.serviceId === '5b3c30a7-978d-45e0-beda-0a1bf7ba8048')
      }
    },
  }
  </script>
  