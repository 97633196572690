<template>
  <div ref="mixchart" class="mt-5" style="width: 100%;height:400px;"></div>
</template>
<script>
import * as echarts from 'echarts/core';
import {
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent
} from 'echarts/components';
import { BarChart, LineChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use([
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  BarChart,
  LineChart,
  CanvasRenderer,
  UniversalTransition
]);

export default {
   
    data() {
        return {
            myChart: null,
            toggle_exclusive: 0,
            option: {
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross',
      crossStyle: {
        color: '#999'
      }
    }
  },
  toolbox: {
    feature: {
      dataView: { show: true, readOnly: false },
      magicType: { show: true, type: ['line', 'bar'] },
      restore: { show: true },
      saveAsImage: { show: true }
    }
  },
  legend: {
    data: ['Evaporation', 'Precipitation', 'Temperature']
  },
  xAxis: [
    {
      type: 'category',
      data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      axisPointer: {
        type: 'shadow'
      }
    }
  ],
  yAxis: [
    {
      type: 'value',
      name: 'kwh',
      min: 0,
      max: 250,
      interval: 50,
      axisLabel: {
        formatter: '{value} kwh'
      }
    },
    // {
    //   type: 'value',
    //   name: 'Temperature',
    //   min: 0,
    //   max: 25,
    //   interval: 5,
    //   axisLabel: {
    //     formatter: '{value} °C'
    //   }
    // }
  ],
  series: [
    {
      name: 'Today',
      type: 'bar',
      tooltip: {
        valueFormatter: function (value) {
          return value + ' kwh';
        }
      },
      data: [
        2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3
      ]
    },
    {
      name: 'Yesterday',
      type: 'bar',
      tooltip: {
        valueFormatter: function (value) {
          return value + ' kwh';
        }
      },
      data: [
        2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0, 2.3
      ]
    },
    // {
    //   name: 'Temperature',
    //   type: 'line',
    //   yAxisIndex: 1,
    //   tooltip: {
    //     valueFormatter: function (value) {
    //       return value + ' °C';
    //     }
    //   },
    //   data: [2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3, 23.4, 23.0, 16.5, 12.0, 6.2]
    // }
  ]
}
        }
    },
    mounted () {
        this.myChart =  echarts.init(this.$refs.mixchart);
        this.myChart.setOption(this.option);
    },
}
</script>