<template>
    <v-card>
        <v-card-title>Alert History |  {{ asset.assetName }} ({{ asset.deviceId }}) {{ asset.assetType }}<v-spacer/> <v-btn icon @click="$emit('onClose')"><v-icon>mdi-close</v-icon></v-btn></v-card-title>
        <v-card-text>
            <v-data-table :search="search" :headers="headers" :loading="loading" :items="items"  sort-by="createDatetime"
                    :sort-desc="true">
                <template v-slot:top>
                    <v-row>
                        <v-col cols="auto">
                    <v-text-field v-model="search" solo dense prepend-inner-icon="mdi-magnify" label="Search"/>
                    </v-col>
                    <v-spacer/>
                    <v-col cols="12" md="auto">
                <v-btn outlined color="primary" @click="exportToXLS(`Export${$dayjs().format('DDMMMYYYYHHmmss')}`,{ headers: headers,items: items })"><v-icon>mdi-export</v-icon>Export</v-btn>
              </v-col>
   
               
               
                 </v-row>
                </template>
    
                <template v-slot:item.createDatetime="{ item }">
                        {{ $dayjs(item.createDatetime).local().format('DD MMM YY HH:mm') }}
                    </template>
                    <template v-slot:item.resolveTime="{ item }">
                        {{ (item.resolveTime) ? $dayjs(item.resolveTime * 1000).local().format('DD MMM YY HH:mm') : '' }}
                    </template>

                    <template v-slot:item.resolve="{ item }">
                        <v-icon color="green" v-if="item.resolve">mdi-check</v-icon>
                        <v-icon color="red" v-else>mdi-alert</v-icon>
                    </template>
                    <template v-slot:item.data="{ item }">
                        {{ item[`${item.element}`] }}
                    </template>
                    <template v-slot:item.unit="{ item }">
                        {{ units[item.element] }}
                    </template> 
            </v-data-table>
        </v-card-text>
    </v-card>
    
    </template>
    <script>
    import { xlsxMixins } from '@/mixins/xlsx'
    export default {
        mixins: [xlsxMixins],
        props: {
                asset: {
                    type: Object
                },
            },
        watch: {
            asset(val) {
                if(val.assetId) {
                    this.getData(val.assetId)
                }
            }
        },
        data() {
            return {
                search: '',
                valid: false,
                dialog: false,
                dialogDelete: false,
                errorSnack: false,
                errorMessage: '',
                timeout: 2000,
                operations: [
                {text: '=', value: 'eq'},
                {text: '>=', value: 'ge'},
                {text: '<=', value: 'le'},
                {text: '!=', value: 'nq'},
                ],
                headers: [
                { text: 'Start', value: 'createDatetime' },
                { text: 'End', value: 'resolveTime' },
                { text: 'Device ID', value: 'deviceId' },
                { text: 'Device Name', value: 'assetName' },
                { text: 'Status', value: 'resolve' },
                { text: 'Title', value: 'title' },
                { text: 'Message', value: 'message' },
                { text: 'Parameter', value: 'element' },
                { text: 'Operation', value: 'operation' },
                { text: 'Parameter', value: 'value' },
                { text: 'Data', value: 'data' },
                { text: 'Unit', value: 'unit' }
                ],
                items: [],
                loading: false,
                elements: [],
                editedIndex: -1,
                defaultItem: {
                    rowKey: 0,
                    element: '',
                    operation: 'eq',
                    value: 0,
                    title: 'หัวข้อ',
                    message: 'ข้อความแนะนำ',
                    trigger: '',
                    resolve: ''
                },
                editedItem: {
                    rowKey: 0,
                    element: null,
                    operation: null,
                    value: 0,
                    title: '',
                    message: '',
                    trigger: '',
                    resolve: ''
                }
            }
        },
        mounted () {
            if (this.asset.assetId) {
                this.getData(this.asset.assetId);
            }
        },
        computed: {
            role: {
      get: function () {
        return this.$store.state.User.role
      }
    },  
            units() {
            return this.$store.state.User.units
        }
        },
        methods: {
            getData(assetId) {
                this.items = []
                this.loading = true
                try {
                   this.$store.dispatch('Alert/GET_ALERT_BY_DEVICE',{assetId: assetId}).then((data)=>{
                    this.items = data
                    this.loading = false
                    }).catch(()=>{
                        this.loading = false
                    })
                }catch(error) {
                    this.loading = false
                }
            },
        },
    }
    
    </script>