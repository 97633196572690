<template>
  <v-hover v-slot="{ hover }">
    <v-card :elevation="hover ? 6 : 2" class="mt-2">
      <v-card-text>
        <v-row>
          <v-col cols="auto">
            <div class="text-left text-h5 font-weight-bold black--text"> <v-icon>mdi-history</v-icon>Energy comparision
            </div>
            <div class="text-left text-subtitle-1">เปรียบเทียบการใช้พลังงานตามช่วงเวลา</div>
            <div class="text-left text-subtitle-1">
              <v-icon>mdi-sigma</v-icon>
            <template v-for="(item,index) in meter">
                <v-icon>mdi-gauge</v-icon>{{ item.assetName }} {{ index < meter.length - 1 ? '+' : '' }}
              </template>
            </div>
          </v-col>
          <v-spacer />
          <v-col cols="auto">
            เลือกช่วงเวลา
            <DateRangePicker class="ml-2" v-model="range" :timePicker="false" />
          </v-col>
          <v-col cols="auto"> <v-btn color="primary" :disabled="loading" @click="getEnergy()">View</v-btn></v-col>
        </v-row>
      </v-card-text>
      <v-tabs fixed-tabs v-model="tab" @change="tabChange">
        <v-tab href="#tab-1">
          เปรียบเทียบรายวัน
        </v-tab>
        <v-tab href="#tab-2">
          เปรียบเทียบรายสัปดาห์
        </v-tab>
        <v-tab href="#tab-3">
          เปรียบเทียบรายเดือน
        </v-tab>
        <v-tab href="#tab-4">
          เปรียบเทียบรายปี
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-1">
          <v-card flat>
            <DAY_TO_DAY_CHART v-if="!loading" :energyValue="dailyEnergy" :meter="meter"/>
            <v-skeleton-loader v-else class="mx-auto" type="card"></v-skeleton-loader>
          </v-card>
        </v-tab-item>
        <v-tab-item value="tab-2">
          <v-card flat>
            <WEEK_TO_WEEK_CHART v-if="!loading" :energyValue="weeklyEnergy" :meter="meter"/>
            <v-skeleton-loader v-else class="mx-auto" type="card"></v-skeleton-loader>
          </v-card>
        </v-tab-item>
        <v-tab-item value="tab-3">
          <v-card flat>
            <MONTH_TO_MONTH_CHART v-if="!loading" :energyValue="monthlyEnergy" :meter="meter"/>
            <v-skeleton-loader v-else class="mx-auto" type="card"></v-skeleton-loader>
          </v-card>
        </v-tab-item>
        <v-tab-item value="tab-4">
          <v-card flat>
            <YEAR_TO_YEAR_CHART v-if="!loading" :energyValue="yearlyEnergy" :meter="meter"/>
            <v-skeleton-loader v-else class="mx-auto" type="card"></v-skeleton-loader>
          </v-card>
        </v-tab-item>

      </v-tabs-items>
    </v-card>
  </v-hover>
</template>
<script>
import MIX_CHART from '@/components/charts/mix_chart'
import DAY_TO_DAY_CHART from '@/components/charts/day_to_day_chart'
import WEEK_TO_WEEK_CHART from '@/components/charts/week_to_week_chart'
import MONTH_TO_MONTH_CHART from '@/components/charts/month_to_month_chart'
import YEAR_TO_YEAR_CHART from '@/components/charts/year_to_year_chart'
import DateRangePicker from '@/components/common/DateRangePicker.vue'
export default {
  props: {
    meter: {
      type: Array
    },
    update: {
      type: Boolean
    }
  },
  components: {
    MIX_CHART,
    DAY_TO_DAY_CHART,
    WEEK_TO_WEEK_CHART,
    MONTH_TO_MONTH_CHART,
    YEAR_TO_YEAR_CHART,
    DateRangePicker
  },
  data() {
    return {
      tab: null,
      loading: false,
      range: {
        startDate: this.$dayjs().startOf('month').toDate(),
        endDate: this.$dayjs().endOf('month').toDate(),
      },
      tab1: false,
      tab2: false,
      tab3: false,
      tab4: false,
      localUpadate: false
    }
  },
  computed: {
    building: {
      get: function () {
        return this.$store.state.User.building
      },
      set: function (newValue) {
        this.$store.dispatch('User/SET_BUILDING', newValue)
      }
    },
    dailyEnergy: {
      get: function () {
        return this.$store.state.Energy.dailyEnergyCompare
      },
    },
    weeklyEnergy: {
      get: function () {
        return this.$store.state.Energy.weeklyEnergyCompare
      },
    },
    monthlyEnergy: {
      get: function () {
        return this.$store.state.Energy.monthlyEnergyCompare
      },
    },
    yearlyEnergy: {
      get: function () {
        return this.$store.state.Energy.yearlyEnergyCompare
      },
    }
  },
  mounted() {
    this.getEnergy();
  },
  watch: {
    meter(val) {
      if (val) {
        this.getEnergy()
      }
    },
    update(){
      this.localUpadate = !this.localUpadate
    }
  },
  methods: {

    tabChange(){
      this.localUpadate = !this.localUpadate
    },
    getEnergy() {
      if (this.meter.length > 0) {
        const daily = new Promise((resolve, reject) => {
          this.$store.dispatch('Energy/GET_DAILY_ENERGY_COMPARE', { BuildingId: this.building.BuildingId, type: 'daily', deviceId:  this.meter.map(a=> a.deviceId), group: true, start:this.range.startDate.getTime() ,end:this.range.endDate.getTime()}).then(() => {
            resolve()
          }).catch((err) => {
            reject(err)
          })
        });
        const weekly = new Promise((resolve, reject) => {
          this.$store.dispatch('Energy/GET_WEEKLY_ENERGY_COMPARE', { BuildingId: this.building.BuildingId, type: 'weekly', deviceId:  this.meter.map(a=> a.deviceId), group: true,end:this.range.endDate.getTime() }).then((message) => {
            resolve()
          }).catch((err) => { reject(err) })
        });
        const monthly = new Promise((resolve, reject) => {
          this.$store.dispatch('Energy/GET_MONTHLY_ENERGY_COMPARE', { BuildingId: this.building.BuildingId, type: 'monthly', deviceId:  this.meter.map(a=> a.deviceId), group: true,end:this.range.endDate.getTime() }).then(() => {
            resolve()
          }).catch((err) => { reject(err) })
        });
        const yearly = new Promise((resolve, reject) => {
          this.$store.dispatch('Energy/GET_YEARLY_ENERGY_COMPARE', { BuildingId: this.building.BuildingId, type: 'yearly', deviceId:  this.meter.map(a=> a.deviceId), group: true,end:this.range.endDate.getTime() }).then(() => {
            resolve()
          }).catch((err) => { reject(err) })
        });
        this.loading = true
        Promise.all([daily, weekly, monthly, yearly]).then((values) => {
          this.loading = false
        }).catch((err) => {
          console.log(err)
          this.loading = false
        })
      }

    },
  }
}
</script>