<template>
  <v-container fluid>

    <Head>
      <template v-slot:title>
        <v-btn icon :to="{ name: 'overview' }"><v-icon>mdi-arrow-left</v-icon></v-btn>
        <v-icon color="primary">mdi-lightning-bolt-circle</v-icon>Electrical
      </template>
      <template v-slot:subtitle>
        ระบบไฟฟ้า
      </template>
    </Head>
    <v-tabs centered v-model="tab" v-if="main_meter.length > 0">
      <v-tab>Dashboard</v-tab>
      <v-tab>Main Distribution Panel</v-tab>
      <v-tab>History</v-tab>
      <v-tab>Monitor</v-tab>
      <v-tab>Machine</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" v-if="main_meter.length > 0">
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <Dashboard/>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <Diagram/>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <Table/>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <Analytic/>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text >
       <MACHINE_VIEW :assets="ee_machine"/>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>

    <v-card v-else flat >
      <v-card-title > <v-spacer/> This feature not available for your building<v-spacer/> </v-card-title>
      <v-img :src="require('@/assets/undraw_city_life_gnpr.svg')">
       
      </v-img>
    </v-card>
    
  </v-container>
</template>
  
<script>
import Head from '@/components/layout/Head.vue'
import Diagram from '@/components/electrical/electrical_diagram'
import Dashboard from '@/components/electrical/electrical_dashboard'
import Analytic from '@/components/electrical/electrical_analytic'
import Table from '@/components/electrical/electrical_table'
import MACHINE_VIEW from '@/components/machine/machine_view'

export default {
  name: 'HelloWorld',
  components: {
    Head,
    Diagram,
    Dashboard,
    Analytic,
    Table,
    MACHINE_VIEW
  },
  computed: {
    main_meter() {
      return this.$store.state.Energy.mainMeter
    },
    ee_machine() {
          return this.$store.state.Asset.assetAll.filter(el =>  el.machine === true && el.serviceId === '20340ea6-88dd-11ee-b9d1-0242ac120003')
    }
  },
  data() {
    return {
      tab: null,
      change: false
    }
  },

}
</script>
  