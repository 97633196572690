<template>
    <v-card>
        <v-card-title>Email Token Settings | {{ asset.assetName }} ({{ asset.deviceId }}) {{ asset.assetType }}<v-spacer/> <v-btn icon @click="$emit('onClose')"><v-icon>mdi-close</v-icon></v-btn></v-card-title>
        <v-card-text>
            <v-snackbar
          v-model="errorSnack"
          :timeout="timeout"
        >
          {{ errorMessage }}
    
          <template v-slot:action="{ attrs }">
            <v-btn
              color="blue"
              text
              v-bind="attrs"
              @click="snackbar = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
            <v-data-table :search="search" :headers="headers" :loading="loading" :items="items">
                <template v-slot:top>
                    <v-row>
                        <!-- <v-col cols="auto"> {{ asset.assetName }} ({{ asset.deviceId }}) {{ asset.assetType }}</v-col> -->
                        <v-col cols="auto">
                    <v-text-field v-model="search" solo dense prepend-inner-icon="mdi-magnify" label="Search"/>
                    </v-col>
                  <v-spacer />
                 
                    <v-col cols="auto">
                    <v-btn color="primary" v-if="role == 'Admin'"  :disabled="loading" @click="addNew"> <v-icon >mdi-plus</v-icon>New Email</v-btn>
                    </v-col></v-row>
                </template>
    
            <template v-slot:item.email="{ item }">
            <v-form ref="form" v-model="valid" v-if="item.rowKey === editedItem.rowKey">
                <v-text-field  :disabled="loading" v-model="editedItem.email" :items="elements" :rules="[v => !!v || 'Please fill email data']"  :hide-details="true" dense single-line :autofocus="true"></v-text-field>
            </v-form>
            <span v-else>{{item.email}}</span>
            </template>
    
            <template v-slot:item.note="{ item }">
            <v-text-field  :disabled="loading"  v-model="editedItem.note" :hide-details="true" dense single-line :autofocus="true" v-if="item.rowKey === editedItem.rowKey"></v-text-field>
            <span v-else>{{item.note}}</span>
            </template>
            <template v-slot:item.actions="{ item }">
                <div v-if="item.rowKey === editedItem.rowKey">
                <v-icon color="red" class="mr-3" @click="close">
                  mdi-window-close
                </v-icon>
                <v-icon color="green"  @click="save">
                  mdi-content-save
                </v-icon>
              </div>
              <div v-else>
                <v-icon color="green" :disabled="role !== 'Admin'" class="mr-3" @click="editItem(item)">
                  mdi-pencil
                </v-icon>
                <v-icon color="red" :disabled="role !== 'Admin'" @click="deleteItem(item)">
                  mdi-delete
                </v-icon>
              </div>
            </template>
    
            </v-data-table>
        </v-card-text>
    </v-card>
    
    </template>
    <script>
    import { v4 as uuidv4 } from 'uuid';
    
    export default {
        props: {
                asset: {
                    type: Object
                },
            },
        watch: {
            asset(val) {
                if(val.assetId) {
                    this.getData(val.assetId)
                }
            }
        },
        data() {
            return {
                search: '',
                valid: false,
                dialog: false,
                dialogDelete: false,
                errorSnack: false,
                errorMessage: '',
                timeout: 2000,
                headers: [
                    { text: 'Email', value: 'email'},
                    { text: 'Note', value: 'note',  width: "350px"},
                    { text: 'Actions', value: 'actions', sortable: false , width: "100px"},
                ],
                items: [],
                loading: false,
                elements: [],
                editedIndex: -1,
                defaultItem: {
                    rowKey: '',
                    email: '',
                    note: '',
                },
                editedItem: {
                    rowKey: '',
                    email: '',
                    note: '',
                }
            }
        },
        computed: {
            role: {
            get: function () {
                return this.$store.state.User.role
            }
        },
          formTitle () {
            return this.editedIndex === -1 ? 'New Rule' : 'Edit Rule'
          },
        },
        mounted () {
            if (this.asset.assetId) {
                this.getData(this.asset.assetId);
            }
        },
        methods: {
            getData(assetId) {
                this.items = []
                this.loading = true
                //this.elements = Object.keys(JSON.parse(this.asset.data))
                this.$store.dispatch('Email/GET_EMAIL_BY_DEVICE',{assetId: assetId}).then((data)=>{
                    this.items = data
                    this.loading = false
                }).catch(()=>{
                    this.loading = false
                })
            },
            addNew() {
                const addObj = Object.assign({}, this.defaultItem);
                addObj.rowKey =  uuidv4();
                this.items.push(addObj);
                this.editItem(addObj);
            },
            editItem(item) {
                this.editedIndex = this.items.indexOf(item);
                this.editedItem = Object.assign({}, item);
            },
            save () {
                if (this.editedIndex > -1 ) {
                    if (!this.$refs.form.validate()) return
                    this.loading = true
                    const data = {
                        assetId: this.asset.assetId,
                        email: this.editedItem.email,
                        note: this.editedItem.note,
                        id: this.editedItem.rowKey
                    }
                    //console.log(data)
                    //console.log(this.editedItem)
                    this.$store.dispatch('Email/UPDATE_EMAIL_BY_DEVICE', data).then((data)=>{
                        this.items = data
                        this.loading = false
                        this.editedItem = Object.assign({}, this.defaultItem);
                        this.editedIndex = -1;
                    }).catch((error)=>{
                        this.loading = false
                        this.errorMessage = error
                        this.errorSnack = true
                    })
                }
               
            },
            deleteItem(item) {
                confirm('Are you sure you want to delete this item?') && this.delete(item);
            },
            delete(item) {
                this.loading = true
                    const data = {
                        assetId: this.asset.assetId,
                        id: item.rowKey
                    }
                    this.$store.dispatch('Email/DELETE_EMAIL_BY_DEVICE', data).then((data)=>{
                        this.items = data
                        this.loading = false
                        this.editedItem = Object.assign({}, this.defaultItem);
                        this.editedIndex = -1;
                    }).catch((error)=>{
                        this.loading = false
                        this.errorMessage = error
                        this.errorSnack = true
                    })
            },
            close() {
                this.loading = true
                this.$store.dispatch('Email/GET_EMAIL_BY_DEVICE',{assetId: this.asset.assetId}).then((data)=>{
                    this.items = data
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                    this.loading = false
                }).catch((error)=>{
                    this.loading = false
                    this.errorMessage = error
                    this.errorSnack = true
                })
            }
        },
    }
    
    </script>