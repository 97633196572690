import Vue from 'vue'
import { msalInstance, b2cPolicies, apiConfig } from '../authConfig'
import { InteractionType } from '@azure/msal-browser'
import store from '@/store'

const DEFAULT_TITLE = 'SMART FEM Platform'

export function registerGuard (router) {
  router.beforeEach(async (to, from, next) => {
    document.title = to.meta.title || DEFAULT_TITLE
    if (to.meta.requiresAuth) {
      const request = {
        authority: b2cPolicies.authorities.signUpSignIn.authority,
        redirectUri: window.location.origin,
        scopes: [...apiConfig.b2cScopes],
        redirectStartPage: to.fullPath,
        extraQueryParameters: { ui_locales: 'th' }
      }
      const shouldProceed = await isAuthenticated(msalInstance, InteractionType.Redirect, request)
      if (shouldProceed) {
        next()
      } else {
        Vue.prototype.$auth.logout()
      }
      // Implement routing to select site or tenant
    } else {
      next()
    }
    // next()
  })
}

export async function isAuthenticated (instance, interactionType, loginRequest, next) {
  // If your application uses redirects for interaction, handleRedirectPromise must be called and awaited on each page load before determining if a user is signed in or not
  return instance.handleRedirectPromise().then(async (response) => {
    if (response !== null) {
      // if (!(response.idTokenClaims.user_info && response.idTokenClaims.user_info.length > 0)) return false
      return true
    } else {
      const accounts = instance.getAllAccounts()
      if (accounts.length > 0) {
        // await persistUserInfo(response)
        // const token = await Vue.prototype.$auth.acquireToken()
        // add logic to reject user
        // await persistUserInfo(token)
        // if (!(response.idTokenClaims.user_info && response.idTokenClaims.user_info.length > 0)) return false
        return true
      }
    }
    if (interactionType === InteractionType.Redirect) {
      return instance.loginRedirect(loginRequest).then(() => {
        return true
      }).catch(() => {
        // return false
      })
    }
    return false
  }).catch((error) => {
    if (error.errorMessage) {
      if (error.errorMessage.indexOf('AADB2C90118') > -1) {
        instance.loginRedirect({
          authority: b2cPolicies.authorities.forgotPassword.authority,
          scopes: [...apiConfig.b2cScopes],
          extraQueryParameters: { ui_locales: 'th' }
        })
      } else {
        console.trace()
        instance.loginRedirect({
          authority: b2cPolicies.authorities.signUpSignIn.authority,
          scopes: [...apiConfig.b2cScopes],
          extraQueryParameters: { ui_locales: 'th' }
        })
      }
    }
  })
}

export async function persistUserInfo (token) {
  if (!(token.idTokenClaims)) return false
  // console.log(token.idTokenClaims.user_info)
  await store.dispatch('User/SET_USER', token.idTokenClaims)
  return true
}
