import { getEmail, postEmail,deleteEmail } from '@/api/'


const state = () => ({
})

const mutations = {
}

const actions = {
    GET_EMAIL_BY_DEVICE({commit, rootState}, { assetId }) {
        return new Promise((resolve, reject) => {
            getEmail({buildingId: rootState.User.building.BuildingId, assetId: assetId, },(message)=>{
                if (message.data) {
                    resolve(message.data)
                } else {
                    resolve()
                }
            }, (error)=>{
                reject(error)
            })
        })
    },
    UPDATE_EMAIL_BY_DEVICE({commit, rootState}, data) {
        data.buildingId = rootState.User.building.BuildingId
        data.tenantId =  rootState.User.tenant.TenantId
        return new Promise((resolve, reject) => {
            postEmail(data ,(message)=>{
                if (message.data) {
                    resolve(message.data)
                } else {
                    resolve()
                }
            }, (error)=>{
                reject(error)
            })
        })
    },
    DELETE_EMAIL_BY_DEVICE({commit, rootState}, data) {
        data.buildingId = rootState.User.building.BuildingId
        data.tenantId =  rootState.User.tenant.TenantId
        return new Promise((resolve, reject) => {
            deleteEmail(data ,(message)=>{
                if (message.data) {
                    resolve(message.data)
                } else {
                    resolve()
                }
            }, (error)=>{
                reject(error)
            })
        })
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
  }
  