<template>
  <v-menu offset-y>
    <v-dialog v-model="contactusDialog" width="384">
    <Contactus />
    </v-dialog>
    <template v-slot:activator="{ on, attrs }">
      <v-avatar
        color="primary"
        size="32"
        v-bind="attrs"
        v-on="on"
        aria-label="User Profile"
        class="ml-2"
      >
        <v-img
          contain
          :src="require('@/assets/1.svg')"
        />
      </v-avatar>
    </template>
    <v-list nav>
      <v-list-item>
      <v-switch
        v-model="notification"
        :true-value="true"
        :false-value="false"
        inset
        :label="(notification) ? 'แจ้งเตือน' : 'ไม่แจ้งเตือน'"
      ></v-switch>
      </v-list-item>
      <v-list-item
        v-for="(item, index) in items"
        :key="index"
        @click="menuActionClick(item.action)"
      >
      <v-list-item-avatar size="35">
        <v-icon>{{ item.icon }}</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
// import {  mdiLogout } from '@mdi/js'
// import Contactus from './Contactus.vue'
export default {
  components: {
    // Contactus,
  },
  data () {
    return {
      items: [
        // { title: 'ข้อมูลผู้ใช้งาน', icon: mdiAccount, action: 'userProfile' },
        // { title: 'ติดต่อเรา', icon: mdiFaceAgent, action: 'contactUs' },
        // { title: 'หน่วยบริการ', icon: mdiOfficeBuilding, action: 'selectTenant' },
        { title: 'ออกจากระบบ', icon: 'mdi-logout', action: 'logout' }
      ],
      contactusDialog: false,
      //notification: false
    }
  },
  computed: {
    notification: {
      get: function (){
        return this.$store.state.User.notification
      },
      set: function(value) {
        this.$store.dispatch('User/SET_NOTIFICATION', value)
      }
    }
  },
  methods: {
    menuActionClick (action) {
      switch (action) {
        case 'logout':
          this.$auth.logout()
          break
        case 'selectTenant':
          // this.$router.push({ name: 'selecttenant' })
          break
        case 'userProfile':
          // this.$emit('userProfile')
          // console.log('editpass')
          // this.$auth.changePassword()
          break
        case 'contactUs':
          this.contactusDialog = true
          break
      }
    }
  }
}
</script>

<style>

</style>
