<template>
    <v-hover v-slot="{ hover }">
        <v-card :elevation="hover ? 6 : 2">
            <v-card-text>
                <div ref="chart" style="width: 100%;height:300px;"></div>
            </v-card-text>
        </v-card>

    </v-hover>
</template>

<script>

import * as echarts from 'echarts/core';
import { TitleComponent, LegendComponent } from 'echarts/components';
import { RadarChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
echarts.use([TitleComponent, LegendComponent, RadarChart, CanvasRenderer]);

export default {
    mounted() {
        this.myChart = echarts.init(this.$refs.chart);
        this.myChart.setOption(this.option);
        window.addEventListener('resize', () => {
            this.myChart.resize()
        });
        this.getData()
    },
    computed: {
        score: {
            get: function () {
                return this.$store.state.Overview.score
            }
        },
    },
    watch: {
        score() {
            this.getData()
        }
    },
    methods: {
        getData() {
            this.myChart.setOption({
                series: [
                    {
                        name: 'Building Score',
                        type: 'radar',
                        data: [
                            {
                                value: this.score,
                                name: 'Building'
                            },
                        ]
                    }
                ]
            })

        }
    },
    data() {
        return {
            myChart: null,
            option: {
                title: {
                    text: 'Building Score'
                },
                legend: {
                    data: ['']
                },
                radar: {
                    indicator: [
                        { name: 'Electrical', max: 100 },
                        { name: 'Sanitary', max: 100 },
                        { name: 'FireAlarm', max: 100 },
                        { name: 'Network', max: 100 },
                        { name: 'Sensor', max: 100 },
                    ]
                },
                series: [
                    {
                        name: 'Building Score',
                        type: 'radar',
                        data: [
                            {
                                value: [],
                                name: 'Building'
                            },
                        ]
                    }
                ]
            }
        }
    },
}

</script>