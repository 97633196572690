<template>
    <div ref="linechart" class="mt-4" style="width: 100%; height: 400px;"></div>

</template>

<script>
import * as echarts from 'echarts';
export default {
    props: {
        data: {
            type: Array
        },
    },
    data() {
        return {
            option: {
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    type: 'scroll'
                },
                grid: {
                    left: '3%',
                    right: '3%',
                    bottom: '10%',
                    top: '10%',
                    containLabel: true
                },
                toolbox: {
                    top: 'bottom',
                    padding: 2,
                    feature: {
                        dataView: {},
                        dataZoom: {},
                        restore: {},
                        saveAsImage: {},
                        magicType: {
                            show: true, type: ['line', 'bar'],
                            title: {
                                line: 'line view',
                                bar: 'bar view',
                            },
                        },
                    }
                },
                xAxis: {
                    type: 'time'
                },
                yAxis: {
                     type: 'value'
                },
                series: [],
                dataset: {
                    source: [],
                    dimensions: [],
                }
            }
        }
    },
    watch: {
        data() {
            this.setData()
        }
    },
    mounted() {
        this.myChart = echarts.init(this.$refs.linechart);
        const resizeObserver = new ResizeObserver((entries) => {
            this.myChart.resize();
        });
        resizeObserver.observe(this.$refs.linechart);
        this.setData()
    },
    methods: {
        setData() {
            if (this.$refs.linechart) {
                const elements = Object.keys(this.data[0])
                this.option.dataset.dimensions = []
                this.option.dataset.source = []
                this.option.series = []
                const data = []
                for (let i = 0; i < this.data.length; i++) {
                    data.push(Object.values(this.data[i]))
                }
                this.option.dataset.dimensions = elements
                this.option.dataset.source = data

                for (let i = 0; i < elements.length; i++) {
                    if (elements[i] !== 'timestamp' && elements[i] !== 'updata') {
                        this.option.series.push(
                            {
                                name: elements[i],
                                type: 'line',
                                encode: {
                                    x: 'timestamp',
                                    y: elements[i] // refer sensor 1 value 
                                }
                            }
                        )
                    }
                }
                this.myChart.setOption(this.option, true);
                this.myChart.resize()
            }
        }
    },
}
</script>