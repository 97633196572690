var render = function render(){var _vm=this,_c=_vm._self._c;return _c('l-map',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],ref:"map",staticClass:"elevation-2",style:(`height: ${_vm.height}px; width: 100%`),attrs:{"center":_vm.center,"crs":_vm.crs},on:{"ready":function($event){return _vm.configMap()}}},[_c('l-control-fullscreen',{attrs:{"position":"topleft","options":{ title: { 'false': 'Go big!', 'true': 'Be regular' } }}}),_c('l-image-overlay',{attrs:{"url":_vm.plant.image,"bounds":_vm.bounds}}),_c('l-control-layers',{attrs:{"position":"topright"}}),_vm._l((_vm.plant.asset),function(item,index){return (item.position)?_c('l-marker',{key:index,ref:item.assetName,refInFor:true,attrs:{"lat-lng":[Number(item.position.split(':')[0]), Number(item.position.split(':')[1])],"options":{
      draggable: (_vm.isSuperAdmin) ? true : false,
    }},on:{"dragend":function($event){return _vm.draggableEvent($event, item)}}},[_c('l-icon',{attrs:{"icon-size":[Number(item.IconSize.split(':')[0]), Number(item.IconSize.split(':')[1])],"icon-url":item.icon}}),_c('l-tooltip',{attrs:{"options":{
      permanent: true,
      direction: 'bottom',
      offset: [-15, 40]
    }}},[(item.machineState === 'Running')?[_c('v-icon',{staticClass:"mr-2",attrs:{"size":"15","color":"green"}},[_vm._v("mdi-circle")])]:[_c('v-icon',{staticClass:"mr-2",attrs:{"size":"15","color":"red"}},[_vm._v("mdi-circle")])],_vm._v(_vm._s(item.assetName)+" ")],2),_c('l-popup',{attrs:{"options":{ autoClose: true, closeOnClick: true, maxWidth: 550}}},[_c('HVAC_POPUP',{attrs:{"asset":item}})],1)],1):_vm._e()})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }