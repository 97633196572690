import { render, staticRenderFns } from "./sanitary_tank_widget.vue?vue&type=template&id=04c98474&scoped=true&"
import script from "./sanitary_tank_widget.vue?vue&type=script&lang=js&"
export * from "./sanitary_tank_widget.vue?vue&type=script&lang=js&"
import style0 from "./sanitary_tank_widget.vue?vue&type=style&index=0&id=04c98474&prod&scss=true&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04c98474",
  null
  
)

export default component.exports