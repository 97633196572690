<template>
  <v-col cols="12">
    <v-row>
      <v-col cols="12" md="3">
        <v-hover v-slot="{ hover }">
          <v-card class="pr-2 pl-2" :elevation="hover ? 6 : 2" v-if="!loading">
            <v-row>
              <v-col cols="12">
                <div class="text-left text-h5 font-weight-bold"><v-icon>mdi-sigma</v-icon> Total Energy Usage</div>
                <div class="text-left text-subtitle-1">การใช้พลังงานรวมประจำวัน</div>
                <div class="text-right text-h4 font-weight-bold" v-if="energyUnitValue.length > 0">{{
                   Number(energyUnitValue[energyUnitValue.length - 1]).toLocaleString(undefined, { maximumFractionDigits: 0 }) }}
                  <span class="text-center text-caption">kWh</span>
                </div>
                <div class="text-caption text-right">{{ $dayjs(energyValue.date).format('DD/MM/YYYY HH:mm') }}</div>
              </v-col>
              <v-col cols="12">
                <SMALL_CHART :category="energyUnitLabel" :data="energyUnitValue"></SMALL_CHART>
                <div class="text-center text-caption">แนวโน้มการใช้พลังงาน 7 วันล่าสุด</div>
              </v-col>
            </v-row>
          </v-card>
          <v-skeleton-loader v-else class="mx-auto" type="card"></v-skeleton-loader>
        </v-hover>
      </v-col>
      <v-col cols="12" md="3">
        <v-hover v-slot="{ hover }">
          <v-card class="pr-2 pl-2" :elevation="hover ? 6 : 2" v-if="!loading">
            <v-row>
              <v-col cols="12">
                <div class="text-left text-h5 font-weight-bold"> <v-icon>mdi-arrow-top-right</v-icon> On-Peak</div>
                <div class="text-left text-subtitle-1">การใช้พลังงานช่วง On-Peak ประจำวัน</div>
                <div class="text-right text-h4 font-weight-bold" v-if="energyPeakValue.length > 0">
                  {{  Number(energyPeakValue[energyPeakValue.length - 1]).toLocaleString(undefined, { maximumFractionDigits: 0 })
                  }}
                  kWh
                </div>
                <div class="text-caption text-right">{{ $dayjs(energyValue.date).format('DD/MM/YYYY HH:mm') }}</div>
              </v-col>
              <v-col cols="12">
                <SMALL_CHART :category="energyPeakLabel" :data="energyPeakValue"></SMALL_CHART>
                <div class="text-center text-caption">แนวโน้มการใช้พลังงาน 7 วันล่าสุด</div>
              </v-col>
            </v-row>
          </v-card>
          <v-skeleton-loader v-else class="mx-auto" type="card"></v-skeleton-loader>
        </v-hover>
      </v-col>
      <v-col cols="12" md="3">
        <v-hover v-slot="{ hover }">
          <v-card class="pr-2 pl-2" :elevation="hover ? 6 : 2" v-if="!loading">
            <v-row>
              <v-col cols="12">
                <div class="text-left text-h5 font-weight-bold"><v-icon>mdi-arrow-bottom-right</v-icon> Off-Peak </div>
                <div class="text-left text-subtitle-1">การใช้พลังงานช่วง Off-Peak ประจำวัน</div>
                <div class="text-right text-h4 font-weight-bold" v-if="energyOffPeakValue.length > 0">
                  {{  Number(energyOffPeakValue[energyOffPeakValue.length - 1]).toLocaleString(undefined, {
                    maximumFractionDigits:
                      0
                  }) }} kWh</div>
                <div class="text-caption text-right">{{ $dayjs(energyValue.date).format('DD/MM/YYYY HH:mm') }}</div>
              </v-col>
              <v-col cols="12">
                <SMALL_CHART :category="energyOffPeakLabel" :data="energyOffPeakValue"></SMALL_CHART>
                <div class="text-center text-caption">แนวโน้มการใช้พลังงาน 7 วันล่าสุด</div>
              </v-col>
            </v-row>
          </v-card>
          <v-skeleton-loader v-else class="mx-auto" type="card"></v-skeleton-loader>
        </v-hover>
      </v-col>
      <v-col cols="12" md="3">
        <v-hover v-slot="{ hover }">
          <v-card class="pr-2 pl-2" :elevation="hover ? 6 : 2" v-if="!loading">
            <v-row>
              <v-col cols="12">
                <div class="text-left text-h5 font-weight-bold"><v-icon>mdi-delta</v-icon> Peak demand</div>
                <div class="text-left text-subtitle-1">ค่าความต้องการใช้พลังงานสูงสุด</div>
                <div class="text-right text-h4 font-weight-bold" v-if="energyDemandValue.length > 0">
                  {{ Number(energyDemandValue[energyDemandValue.length - 1]).toLocaleString()}}
                  kW</div>
                <div class="text-caption text-right">{{ $dayjs(energyValue.date).format('DD/MM/YYYY HH:mm') }}</div>
              </v-col>
              <v-col cols="12">
                <SMALL_CHART :category="energyDemandLabel" :data="energyDemandValue"></SMALL_CHART>
                <div class="text-center text-caption">แนวโน้มการใช้พลังงาน 7 วันล่าสุด</div>
              </v-col>
            </v-row>
          </v-card>
          <v-skeleton-loader v-else class="mx-auto" type="card"></v-skeleton-loader>
        </v-hover>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import SMALL_CHART from '@/components/energy_management/energy_summary_small_chart.vue'

export default {
  props: {
    meter: {
      type: Array
    },
  },
  components: {
    SMALL_CHART,
  },
  data() {
    return {
      loading: true
    }
  },
  watch: {
    meter(value) {
      if (value && value.length > 0) {
        this.getDailyEnergy()
      } else {
        this.loading = true
      }
    },
    building() {
      this.getDailyEnergy()
    }
  },
  mounted() {
    this.getDailyEnergy();
  },
  computed: {
    building: {
      get: function () {
        return this.$store.state.User.building
      },
      set: function (newValue) {
        this.$store.dispatch('User/SET_BUILDING', newValue)
      }
    },
    energyValue: {
      get: function () {
        return this.$store.state.Energy.dailyEnergy
      },
    },
    energyPeakValue() {
      return this.energyValue.map(a => Number(a.Peak).toFixed(0))
    },
    energyPeakLabel() {
      return this.energyValue.map(a => a.date)
    },
    energyOffPeakValue() {
      return this.energyValue.map(a => Number(a.OffPeak).toFixed(0))
    },
    energyOffPeakLabel() {
      return this.energyValue.map(a => a.date)
    },
    energyUnitValue() {
      return this.energyValue.map(a => Number(a.Unit).toFixed(0))
    },
    energyUnitLabel() {
      return this.energyValue.map(a => a.date)
    },
    energyDemandValue() {
      return this.energyValue.map(a => Number(a.demand).toFixed(0))
    },
    energyDemandLabel() {
      return this.energyValue.map(a => a.date)
    }
  },
  methods: {
    getDailyEnergy() {
      if (this.meter.length > 0) {
        this.loading = true
        const start = this.$dayjs().local().subtract(7, 'day').unix() * 1000
        const end = this.$dayjs().local().endOf('day').unix() * 1000
        this.$store.dispatch('Energy/GET_DAILY_ENERGY', { BuildingId: this.building.BuildingId, type: 'daily', deviceId: this.meter.map(a => a.deviceId), start: start, end: end, group: true }).then(() => {
          this.loading = false
        }).catch(() => {
          this.loading = false
        })
      }

    }
  }
}
</script>