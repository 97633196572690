import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import LineIcon from '@/components/common/LineIcon.vue'
//#003594
Vue.use(Vuetify);

export default new Vuetify({
    icons:{
      values: {
        LineIcon: { // name of our custom icon
          component: LineIcon, // our custom component
        },
      }
    },
    theme: {
        themes: {
          light: {
            primary: '#003594', // #3f51b5
            secondary: '#2F2F88', // #b0bec5
          },
        },
        options: {
          cspNonce: 'dQw4w9WgXcQ'
        }
      },
});
