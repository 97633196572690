"use strict";

import Vue from 'vue';
import axios from "axios";
import store from '@/store'

// Full config:  https://github.com/axios/axios#request-config
//https://func-fm-service-ea-02.azurewebsites.net
//https://apidb.smartfem.app/v1/
axios.defaults.baseURL = 'https://func-fm-service-sa-01.azurewebsites.net/v1/'
axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.headers.common['x-functions-key'] = 'AV4EEefyckDftsPyo8IqqTS1X3q1ifcKdZU6GAZgrf1ZAzFu5P-k3w=='
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  async function(config) {
    const token = await Vue.prototype.$auth.acquireToken()
    config.headers.Authorization = 'Bearer ' + token.accessToken
    if (config.method === 'get') {
      if (!config.url.includes('tenantId') && !config.url.includes('user') && !config.url.includes('unit')&& !config.url.includes('asset-type') && !config.url.includes('services')) {
        config.url += '&tenantId=' + store.state.User.tenant.TenantId
      }
    }

    // Do something before request is sent
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response;
  },
  function(error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

Plugin.install = function(Vue, options) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    },
  });
};

Vue.use(Plugin)

export default Plugin;
