<template>
  <v-app v-if="$auth.inProgress === 'none' && !permissionLoading">
    <AppBar @drawerChange="drawer = !drawer" @alarmDrawerChange="alarmDrawer = !alarmDrawer"></AppBar>
    <Navigation :drawer.sync="drawer"></Navigation>
    <AlarmNavigation :drawer.sync="alarmDrawer"></AlarmNavigation>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
  <ProgressIndicator v-else />
</template>

<script>

import ProgressIndicator from '@/components/ProgressIndicator'
import AppBar from '@/components/layout/AppBar'
import Navigation from '@/components/layout/Navigation'
import AlarmNavigation from '@/components/layout/AlarmNavigation'
export default {
  name: 'App',
  components: {
    AppBar,
    Navigation,
    ProgressIndicator,
    AlarmNavigation
  },
  data: () => ({
    drawer: false,
    alarmDrawer: false,
    group:null
    //
  }),
  computed: {
    permissionLoading () {
      return this.$store.state.User.permissionLoading
    }
  },
};
</script>
