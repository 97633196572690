import Vue from 'vue'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import buddhistEra from 'dayjs/plugin/buddhistEra'
import localeTh from 'dayjs/locale/th'
import utc from 'dayjs/plugin/utc'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'

dayjs.extend(utc)
dayjs.extend(customParseFormat)
dayjs.extend(weekOfYear)
dayjs.extend(utc)
dayjs.extend(buddhistEra)
dayjs.extend(relativeTime)
dayjs.extend(isSameOrBefore)
dayjs.extend(LocalizedFormat)

Object.defineProperties(Vue.prototype, {
  $dayjs: {
    get () {
      return dayjs
    }
  }
})
