<template>
  <div ref="mixchart" class="mt-5" style="width: 100%; height:400px;"></div>
</template>
<script>
import * as echarts from 'echarts/core';
import {
  DatasetComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent
} from 'echarts/components';
import { BarChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use([
  DatasetComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  BarChart,
  CanvasRenderer
]);

export default {
  props: {
    energyValue: {
      type: Array
    },
    meter: {
      type: Array
    },
    changed: {
      type: Boolean
    }
  },
  data() {
    return {
      myChart: null,
      toggle_exclusive: 0,
      option: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        toolbox: {
          feature: {
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ['line', 'bar'] },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        legend: {
    
        },
        xAxis: [
          {
            type: 'category',
            axisPointer: {
              type: 'shadow'
            },
            axisLabel: {
              formatter: (value) => {
                return value
              }
            },
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: 'kwh',
            min: 0,
            axisLabel: {
              formatter: '{value} kwh'
            }
          },
          {
            type: 'value',
            name: 'Price',
            min: 0,
            axisLabel: {
              formatter: '{value} Baht'
            }
          }
        ],
        series: [
        ],
        dataset: {
          dimensions: ['year'],
           source: [
         
          ]
        }
      }
    }
  },
  methods: {
    setData() {
      const year = [...new Set(this.energyValue.map(item => item.year))]
      var o = this.energyValue.reduce( (a,b) => {
            a[b.year] = a[b.year] || [];
            a[b.year].push({[b.year]:b.Unit});
            return a;
        }, {})

        var a = Object.keys(o).map(function(k) {
              return {year : k, ...(o[k])?Object.assign.apply({},o[k]):0};
        })
        year.forEach((element)=>{
          this.option.series.push( { type: 'bar' })
        })
        this.option.dataset.dimensions = [... this.option.dataset.dimensions,...year ]
        this.option.dataset.source = a
        this.myChart.setOption(this.option);
        this.myChart.resize()
    }

  },
  mounted() {
    this.myChart = echarts.init(this.$refs.mixchart);
    const resizeObserver = new ResizeObserver((entries) => {
      this.myChart.resize();
    });
    resizeObserver.observe(this.$refs.mixchart);
    this.setData()
  },
}
</script>