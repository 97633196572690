<template>
    <v-hover v-slot="{ hover }">
        <v-card :elevation="hover ? 6 : 2" :disabled="loading">
            <v-row>
                <v-col cols="auto" class="ml-2 pt-4">
                    <div class="text-h4 font-weight-bold">
                        {{ Number(JSON.parse(meter.data).WaterLevel) }} cm.
                        <div class="text-body-1">
                            {{ $dayjs(meter.heartbeat).format('DD MMM YYYY hh:mm') }}
                        </div>
                    </div>
                    <v-icon>mdi-waves-arrow-up</v-icon>
                    Water Level
                </v-col>
                <v-col cols="8" class="">
                    <div ref="levelChart" style="width: 100%;height:250px;"></div>
                </v-col>
            </v-row>
        </v-card>
    </v-hover>
</template>

<script>
import { getTelemetry } from '@/api/'
import * as echarts from 'echarts/core';
import {
    TitleComponent,
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    MarkLineComponent,
    MarkPointComponent,
    DatasetComponent
} from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use([
    TitleComponent,
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    MarkLineComponent,
    MarkPointComponent,
    LineChart,
    CanvasRenderer,
    UniversalTransition,
    DatasetComponent
]);

export default {
    props: {
        meter: {
            type: Object
        },
    },
    destroyed() {
        this.interval = null
    },
    watch: {
        meter() {
            //this.setData()
        }
    },
    data() {
        return {
            myChart: null,
            interval: null,
            loading: false,
            option: {
                title: {
                },
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    show: false
                },
                toolbox: {
                    show: false,
                    feature: {
                        dataZoom: {
                            yAxisIndex: 'none'
                        },
                        dataView: { readOnly: false },
                        magicType: { type: ['line', 'bar'] },
                        restore: {},
                        saveAsImage: {}
                    }
                },
                xAxis: {
                    type: 'time',
                    boundaryGap: false
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        formatter: '{value} cm.'
                    }
                },
                dataset: {
                    dimensions: ['timestamp', 'level'],
                    source: []
                },
                series: [
                    {
                        name: 'Water Level',
                        type: 'line',
                        markPoint: {
                            data: [
                                { type: 'max', name: 'Max' },
                                { type: 'min', name: 'Min' }
                            ]
                        },
                        markLine: {
                            data: [{ type: 'average', name: 'Avg' }]
                        }
                    },

                ]
            }
        }
    },
    computed: {
        building: {
            get: function () {
                return this.$store.state.User.building
            },
            set: function (newValue) {
                this.$store.dispatch('User/SET_BUILDING', newValue)
            }
        }
    },
    methods: {
        setData() {
            this.loading = true
            getTelemetry({ buildingId: this.building.BuildingId, assetId: this.meter.assetId, start: this.$dayjs().subtract(1, 'day').unix(), end: this.$dayjs().unix() }, message => {
                let series = []
                message.data.forEach(element => {
                    if (Number(element.WaterLevel) > 250) {

                    } else {
                        series.push([element.timestamp, Number(element.WaterLevel)])
                    }
                })
                this.myChart.setOption(this.option);
                this.myChart.setOption({
                    dataset: {
                        dimensions: ['timestamp', 'level'],
                        source: series,
                    }
                });
                this.loading = false
            }, error => {
                console.log(error)
                this.loading = false
            })
        }
    },
    mounted() {
        this.myChart = echarts.init(this.$refs.levelChart);
        this.setData()
        window.addEventListener('resize', () => {
            this.myChart.resize()
        });
        this.interval = setInterval(() => {
            this.setData();
        }, 60000)
    }

}

</script>