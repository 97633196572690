<template>
    <v-card>
        <v-card-title>Add User<v-spacer/><v-btn icon @click="$emit('onClose')"><v-icon>mdi-close</v-icon></v-btn></v-card-title>
        <v-card-text>
            <v-snackbar
            v-model="errorSnack"
            :timeout="timeout">
          {{ errorMessage }}
          <template v-slot:action="{ attrs }">
            <v-btn
              color="blue"
              text
              v-bind="attrs"
              @click="snackbar = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
            <v-form v-model="valid" ref="form" >
            <v-autocomplete label="User" :disabled="posting"  solo :items="userAD" item-text="displayName" v-model="selectedUser" return-object  :rules="[v => !!v || 'Please select data']"></v-autocomplete>
            <v-autocomplete label="Role" :disabled="posting" solo :items="roles" v-model="selectRole"  :rules="[v => !!v || 'Please select data']"></v-autocomplete>
        </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" :disabled="!valid" :loading="posting" @click="postData">Save</v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
export default {
    data() {
        return {
            valid: false,
            selectedUser: null,
            selectRole: null,
            roles: [
                {text: 'Operator', value: 'Operator'},
                {text: 'Administrator', value: 'Admin'}
            ],
            posting: false,
            timeout: 2000,
            errorSnack: false,
            errorMessage: '',
        }
    },
    computed: {
        userAD: {
          get: function () {
            return this.$store.state.Member.userAD
          }
        },
    },
    methods: {
        postData() {
            this.posting = true
            this.$store.dispatch('Member/ADD_MEMBER', {...this.selectedUser, Role: this.selectRole}).then(()=>{
                this.posting = false
                this.errorSnack = true
                this.errorMessage = 'Success'
                this.$refs.form.reset()
                this.$emit('onClose')
            }).catch((error)=>{
                this.posting = false
                console.log(error)
                this.errorSnack = true
                this.errorMessage = error
            })
        }
    },
}

</script>