import { getWater, getTelemetry, getLevel } from '@/api/'
import Vue from 'vue'
import * as alasql from 'alasql'

const state = () => ({
    hourlyWater: [],
    dailyWater:[],
    weeklyWater:[],
    monthlyWater:[],
    yearlyWater:[],
    mainWaterMeter: [],
    waterLevelData: [],
    mainWaterLevel: {},  
    selectedMainWaterMeter: {},
    waterTable: []
})

const mutations = {
  SET_WATER_TABLE(state, data) {
    state.waterTable = data
  },
  SET_MAIN_METER(state, data) {
      state.mainWaterMeter =data
  },
  SET_HOURLY_WATER(state, data) {
        state.hourlyWater =  alasql(`select weekDay, H, AVG(Unit) as Unit from ? group by weekDay, H`, [data])
      },
  SET_DAILY_WATER(state, data){
        if (data) {
          state.dailyWater = data.sort(function(a,b) { 
            if (Vue.prototype.$dayjs(a.date).unix() > Vue.prototype.$dayjs(b.date).unix()) {
              return true
            } else {
              return false
            }
          })
        } else {
          state.dailyWater = data 
        }
      },
     SET_WEEKLY_WATER(state, data){
        state.weeklyWater = data.sort(function(a,b) { 
          if (Number(a.year) === Number(b.year)) {
            return Number(a.week) - Number(b.week) 
          } 
          return Number(a.year) >Number(b.year) ? 1 : -1;
        }) 
      },
      SET_MONTHLY_WATER(state, data){
        state.monthlyWater = data.sort(function(a,b) { 
          if (Number(a.year) === Number(b.year)) {
            return Number(a.month) - Number(b.month) 
          } 
          return Number(a.year) >Number(b.year) ? 1 : -1;
        })  
      },  
      SET_YEARLY_WATER(state, data){
        state.yearlyWater = data
      },  
      SET_SELECTED_MAIN_WATER_METER_BUILDING_CHANGE(state){
        if ( state.mainWaterMeter.length > 0) {
          state.selectedMainWaterMeter =  state.mainWaterMeter
        } else {
          state.selectedMainWaterMeter =  {}
        }
      },
      SET_MAIN_LEVEL_DATA(state,data) {
        let filterData = []
        data.forEach(element => {
          if (Number(element.WaterLevel) > 250) {

          }else {
            filterData.push(element)
          }
        });
        state.waterLevelData = filterData
      },
      SET_MAIN_LEVEL(state, data) {
        state.mainWaterLevel = data
      }
}

const actions = {
  SET_SELECTED_MAIN_WATER_METER_BUILDING_CHANGE({commit}) {
    commit('SET_SELECTED_MAIN_WATER_METER_BUILDING_CHANGE')
  },
  SET_MAIN_METER({commit,rootState}){
      commit('SET_MAIN_METER', rootState.Asset.assetSanitary.filter(el=> el.assetType === 'WaterMeter'))
  },
  SET_MAIN_LEVEL({commit,rootState}){
    commit('SET_MAIN_LEVEL', rootState.Asset.assetSanitary.filter(el=> el.assetType === 'WaterLevel'))
  },
  GET_LEVEL({commit}, data) {
    return new Promise((resolve, reject) => {
      getLevel({buildingId: data.BuildingId, type: data.type, deviceId: data.deviceId, start: data.start, end: data.end}, message =>{
        resolve(message.data)
      }, error=> {
        reject(error)
      })
    })
  },
  GET_WATER_CONSUMPTION({commit}, data) {
    return new Promise((resolve, reject) => {
      getWater({buildingId: data.BuildingId, type: data.type, deviceId: data.deviceId, start: data.start, end: data.end, group: data.group}, message =>{
        resolve(message.data)
      }, error=> {
        reject(error)
      })
    })
  },
  GET_HOURLY_WATER({commit}, data) {
        return new Promise((resolve, reject) => {
          getWater({buildingId: data.BuildingId, type: data.type, deviceId: data.deviceId, period: data.period}, message =>{
            commit('SET_HOURLY_WATER', message.data)
            resolve(message.data)
          }, error=> {
            reject(error)
          })
        })
      },
      GET_DAILY_WATER({commit}, data) {
        return new Promise((resolve, reject) => {
          getWater({buildingId: data.BuildingId, type: data.type, deviceId: data.deviceId}, message =>{
            commit('SET_DAILY_WATER', message.data)
            resolve(message.data)
          }, error=> {
            reject(error)
          })
        })
      },
      GET_WEEKLY_WATER({commit}, data) {
        return new Promise((resolve, reject) => {
          getWater({buildingId: data.BuildingId, type: data.type, deviceId: data.deviceId}, message =>{
            commit('SET_WEEKLY_WATER', message.data)
            resolve(message.data)
          }, error=> {
            reject(error)
          })
        })
      },
      GET_MONTHLY_WATER({commit}, data) {
        return new Promise((resolve, reject) => {
          getWater({buildingId: data.BuildingId, type: data.type, deviceId: data.deviceId}, message =>{
            commit('SET_MONTHLY_WATER', message.data)
            resolve(message.data)
          }, error=> {
            reject(error)
          })
        })
      },
      GET_YEARLY_WATER({commit}, data) {
        return new Promise((resolve, reject) => {
          getWater({buildingId: data.BuildingId, type: data.type, deviceId: data.deviceId}, message =>{
            commit('SET_YEARLY_WATER', message.data)
            resolve(message.data)
          }, error=> {
            reject(error)
          })
        })
      },
      GET_MAIN_LEVEL({commit}, data) {
        return new Promise((resolve, reject)=> {
          getTelemetry({buildingId: data.buildingId, assetId: data.assetId,start: data.start, end:data.end}, message=>{
            commit('SET_MAIN_LEVEL_DATA', message.data)
            resolve(message.data)
          }, error=> {
            reject(error)
          })
        })
      },
      GET_WATER_TABLE({commit}, data) {
        return new Promise((resolve, reject) => {
          getWater({ buildingId: data.BuildingId, type: data.type, deviceId: data.deviceId, period: data.period }, message =>{
            commit('SET_WATER_TABLE', message.data)
            resolve(message.data)
          }, error=> {
            reject(error)
          })
        })
      }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
  }
  